import React from "react";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import makeStyles from "./Styles";

// const actions = [
//   { icon: <FileCopyIcon />, name: "Copy" },
//   { icon: <SaveIcon />, name: "Save" },
//   { icon: <PrintIcon />, name: "Print" },
//   { icon: <ShareIcon />, name: "Share" },
//   { icon: <DeleteIcon />, name: "Delete" }
// ];

export default SpeedDialsAdd;

function SpeedDialsAdd(props: Props) {
  const { openAdd, setOpenAdd, canAdd } = props;

  const classes = makeStyles();
  return (
    <div className={classes.root}>
      <SpeedDial
        hidden={!canAdd}
        ariaLabel="SpeedDial"
        className={classes.speedDial}
        icon={<SpeedDialIcon className={classes.icon} />}
        // onClose={handleClose}
        onClick={() => setOpenAdd(true)}
        open={openAdd}
        direction="up"
      >
        {/* {actions.map(action => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={handleClose}
            />
          ))} */}
      </SpeedDial>
    </div>
  );
}

interface Props {
  openAdd: boolean;
  setOpenAdd: (s: boolean) => void;
  canAdd?: boolean;
}
