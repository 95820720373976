import React, { useContext } from "react";
import css from "./TimesheetsSidebar.module.css";
import {
  List,
  Divider,
  Button,
  ListSubheader,
  RadioGroup,
  Radio,
  FormControlLabel
} from "@material-ui/core";

import { FilterListOutlined, AccessAlarmOutlined } from "@material-ui/icons";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/esm/locale";
import { Period, PersonBasic } from "../../../declarations";
import AutoComplete from "../../../Components/AutoComplete/AutoComplete";
import makeStyles from "./Styles";
import { userContext } from "../../Root";

export default TimesheetsSidebar;

function TimesheetsSidebar({
  people,
  charged,
  setCharged,
  chargeable,
  setChargeable,
  selectedExecutionLawyer,
  setSelectedExecutionLawyer,
  clearFilters,
  isFilterActive,
  setSelectedPeriod,
  selectedPeriod,
  displayMode,
  setDisplayMode,
  setDrawer
}: Props) {
  
  const classes = makeStyles();
  const { user } = useContext(userContext);

  const periodDisabled = false;
  const defaultOption =
    user === null || user.personId === null || user.contactName === null
      ? { label: "Pesquisar...", key: 0, value: null }
      : {
          label: user.contactName,
          key: user.personId,
          value: {
            userId: user.id,
            personId: user.personId,
            class: user.class || "",
            contactName: user.contactName,
            contactId: user.contactId || 0
          }
        };

  const selectedExecutionLawyerOption =
    selectedExecutionLawyer === null
      ? defaultOption
      : {
          label: selectedExecutionLawyer.contactName,
          key: selectedExecutionLawyer.personId,
          value: selectedExecutionLawyer
        };

  return (
    <div className={css.sidebarContainer}>
      <div className={css.sidebarSup}>
        <div className={css.sidebarIconTitle}>
          <AccessAlarmOutlined className={classes.iconTitle} />
          <p className={css.sidebarSupTitle}>Honorários</p>
        </div>
        <div className={css.selectAutoComplete}>
          <AutoComplete<PersonBasic>
            label="Advogado Executor"
            options={people.map(user => ({
              key: user.personId,
              label: user.contactName,
              value: user
            }))}
            setSelected={option => setSelectedExecutionLawyer(option.value)}
            selected={selectedExecutionLawyerOption}
          />
        </div>
      </div>
      <div className={css.sidebarInf}>
        <div className={css.filterGroup}>
          <span className={css.filterTitle}>Faturável</span>
          <RadioGroup
            value={chargeable === null ? "Ambos" : chargeable ? "Sim" : "Não"}
            onChange={(_, value) =>
              setChargeable(value === "Ambos" ? null : value === "Sim" ? true : false)
            }
            row
          >
            <FormControlLabel
              className={classes.radioLabel}
              value="Sim"
              control={<Radio className={classes.radio} color="primary" />}
              label="Sim"
              labelPlacement="top"
            />
            <FormControlLabel
              className={classes.radioLabel}
              value="Não"
              control={<Radio className={classes.radio} color="primary" />}
              label="Não"
              labelPlacement="top"
            />
            <FormControlLabel
              className={classes.radioLabel}
              value="Ambos"
              control={<Radio className={classes.radio} color="primary" />}
              label="Ambos"
              labelPlacement="top"
            />
          </RadioGroup>
        </div>
        <Divider />
        <div className={css.filterGroup}>
          <span className={css.filterTitle}>Faturados</span>
          <RadioGroup
            value={charged === null ? "Ambos" : charged ? "Sim" : "Não"}
            onChange={(_, value) =>
              setCharged(value === "Ambos" ? null : value === "Sim" ? true : false)
            }
            row
          >
            <FormControlLabel
              className={classes.radioLabel}
              value="Sim"
              control={<Radio className={classes.radio} color="primary" />}
              label="Sim"
              labelPlacement="top"
            />
            <FormControlLabel
              className={classes.radioLabel}
              value="Não"
              control={<Radio className={classes.radio} color="primary" />}
              label="Não"
              labelPlacement="top"
            />
            <FormControlLabel
              className={classes.radioLabel}
              value="Ambos"
              control={<Radio className={classes.radio} color="primary" />}
              label="Ambos"
              labelPlacement="top"
            />
          </RadioGroup>
        </div>
        <Divider />
        <List className={classes.sidebarInfList}>
          <ListSubheader disableSticky={true}>Período</ListSubheader>
          <div className={css.pickerPeriod}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <KeyboardDatePicker
                DialogProps={{ className: classes.datePicker }}
                disabled={periodDisabled}
                showTodayButton={true}
                todayLabel="Hoje"
                cancelLabel="Cancelar"
                maxDate={selectedPeriod.toDate ? selectedPeriod.toDate : undefined}
                invalidLabel="Data inválida"
                invalidDateMessage="Data inválida"
                className={classes.picker}
                format="dd/MM/yyyy"
                margin="normal"
                label="De"
                value={selectedPeriod.fromDate}
                onChange={date => {
                  if (date === null || date.toString() === "Invalid Date") return;
                  date.setHours(0, 0, 0, 0);
                  setSelectedPeriod(period => ({ ...period, fromDate: date }));
                }}
              />
              <KeyboardDatePicker
                DialogProps={{ className: classes.datePicker }}
                showTodayButton={true}
                todayLabel="Hoje"
                cancelLabel="Cancelar"
                minDate={selectedPeriod.fromDate}
                disabled={!selectedPeriod.toDate || periodDisabled}
                invalidLabel="Data inválida"
                invalidDateMessage="Data inválida"
                className={classes.picker}
                format="dd/MM/yyyy"
                margin="normal"
                label="Até"
                value={selectedPeriod.toDate}
                onChange={date => {
                  if (date === null || date.toString() === "Invalid Date") return;
                  date.setHours(23, 59, 59, 0);
                  setSelectedPeriod(period => ({ ...period, toDate: date }));
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </List>
      </div>
      <div className={css.filter}>
        <Button
          fullWidth
          disabled={!isFilterActive}
          className={`${classes.button}`}
          onClick={clearFilters}
          variant="contained"
          color="primary"
        >
          <FilterListOutlined className={classes.iconFilter} />
          <span style={{ paddingRight: "20px" }}>Limpar filtros</span>
        </Button>
      </div>
    </div>
  );
}

interface Props {
  people: PersonBasic[];
  charged: boolean | null;
  setCharged: (b: boolean | null) => void;
  chargeable: boolean | null;
  setChargeable: (b: boolean | null) => void;
  selectedExecutionLawyer: PersonBasic | null;
  setSelectedExecutionLawyer: (b: PersonBasic | null) => void;
  clearFilters: () => void;
  isFilterActive: boolean;
  setSelectedPeriod: (func: (period: Period) => Period) => void;
  selectedPeriod: { fromDate: Date | null; toDate: Date | null };
  displayMode: number;
  setDisplayMode: (mode: number) => void;
  setDrawer?: (state: boolean) => void;
}

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);
