import React from "react";
import css from "./InfoBox.module.css";
import { AccessAlarmOutlined } from "@material-ui/icons";
import { Icon } from "@material-ui/core";
import makeStyles from "./Styles";

export default InfoBox;

function InfoBox() {
  const classes = makeStyles();

  return (
    <>
      <a
        href={"#boxTimesheets"}
        title="Honorários"
        className={`${css.boxContainer} ${css.boxBtnTimesheets}`}
      >
        <div className={css.boxBtnIcon}>
          <AccessAlarmOutlined className={classes.icon} />
        </div>
        <div className={css.boxBtnTitle}>últimos 10</div>
      </a>
      <a
        href={"#boxExpenses"}
        title="Despesas"
        className={`${css.boxContainer} ${css.boxBtnExpenses}`}
      >
        <div className={css.boxBtnIcon}>
          <Icon className={`fas fa-money-check-alt ${classes.icon}`} />
        </div>
        <div className={css.boxBtnTitle}>últimos 10</div>
      </a>
      <a
        href={"#boxProcMovements"}
        title="Andamentos"
        className={`${css.boxContainer} ${css.boxBtnProcMovements}`}
      >
        <div className={css.boxBtnIcon}>
          <Icon className={`far fa-newspaper ${classes.icon}`} />
        </div>
        <div className={css.boxBtnTitle}>últimos 10</div>
      </a>
    </>
  );
}
