import React from "react";
import { ListItem, Icon } from "@material-ui/core";
import css from "./ExpensesListItem.module.css";
import { ExpenseType } from "../../../declarations";
import { ContactsOutlined, FolderOutlined } from "@material-ui/icons";
import makeStyles from "./Styles";
import moment from "moment";
import "moment/locale/pt-br";

export default ExpensesListItem;

moment.locale("pt-br");

function ExpensesListItem(props: Props) {
  const expense = props.content as ExpenseType;

  const newDate = expense.date

  const currentYear = moment().format("YYYY")

  const movementDayMonth = moment(newDate).format("D MMM")
  const year = moment(newDate).format("YYYY")

  const movementYear =
    currentYear !== year ? (
      <div className={css.itemDateYear}>
        <div className={css.itemYear}>{year}</div>
      </div>
    ) : null;

  const classes = makeStyles();
  return (
    <div>
      <ListItem
        onClick={() => props.openExpense(expense.id)}
        className={`${classes.Item} ${css.listItem}`}
        button
      >
        <div className={css.ItemDate}>
          <div className={css.itemDayMonth}>{movementDayMonth}</div>
          {movementYear}
        </div>
        <div className={css.listItemInfo}>
          <div className={css.itemDesc}>{expense.history}</div>
          <div title="Tipo de despesa" className={css.itemLawyerIcon}>
            <Icon className={`fas fa-receipt ${classes.iconInfo}`} />
          </div>
          <div className={css.itemLawyerName}>{expense.expenseAccount.title}</div>
          <div title="Cliente" className={css.itemClientIcon}>
            <ContactsOutlined className={classes.iconInfo} />
          </div>
          <div className={css.itemClientName}>{expense.clientName}</div>
          <div title="Pasta/Caso" className={css.itemFolderIcon}>
            <FolderOutlined className={classes.iconInfo} />
          </div>
          <div className={css.itemFolderNumber}>
            {expense.process.folderNumber}
            {expense.process.title ? `- ${expense.process.title}` : null}
          </div>
        </div>
        {expense.status === "F" ? (
          <div title="Faturado" className={css.ItemStatus}>
            <div className={css.itemIconStatus}>
              <Icon className={`fas fa-file-invoice-dollar ${classes.iconStatus}`} />
            </div>
          </div>
        ) : null}
      </ListItem>
    </div>
  );
}

interface Props {
  content: ExpenseType;
  openExpense: (id: number) => void;
}
