import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    Item: {
      borderColor: "rgba(0, 0, 0, 0.12)",
      borderStyle: "solid",
      borderBottomWidth: "1px",
      display: "grid",
      gridTemplateRows: "1fr 1fr",
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingTop: "1px",
      paddingBottom: "1px",
      height: "130px",
      gridGap: "5px 10px"
    },

    iconInfo: {
      color: "#747474",
      width: "1em",
      height: "1em",
      fontSize: "1rem",
      alignSelf: "center"
    },
    iconTime: {
      color: "#747474",
      width: "1em",
      height: "1em",
      fontSize: "0.95rem",
      alignSelf: "center"
    },
    iconStatus: {
      color: "#747474",
      width: "1em",
      height: "1em",
      fontSize: "1.3rem",
      alignSelf: "center"
    }
  })
);
