import React, { ReactElement, useState } from "react";
import { Switch, Route, Redirect, RouteComponentProps } from "react-router";
import AuthPage from "../../Components/AuthPage/AuthPage";
import { LinearProgress } from "@material-ui/core";
import css from "./Auth.module.css";
import Login from "./Login";
import RecoveryRequest from "./Recovery/RecoveryRequest";
import PasswordReset from "./Recovery/PasswordReset";
import makeStyles from "./Styles";

export default Auth;

function Auth(props: RouteComponentProps): ReactElement {
  const classes = makeStyles();
  const [loading, setLoading] = useState(false);

  return (
    <div className={css.container}>
      <AuthPage>
        {loading ? <LinearProgress className={classes.loading} /> : null}
        <Switch>
          <Route
            render={props => <Login {...props} loading={loading} setLoading={setLoading} />}
            path="/auth/login"
          />
          <Route
            render={props => (
              <RecoveryRequest {...props} loading={loading} setLoading={setLoading} />
            )}
            path="/auth/recuperacao"
          />
          <Route
            render={props => <PasswordReset {...props} loading={loading} setLoading={setLoading} />}
              path="/auth/recuperacao-nova-senha/:token/"
          />
          <Redirect to="/not-found" />
        </Switch>
      </AuthPage>
    </div>
  );
}
