import React, { useState, ChangeEvent, SyntheticEvent } from "react";
import { Input, IconButton, Hidden } from "@material-ui/core";
import css from "./SearchList.module.css";
import { FilterListOutlined, SearchOutlined } from "@material-ui/icons";
import makeStyles from "./Styles";

export default SearchList;

function SearchList(props: Props) {
  const [valorInicial, setValor] = useState<State>({
    searchString: ""
  });

  const handleSubmit = async (e: SyntheticEvent<EventTarget>) => {
    e.preventDefault();
    props.search(valorInicial.searchString);
  };

  const handleChange = (name: keyof State) => (event: ChangeEvent<HTMLInputElement>) => {
    setValor({ ...valorInicial, [name]: event.target.value });
  };

  const classes = makeStyles();

  const { className } = props;

  return (
    <div className={`${css.listSearch} ${className}`}>
      <div className={css.searchContainer}>
        <form className={css.search} onSubmit={handleSubmit}>
          <div className={css.filterMenu}>
            <Hidden lgUp>
              <IconButton
                className={classes.icon}
                onClick={() => {
                  props.setDrawer(true);
                }}
              >
                <FilterListOutlined />
              </IconButton>
            </Hidden>
          </div>
          <SearchOutlined className={css.iconSearch} color="action" />

          <Input
            className={css.inputSearch}
            onChange={handleChange("searchString")}
            placeholder="Pesquisar por..."
            disableUnderline
            fullWidth
            value={valorInicial.searchString}
          />
          <IconButton type="submit" className={classes.button}>
            <SearchOutlined className={classes.iconSearch} />
          </IconButton>
        </form>
      </div>
    </div>
  );
}

interface State {
  searchString: string;
}

interface Props {
  setDrawer: (state: boolean) => void;
  search: (str: string) => void;
  className?: string;
}
