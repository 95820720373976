import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    item: {
      borderColor: "rgba(0, 0, 0, 0.12)",
      borderStyle: "solid",
      borderBottomWidth: "1px",
      display: "grid",
      gridTemplateRows: "1fr",
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingTop: "1px",
      paddingBottom: "1px",
      height: "55px"
    },

    iconInfo: {
      color: "#747474",
      width: "1em",
      height: "1em",
      fontSize: "1rem",
      alignSelf: "center"
    },
    iconTime: {
      color: "#747474",
      width: "1em",
      height: "1em",
      fontSize: "0.95rem",
      alignSelf: "center"
    },
    iconStatus: {
      color: "#747474",
      width: "1em",
      height: "1em",
      fontSize: "1.3rem",
      alignSelf: "center"
    },
    button: {
      margin: "0px 8px",
      padding: "2px 4px",
      backgroundColor: "#125ad3",
      fontSize: "0.7rem",
      "&:hover": {
        backgroundColor: "#1153c3"
      }
    },
    input: {
      fontSize: "0.9rem",
      "&.Mui-focused": {
        color: "#125ad3"
      }
    },
    select: {
      minWidth: "100px",
      fontSize: "0.9rem",
      marginTop: "5px !important",
      "&.MuiInput-underline::after": {
        borderBottom: "2px solid #125ad3"
      }
    },
    iconFilter: {
      color: "#125ad3"
    },
    iconButton: {
      padding: "4px"
    },
    icon: {
      color: "#747474",
      width: "1.3em",
      height: "1em",
      fontSize: "1.1rem",
      textAlign: "center"
    },
    list: {
      paddingTop: "0",
      paddingBottom: "0",
      display: "grid",
      gridAutoRows: "max-content",
      overflow: "auto"
    },
    loading: {
      alignSelf: "center",
      justifySelf: "center",
      color: "#125ad3"
    },
    sklText: {
      margin: "6px 0"
    }
  })
);
