import { ExpenseType, TimesheetType, ProcMovementType } from "../../declarations";
import { isKeyIncludedIn } from "../../Utils/isKeyIncludedInObject";

export const initialState: State = {
  initialized: false,
  charts: {
    monthBalance: {
      title: "Balanço do faturamento (últimos 6 meses)",
      name: "monthBalance",
      label: "Ganhos em reais",
      error: null,
      loading: true,
      type: "bar",
      data: undefined,
      zoom: undefined,
      stacked: true
    },
    revenuePerClient: {
      title: "Receita por cliente (últimos 6 meses)",
      name: "revenuePerClient",
      label: "Ganhos em reais",
      error: null,
      loading: true,
      type: "pie",
      data: undefined
    },
    totalReceivedBills: {
      title: "Total recebido (últimos 6 meses)",
      name: "totalReceivedBills",
      label: "Total Faturas a receber",
      error: null,
      loading: true,
      type: "bar",
      data: undefined,
      stacked: true,
      horizontal: true,
      zoom: undefined
    }
  },
  collection: {
    expenses: {
      list: [],
      loading: true,
      error: null
    },
    timesheets: {
      list: [],
      loading: true,
      error: null
    },
    procMovements: {
      list: [],
      loading: true,
      error: null
    }
  }
};

export function reducer(state: State, action: Action): State {
  let newState = { ...state };

  if (action.type === "initialized") {
    newState.initialized = true;
  }

  if (action.type === "revenuePerClient") {
    const reqData = action.payload;
    const newData: PieChart["data"] = {
      series: reqData.entries.map(e => e.value),
      labels: reqData.entries.map(e => e.name)
    };
    newState.charts.revenuePerClient.data = newData;
  }
  if (action.type === "monthBalance") {
    const reqData = action.payload;
    const newData: BarChart["data"] = {
      xAxisLabels: reqData.map(r => `${r.month}/${r.year}`),
      series: [
        { name: "Recebido", data: reqData.map(r => r.received) },
        { name: "A receber", data: reqData.map(r => r.toReceive) }
      ]
    };
    newState.charts.monthBalance.data = newData;
  }

  if (action.type === "totalReceivedBills") {
    const reqData = action.payload;
    const newData: BarChart["data"] = {
      xAxisLabels: reqData.map(r => r.client),
      series: [
        { name: "Total recebido", data: reqData.map(r => r.totalReceived) },
        { name: "Total a receber", data: reqData.map(r => r.totalToReceive) }
      ]
    };
    newState.charts.totalReceivedBills.data = newData;
  }

  if (
    action.type === "expenses" ||
    action.type === "timesheets" ||
    action.type === "procMovements"
  ) {
    newState.collection[action.type].list = action.payload;
  }

  if (action.type === "loading") {
    // Verifica se action.payload.name tem nome de alguma key dentro de state.charts
    if (isKeyIncludedIn<State["charts"]>(newState.charts, action.payload.name)) {
      newState.charts[action.payload.name as ChartNames].loading = action.payload.value;
      // Verifica se action.payload.name tem nome de alguma key dentro de state.collection
    } else if (isKeyIncludedIn(newState.collection, action.payload.name)) {
      newState.collection[action.payload.name as ListNames].loading = action.payload.value;
    }
  }
  if (action.type === "error") {
    // Verifica se action.payload.name tem nome de alguma key dentro de state.charts
    if (isKeyIncludedIn<State["charts"]>(newState.charts, action.payload.name)) {
      newState.charts[action.payload.name as ChartNames].error = action.payload.value;
      // Verifica se action.payload.name tem nome de alguma key dentro de state.collection
    } else if (isKeyIncludedIn<State["collection"]>(newState.collection, action.payload.name)) {
      newState.collection[action.payload.name as ListNames].error = action.payload.value;
    }
  }

  return newState;
}

export interface State {
  initialized: boolean;
  charts: {
    revenuePerClient: PieChart;
    monthBalance: BarChart;
    totalReceivedBills: BarChart;
  };
  collection: {
    timesheets: ListData<TimesheetType>;
    expenses: ListData<ExpenseType>;
    procMovements: ListData<ProcMovementType>;
  };
}

export type Action =
  | SimpleAction
  | FetchChartAction<"revenuePerClient", RevenuePerClientData>
  | FetchChartAction<"monthBalance", MonthBalanceData>
  | FetchChartAction<"totalReceivedBills", TotalReceivedBillsData>
  | FetchListAction<"expenses", ExpenseType>
  | FetchListAction<"timesheets", TimesheetType>
  | FetchListAction<"procMovements", ProcMovementType>
  | LoadingAction
  | ErrorAction;

type SimpleAction = {
  type: "initialized";
};

interface LoadingAction {
  type: "loading";
  payload: { name: ChartNames | ListNames; value: boolean };
}
interface ErrorAction {
  type: "error";
  payload: { name: ChartNames | ListNames; value: string };
}

interface FetchChartAction<type extends ChartNames, ChartData> {
  type: type;
  payload: ChartData;
}

interface FetchListAction<type extends ListNames, ListDataType> {
  type: type;
  payload: ListDataType[];
}

export interface Chart {
  title: string;
  error: string | null;
  loading: boolean;
  type: ChartType;
  name: ChartNames;
  label: string;
}

export type Charts = PieChart | BarChart;
interface BarChart extends Chart {
  type: "bar";
  zoom?: {
    enabled?: boolean;
  };
  horizontal?: boolean;
  stacked?: boolean;
  dataLabels?: boolean;
  data?: {
    xAxisLabels: string[];
    series: {
      name: string;
      data: number[];
    }[];
  };
}
interface PieChart extends Chart {
  type: "pie";
  data?: {
    labels: string[];
    series: number[];
  };
}

export interface ListData<type> {
  list: type[];
  loading: boolean;
  error: string | null;
}

// Os nomes devem ser idênticos às keys dentro do initialState.charts
type ChartNames = "revenuePerClient" | "monthBalance" | "totalReceivedBills";
// Os nomes devem ser idênticos às keys dentro do initialState.collection
export type ListNames = "expenses" | "timesheets" | "procMovements";

export type MonthEarningsData = {
  value: number;
  month: number;
  year: number;
}[];

export type MonthBalanceData = {
  toReceive: number;
  received: number;
  month: number;
  year: number;
}[];

export type RevenueComparisonData = {
  amounts: { revenue: number; spent: number };
  month: number;
  year: number;
}[];

export type RevenuePerClientData = {
  fromDate: string;
  toDate: string;
  entries: {
    value: number;
    name: string;
  }[];
};

export type TotalReceivedBillsData = {
  totalToReceive: number;
  totalReceived: number;
  client: string;
}[];

export type ChartType =
  | "area"
  | "line"
  | "bar"
  | "histogram"
  | "pie"
  | "donut"
  | "radialBar"
  | "scatter"
  | "bubble"
  | "heatmap"
  | "candlestick"
  | "radar";
