/**
 * Converte uma key com nome de date dentro de um objeto.
 * @example normalizeDataIn<TimesheetType>({
 *  // ...,
 *  date: "2019-10-29 00:00:00",
 *  revisionDate: "2019-10-31 00:00:00",
 * });
 * return {
 *  // ...,
 *  date: Tue Oct 29 2019 00:00:00 GMT-0300 (Brasilia Standard Time) {},
 *  revisionDate: Thu Oct 31 2019 00:00:00 GMT-0300 (Brasilia Standard Time) {},
 * };
 */
export function normalizeDateIn<obj>(
  request: obj & { date: string | Date; revisionDate?: string | Date }
): obj {

  const [day, mounth, year] = request.date
    .toString()
    .trim()
    .split("-")
    .map((date: string) => parseInt(date))
  request.date = new Date(day, mounth - 1, year);

  if (request.hasOwnProperty("revisionDate") && typeof request.revisionDate === "string"){
  const [day, mounth, year] = request.revisionDate
    .toString()
    .trim()
    .split("-")
    .map((date: string) => parseInt(date))
    request.revisionDate = new Date(day, mounth - 1, year);
  }
  return request;
}
