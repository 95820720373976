import React, { useState, SyntheticEvent, ChangeEvent, ReactElement } from "react";
import { TextField, Button } from "@material-ui/core";
import css from "./Recovery.module.css";
import Title from "../../../Components/Title/Title";
import { Link, RouteComponentProps } from "react-router-dom";
import apiCaller from "../../apiCaller";
import { ErrorOutlined } from "@material-ui/icons";
import makeStyles from "./Styles";

export default function(
  props: RouteComponentProps & { loading: boolean; setLoading: (newState: boolean) => void }
): ReactElement {
  const { setLoading } = props;
  const [error, setError] = useState();
  const [state, setState] = useState<State>({
    username: "",
    sent: false,
    maskedEmail: ""
  });

  const canBeSubmitted = () => {
    const { username } = state;
    return username.length > 0;
  };

  const handleSubmit = async (e: SyntheticEvent<EventTarget>) => {
    e.preventDefault();
    const { username } = state;
    setLoading(true);
    const { data, error } = await apiCaller.post("/auth/passwordRecovery", { username });
    if (error) {
      if (error !== 1) setError(error);
    } else {
      const { maskedEmail } = data;
      setState(s => ({ ...s, sent: true, maskedEmail }));
    }
    setLoading(false);
  };

  const handleChange = (field: keyof State) => (e: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [field]: e.target.value });
  };

  const classes = makeStyles();

  if (state.sent)
    return (
      <div className={css.recoveryForm} onSubmit={handleSubmit}>
        <Title className={css.titleRecovery}>E-mail enviado com sucesso</Title>
        <p className={css.noticeRecovery}>
          Um e-mail de recuperação foi enviado para <b>{state.maskedEmail}</b>
        </p>
        <p className={css.noticeRecovery}>
          Verifique sua caixa de entrada e clique no link de recuperação de senha.
        </p>
        <Link className={css.link} to="/auth/Login">
          Ir para Login
        </Link>
      </div>
    );

  return (
    <form className={css.recoveryForm} onSubmit={handleSubmit}>
      <Title className={css.titleRecovery}>RECUPERE A SUA SENHA</Title>
      <TextField
        required
        className={`${css.formControl} ${classes.textField}`}
        type="username"
        label="Usuário"
        onChange={handleChange("username")}
      />
      {error === "User needs a valid Email" ? (
        <div className={css.errorRecovery}>
          <ErrorOutlined className={css.iconError} color="error" fontSize="small" />
          <p className={css.errorText}>Usuário não possui um e-mail válido</p>
        </div>
      ) : error === "Bad Request" ? (
        <div className={css.errorRecovery}>
          <ErrorOutlined className={css.iconError} color="error" fontSize="small" />
          <p className={css.errorText}>Usuário incorreto</p>
        </div>
      ) : (
        undefined
      )}

      <Button
        type="submit"
        className={`${classes.button} ${css.recoveryBtn}`}
        variant="contained"
        color="primary"
        disabled={!canBeSubmitted()}
      >
        Solicitar
      </Button>
      <Link className={css.link} to="/auth/Login">
        Voltar ao Login
      </Link>
    </form>
  );
}

interface State {
  username: string;
  error?: string;
  sent: boolean;
  maskedEmail: string;
}
