import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    button: {
      padding: "0px",
      width: "40px",
      backgroundColor: "#125ad3",
      gridColumn: 4,
      borderTopLeftRadius: "0px",
      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "4px",
      borderTopRightRadius: "4px",
      "&:hover": {
        backgroundColor: "#043bbf"
      }
    },
    icon: {
      padding: "4px"
    },
    iconSearch: {
      color: "#fff"
    }
  })
);
