import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    loading: {
      gridRow: "1",
      height: "5px",
      overflow: "hidden",
      position: "relative",
      margin: "-20px 0",
      borderRadius: "20px",
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#125ad3"
      }
    }
  })
);
