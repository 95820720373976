import React, { Fragment } from "react";
import { ListItem, IconButton, Popover } from "@material-ui/core";
import css from "./ContactsListItem.module.css";
import { PhoneOutlined, SmartphoneOutlined, BusinessOutlined } from "@material-ui/icons";
import { formatPhone, formatCellPhone } from "../../../Utils/fieldFormat";
import makeStyles from "./Styles";

export default ContactsListItem;

function ContactsListItem(props: any) {
  const { content: contact } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  }

  function handleClose(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setAnchorEl(null);
    event.stopPropagation();
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const classes = makeStyles();

  const phoneNumber = formatPhone(contact.phoneNumber === null ? "" : contact.phoneNumber);
  const cellPhoneNumber = formatCellPhone(
    contact.cellPhoneNumber === null ? "" : contact.cellPhoneNumber
  );

  const statusStyled = () => {
    switch (contact.status) {
      case false:
        return undefined;
      case true:
        return { color: "rgba(255, 0, 0, 0.5)", textDecoration: "line-through" };
    }
  };

  return (
    <div>
      <ListItem
        onClick={() => props.openContact(contact.id)}
        className={`${classes.Item} ${css.listItem}`}
        dense
        button
      >
        <div className={css.listItemInfo}>
          <div className={css.itemName} style={statusStyled()}>{contact.name}</div>
          <div className={css.itemEmail}>{contact.email}</div>
          <div className={css.listItemPhone}>
            {phoneNumber !== "" ? (
              <div className={css.itemPhone}>
                <PhoneOutlined className={classes.icon} />
                <div className={css.phoneNumber}>{phoneNumber}</div>
              </div>
            ) : null}
            {cellPhoneNumber !== "" ? (
              <div className={css.itemCellPhone}>
                <SmartphoneOutlined className={classes.icon} />
                <div className={css.phoneNumber}>{cellPhoneNumber}</div>
              </div>
            ) : null}
          </div>
        </div>

        <div title={`Contato de ${contact.contactOrigin}`} className={css.itemContactOrigin}>
          {contact.id !== contact.contactOriginId ? (
            <Fragment>
              <IconButton
                className={classes.iconButton}
                aria-describedby={id}
                onClick={handleClick}
              >
                <BusinessOutlined className={classes.iconOrigin} />
                <span className={css.originTitle}>origem</span>
              </IconButton>
              <Popover
                className={classes.popover}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "right"
                }}
              >
                Contato de {contact.contactOrigin}
              </Popover>
            </Fragment>
          ) : (
            undefined
          )}
        </div>
      </ListItem>
    </div>
  );
}
