import { makeStyles, createStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    dialog: {
      "& .MuiDialog-paperWidthMd": {
        minHeight: "90vh",
        "@media only screen and (max-width: 820px)": {
          width: "100%",
          height: "100%",
          margin: "0",
          maxWidth: "100%",
          maxHeight: "none",
          borderRadius: "0"
        }
      }
    },
    dialogTitle: {
      zIndex: 1,
      backgroundColor: "#125ad3",
      color: "#fff",
      gridTemplateColumns: "1fr 1fr",
      padding: "4px",
      boxShadow:
        "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)"
    },
    dialogTitleLinked: {
      backgroundColor: "#fff",
      gridTemplateColumns: "1fr 1fr",
      padding: "4px",
      boxShadow:
        "0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px 0px rgba(0, 0, 0, 0), 0px 1px 10px 0px rgba(0, 0, 0, 0.1)"
    },
    dialogActions: {
      display: "grid",
      gridTemplateColumns: "1fr auto",
      gridTemplateRows: "30px",
      gridGap: "10px",
      padding: "8px 24px",
      boxShadow:
        "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
      "@media only screen and (max-width: 600px)": {
        gridTemplateColumns: "1fr",
        gridTemplateRows: "50px",
        padding: "8px 10px"
      }
    },
    toggleButton: {
      height: "30px",
      display: "grid",
      padding: "0px 8px",
      color: "rgba(0, 0, 0, 0.7)",
      "&.MuiToggleButton-root.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.4)"
      }
    },
    editIcon: {
      gridColumn: "2",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "end",
      color: "#fff",
      padding: "4px"
    },
    closeIcon: {
      gridColumn: "1",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "start",
      color: "#fff",
      padding: "4px"
    },
    closeIconLinked: {
      gridColumn: "1",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "start",
      padding: "4px"
    },
    loading: {
      height: "5px",
      position: "relative",
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#125ad3"
      }
    },
    icon: {
      color: "#737373",
      fontSize: "20px",
      gridRow: "1",
      gridColumn: "1"
    },
    button: {
      color: "#125ad3",
      lineHeight: "0px"
    },
    dialogButton: {
      gridColumn: "2",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "end",
      margin: "10px",
      color: "#125ad3"
    }
  })
);
