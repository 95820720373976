import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    root: {
      transform: "translateZ(0px)",
      flexGrow: 1
    },

    speedDial: {
      position: "absolute",
      "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
        bottom: "10px",
        right: "20px"
      },
      "& .MuiFab-primary": {
        backgroundColor: "#125ad3",
        width: "46px",
        height: "46px",
        "&:hover": {
          backgroundColor: "#043bbf"
        }
      }
    },
    icon: {
      "& .MuiSpeedDialIcon-iconOpen": {
        transform: "none"
      }
    }
  })
);
