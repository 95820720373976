import React, { useState, useEffect, useCallback } from "react";
import css from "../Timesheets/Timesheets.module.css";
import apiCaller from "../apiCaller";
import SearchList from "../../Components/SearchList/SearchList";
import { Period, TimesheetType, PersonBasic } from "../../declarations";
import TimesheetsListItem from "./ListItem/TimesheetsListItem";
import MainList from "../../Components/MainList/MainList";
import { Action, State, OrderFieldNames } from "./TimesheetsStore";
import BackDrawer from "../../Components/BackDrawer/BackDrawer";
import { RouteComponentProps } from "react-router";
import Timesheet from "./Timesheet/Timesheet";
import TimesheetAdd from "./Add/TimesheetAdd";
import TimesheetsSidebar from "./Sidebar/TimesheetsSidebar";
import SpeedDialsAdd from "../../Components/SpeedDialsAdd/SpeedDialsAdd";
import { compareWithoutDiacritics } from "../../Utils/compareWithoutDiacritics";
import { normalizeDateIn } from "../../Utils/normalizeDateIn";

export default Timesheets;

function Timesheets({
  people,
  dispatch,
  fetched,
  query,
  displayMode,
  order,
  orderFields,
  refreshing,
  selectedPeriod,
  chargeable,
  charged,
  selectedExecutionLawyer,
  history,
  defaultExecutionLawyer,
  ...props
}: { dispatch: (a: Action) => void; people: PersonBasic[] } & State & RouteComponentProps) {
  const currentMode = props.modes[displayMode];
  const currentQuery = props.queryPaginations[query];
  const idParam = (props.match.params as { id?: string }).id;
  const openTimesheetId = idParam !== undefined && !isNaN(+idParam) ? +idParam : undefined;
  const setOpenTimesheet = (id: number) => history.push("/honorarios/" + (id ? id : ""));

  useEffect(
    function() {
      dispatch({ type: "updateQuery" });
    },
    [
      dispatch,
      currentMode.search,
      refreshing,
      order,
      selectedPeriod,
      displayMode,
      chargeable,
      charged,
      selectedExecutionLawyer
    ]
  );

  let currentQueryLastPage = -1;
  if (currentQuery) currentQueryLastPage = currentQuery.lastPage;

  const fetchNextPage = useCallback(
    function() {
      dispatch({ type: "loading", payload: { query, loading: true } });
      (async function() {
        const { data, error } = await apiCaller.get<any>(
          query + `&pageLength=20&pageNumber=${currentQueryLastPage}`
        );
        if (!error) {
          const normalized: TimesheetType[] = [];
          for (const ts of data.data) {
            normalized.push(ts);
          }
          dispatch({
            type: "fetchNextPage",
            payload: {
              data: normalized.map((ts: TimesheetType) => normalizeDateIn<TimesheetType>(ts)),
              query
            }
          });
        } else dispatch({ type: "error", payload: error });

        dispatch({ type: "loading", payload: { query, loading: false } });
      })();
    },
    [currentQueryLastPage, dispatch, query]
  );

  const refresh = () => {
    dispatch({ type: "refresh" });
  };

  useEffect(() => {
    if (currentQuery && !currentQuery.endReached && currentQuery.owns.size === 0) {
      fetchNextPage();
    }
  }, [currentQuery, fetchNextPage, query]);

  const search = useCallback(
    async (searchString: string) => {
      dispatch({ type: "search", payload: searchString });
    },
    [dispatch]
  );

  useEffect(
    function () {
      (async function () {
        const { data } = await apiCaller.get<any>(`/timesheets`);
        setCanAdd(data.canAdd);
      })();
    },
    []
  );

  const [canAdd, setCanAdd] = useState(false)

  const setChargeableFilter = (value: boolean | null) =>
    dispatch({ type: "chargeable", payload: value });
  const setChargedFilter = (value: boolean | null) => dispatch({ type: "charged", payload: value });
  const setSelectedExecutionLawyer = (value: PersonBasic | null) =>
    dispatch({ type: "selectedExecutionLawyer", payload: value });

  const isFilterActive =
    displayMode !== 0 ||
    currentMode.search.length > 0 ||
    selectedPeriod.fromDate !== null ||
    chargeable !== null ||
    charged !== null ||
    selectedExecutionLawyer !== defaultExecutionLawyer;
  const clearFilters = function() {
    dispatch({ type: "clearFilters" });
  };

  const [showDrawer, setDrawer] = useState<boolean>(false);
  const toggleDrawer = function() {
    setDrawer(s => !s);
  };

  const sidebar = (
    <TimesheetsSidebar
      people={people}
      chargeable={chargeable}
      setChargeable={setChargeableFilter}
      charged={charged}
      setCharged={setChargedFilter}
      selectedExecutionLawyer={selectedExecutionLawyer}
      setSelectedExecutionLawyer={setSelectedExecutionLawyer}
      clearFilters={clearFilters}
      isFilterActive={isFilterActive}
      displayMode={displayMode}
      selectedPeriod={selectedPeriod}
      setDrawer={setDrawer}
      setSelectedPeriod={(func: (period: Period) => Period) => {
        dispatch({ type: "selectedPeriod", payload: func(selectedPeriod) });
      }}
      setDisplayMode={(i: number) => dispatch({ type: "displayMode", payload: i })}
    />
  );
  const drawer = { showDrawer, setDrawer, toggleDrawer, drawerContent: sidebar };

  const searchFields: string[] = [
    "region",
    "executionLawyer.contactName",
    "chargingLawyer.contactName",
    "client.contactName",
    "process.title",
    "process.number",
    "process.numberUnified"
  ];

  const displayingTimesheets = Object.values(fetched)
    .filter(c => {
      let show =
        currentMode.filterFunction(c) &&
        currentQuery.owns.has(c.id) &&
        (chargeable === null || chargeable === c.chargeable) &&
        (charged === null || charged === (c.status === "F")) &&
        (selectedExecutionLawyer === null ||
          selectedExecutionLawyer.personId === c.executionLawyer.personId);

      if (show && currentMode.search.length > 0) {
        show = false;
        for (let searchField of searchFields) {
          const propertyPath = searchField.split(".");

          const value: null | string | number = propertyPath.reduce(
            (current: any, key) => (current = current[key]),
            c
          );
          show = show || compareWithoutDiacritics(currentMode.search, value);
        }
      }
      if (selectedPeriod.fromDate !== null && selectedPeriod.toDate !== null && !setOpenTimesheet) {
        show =
          show &&
          c.date.getTime() <= selectedPeriod.toDate.getTime() &&
          c.date.getTime() >= selectedPeriod.fromDate.getTime();
      }
      return show;
    })
    .sort((a: TimesheetType, b: TimesheetType) => {
      if (order.field.name === "id") {
        if (order.mode === "ASC") return a.id - b.id;
        else return b.id - a.id;
      } else if (order.field.name === "date") {
        const aMs = new Date(a.date).getTime();
        const bMs = new Date(b.date).getTime();

        if (order.mode === "ASC") {
          if (aMs === bMs) return a.id - b.id;
          return aMs - bMs;
        } else {
          if (aMs === bMs) return b.id - a.id;
          return bMs - aMs;
        }
      }
      return 0;
    });
  const updateTimesheet = useCallback(
    (id: number, updateFunction: (a: TimesheetType) => TimesheetType) => {
      const timesheet = updateFunction(fetched[id]);
      dispatch({ type: "updateTimesheet", payload: timesheet });
    },
    [dispatch, fetched]
  );
  const deleteTimesheet = useCallback(
    (id: number) => {
      dispatch({ type: "deleteTimesheet", payload: id });
    },
    [dispatch]
  );

  const [openAdd, setOpenAdd] = useState(false);

  return (
    <>
      <BackDrawer {...drawer} />
      {openTimesheetId ? (
        <Timesheet
          deleteTimesheet={deleteTimesheet}
          history={history}
          timesheetId={openTimesheetId}
          timesheet={fetched[openTimesheetId]}
          openTimesheet={setOpenTimesheet}
          updateTimesheet={updateTimesheet}
        />
      ) : null}
      {openAdd ? (
        <TimesheetAdd history={history} openAdd={openAdd} setOpenAdd={setOpenAdd} />
      ) : null}
      <div className={css.main}>
        <div className={css.timesheetToolbar} />
        <div className={css.timesheetMain}>
          <div className={css.timesheetSidebar}>{sidebar}</div>
          <div className={css.timesheetList}>
            <SearchList search={search} {...drawer} />
            <MainList<TimesheetType, { openTimesheet: (id: number) => void }, OrderFieldNames>
              {...{
                orderFields,
                endReached: currentQuery && currentQuery.endReached,
                loading: currentQuery && currentQuery.loading,
                refresh,
                currentSearch: currentMode.search,
                setOrder: func => dispatch({ type: "order", payload: func(order) }),
                order,
                isFilterActive,
                clearFilters,
                contentArray: displayingTimesheets,
                ListComponent: TimesheetsListItem,
                listComponentProps: {
                  openTimesheet: setOpenTimesheet
                },
                fetchNextPage: () => {
                  if (!currentQuery.loading && !currentQuery.endReached) fetchNextPage();
                }
              }}
            />
            <SpeedDialsAdd canAdd={canAdd} openAdd={openAdd} setOpenAdd={setOpenAdd} />
          </div>
        </div>
      </div>
    </>
  );
}

// import rtfParser from "rtf-parser";
// export async function normalizeTimesheet(requestTimesheet: TimesheetType): Promise<TimesheetType> {
//   if (requestTimesheet.discrimination.indexOf("{\\rtf") === 0) {
//     const discriminationObj: any = await new Promise((res, rej) =>
//       rtfParser.string(requestTimesheet.discrimination, (err, doc) => {
//         if (err) res(requestTimesheet.discrimination);
//         else res(doc);
//       })
//     );
//     let discrimination: string = "";

//     Array.from(discriminationObj.content).forEach(
//       (p: any) =>
//         (discrimination += Array.from(p.content).reduce((v, s: any) => v + s.value, "") + "\r\n")
//     );
//     requestTimesheet.discrimination = discrimination;
//   }
//   return requestTimesheet;
// }
