import { createStyles, makeStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    autoComplete: {
      paddingRight: "8px",
      maxWidth: "350px"
    },
    client: {
      maxWidth: "350px"
    },
    textField: {
      color: "white",
      height: 48,
      "& .MuiInputBase-root": {
        fontSize: "0.9rem"
      },
      "&:hover:not(.Mui-disabled):before": {
        borderBottom: "2px solid #125ad3"
      },
      "&:after": {
        borderBottom: "2px solid #125ad3"
      },

      "& label.Mui-focused": {
        color: "#125ad3"
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#125ad3"
      },
      "& .MuiInput-underline": {
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: "2px solid #125ad3"
        },
        "&:after": {
          borderBottom: "2px solid #125ad3"
        }
      }
    },
    loading: {
      alignSelf: "center",
      justifySelf: "center",
      color: "#125ad3"
    }
  })
);
