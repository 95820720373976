import React, { useEffect, useState } from "react";
import apiCaller from "../../Root/apiCaller";
import css from "./RecentCases.module.css";

export function RecentCases({ getCase }: { getCase(n: string): void }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [recentCases, setRecentCases] = useState<CaseBasic[]>([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { error, data } = await apiCaller.get<{ data: CaseBasic[] }, string>("/cases/recent");

      if (data !== undefined) {
        setRecentCases(data.data);
      } else if (error !== undefined) {
        setError(error);
      }

      setLoading(false);
    })();
  }, []);

  return (
    <>
      <div className={css.containerRecentCases}>
        <div className={css.contentTitle}>Casos recentes</div>
        <div className={css.listRecentCases}>
          {error && loading
            ? "carregando"
            : recentCases.map((c, i) => {
                return (
                  <div key={i} onClick={() => getCase(c.folderNumber)} className={css.viewCase}>
                    <div className={css.itemRecentCases}>
                      {c.folderNumber} - {c.title}
                      <span style={{ fontWeight: 400 }}>( {c.clientName})</span>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </>
  );
}

interface CaseBasic {
  clientName: string;
  title: string;
  folderNumber: string;
}
