import React, { useContext } from "react";
import css from "./AssignmentsSidebar.module.css";
import {
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Button
} from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import makeStyles from "./Styles";

import {
  CheckBoxOutlined,
  ReorderOutlined,
  TodayOutlined,
  CheckOutlined,
  Filter7Outlined,
  Filter3Outlined,
  CloseOutlined,
  AccessTimeOutlined,
  FilterListOutlined
} from "@material-ui/icons";
import { Period } from "../../../declarations";
import AutoComplete from "../../../Components/AutoComplete/AutoComplete";
import { userContext } from "../../Root";

export default AssignmentsSidebar;

function AssignmentsSidebar({
  clearFilters,
  isFilterActive,
  setSelectedPeriod,
  selectedPeriod,
  displayMode,
  setDisplayMode,
  setSelectedAssigned,
  selectedAssigned,
  setDrawer,
  userGroup
}: Props) {
  const closeDrawer = () => (setDrawer ? setDrawer(false) : null);
  const periodDisabled = [1, 5, 6].includes(displayMode);
  const classes = makeStyles();

  const { user } = useContext(userContext);

  const defaultOption: {
    key: any;
    label: string;
    value: { contactName: string; userId: number } | null;
  } =
    user === null || user.personId === null || user.contactName === null
      ? { key: 0, label: "Pesquisar...", value: null }
      : {
          key: 0,
          label: user.contactName,
          value: {
            userId: user.id,
            contactName: user.contactName
          }
        };

  const options = [
    defaultOption,
    ...userGroup.map(person => ({
      label: person.contactName,
      key: "" + person.userId,
      value: person
    }))
  ];

  return (
    <div className={css.sidebarContainer}>
      <div className={css.sidebarSup}>
        <div className={css.sidebarIconTitle}>
          <CheckBoxOutlined className={classes.iconTitle} />
          <p className={css.sidebarSupTitle}>Prazos</p>
        </div>
        <div className={css.userAssigned}>
          <AutoComplete<{ contactName: string; userId: number }>
            label="Atribuído á"
            options={options}
            selected={
              selectedAssigned === null
                ? defaultOption
                : {
                    value: selectedAssigned,
                    key: "" + selectedAssigned.userId,
                    label: selectedAssigned.contactName
                  }
            }
            setSelected={option => setSelectedAssigned(option.value)}
          />
        </div>
      </div>
      <div className={css.sidebarInf}>
        <List className={classes.sidebarInfList}>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 0}
            onClick={() => {
              setDisplayMode(0);
              closeDrawer();
            }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <ReorderOutlined className={classes.itemIcon} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText} primary="Todos" />
          </ListItem>

          <Divider />
          <ListSubheader disableSticky={true}>FILTROS</ListSubheader>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 1}
            onClick={() => {
              setDisplayMode(1);
              closeDrawer();
            }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <TodayOutlined className={classes.itemIcon} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText} primary="Hoje" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 2}
            onClick={() => {
              setDisplayMode(2);
              closeDrawer();
            }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <AccessTimeOutlined className={classes.itemIcon} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText} primary="Pendentes" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 4}
            onClick={() => {
              setDisplayMode(4);
              closeDrawer();
            }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <CheckOutlined className={classes.itemIcon} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText} primary="Concluídos" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 3}
            onClick={() => {
              setDisplayMode(3);
              closeDrawer();
            }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <CloseOutlined className={classes.itemIcon} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText} primary="Cancelados" />
          </ListItem>
          <Divider />
          <ListSubheader disableSticky={true}>Próximos</ListSubheader>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 5}
            onClick={() => {
              setDisplayMode(5);
              closeDrawer();
            }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Filter3Outlined className={classes.itemIcon} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText} primary="3 Dias" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 6}
            onClick={() => {
              setDisplayMode(6);
              closeDrawer();
            }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Filter7Outlined className={classes.itemIcon} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText} primary="7 Dias" />
          </ListItem>
          <Divider />

          <ListSubheader disableSticky={true}>Período</ListSubheader>
          <div className={css.pickerPeriod}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <KeyboardDatePicker
                DialogProps={{ className: classes.datePicker }}
                disabled={periodDisabled}
                showTodayButton={true}
                todayLabel="Hoje"
                cancelLabel="Cancelar"
                maxDate={selectedPeriod.toDate ? selectedPeriod.toDate : undefined}
                invalidLabel="Data inválida"
                invalidDateMessage="Data inválida"
                className={classes.picker}
                format="dd/MM/yyyy"
                margin="normal"
                label="De"
                value={selectedPeriod.fromDate}
                onChange={date => {
                  if (date === null) return;
                  date.setHours(0, 0, 0, 0);
                  setSelectedPeriod(period => ({ ...period, fromDate: date }));
                }}
              />
              <KeyboardDatePicker
                DialogProps={{ className: classes.datePicker }}
                showTodayButton={true}
                todayLabel="Hoje"
                cancelLabel="Cancelar"
                minDate={selectedPeriod.fromDate}
                disabled={!selectedPeriod.toDate || periodDisabled}
                invalidLabel="Data inválida"
                invalidDateMessage="Data inválida"
                className={classes.picker}
                format="dd/MM/yyyy"
                margin="normal"
                label="Até"
                value={selectedPeriod.toDate}
                onChange={date => {
                  if (date === null) return;
                  date.setHours(23, 59, 59, 0);
                  setSelectedPeriod(period => ({ ...period, toDate: date }));
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </List>
      </div>
      <div className={css.filter}>
        <Button
          fullWidth
          disabled={!isFilterActive}
          className={`${classes.button}`}
          onClick={clearFilters}
          variant="contained"
          color="primary"
        >
          <FilterListOutlined className={classes.iconFilter} />
          <span style={{ paddingRight: "20px" }}>Limpar filtros</span>
        </Button>
      </div>
    </div>
  );
}

interface Props {
  clearFilters: () => void;
  isFilterActive: boolean;
  setSelectedPeriod: (func: (period: Period) => Period) => void;
  selectedPeriod: { fromDate: Date | null; toDate: Date | null };
  displayMode: number;
  setDisplayMode: (mode: number) => void;
  setSelectedAssigned: (index: { contactName: string; userId: number } | null) => void;
  selectedAssigned: { contactName: string; userId: number } | null;
  setDrawer?: (state: boolean) => void;
  userGroup: { contactName: string; userId: number }[];
}

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);
