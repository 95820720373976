import { createStyles, makeStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    textField: {
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "2px solid #125ad3"
      },
      "& .MuiInput-underline:after": {
        borderBottom: "2px solid #125ad3"
      },
      "& .MuiFormLabel-root.Mui-focused": {
        color: "#125ad3"
      }
    },
    input: {
      display: "flex",
      padding: 0,
      height: "auto"
    },
    paper: {
      position: "absolute",
      zIndex: 1,
      left: 0,
      right: 0
    },
    noOptionsMessage: {
      textAlign: "center",
      color: "#7d7d7d"
    }
  })
);
