import React, { useEffect, useState } from "react";
import { CustomPicker } from "react-color";
import css from "./ColorPicker.module.css";
import { Check } from "@material-ui/icons";

export default CustomPicker(ColorPicker);

function ColorPicker(props: Props) {
  const [stateColor, setStateColor] = useState({
    selected: false,
    backgroundColor: ""
  });

  useEffect(() => {
    if(props.state.color){
      handleChange(props.state.color)
    }
  }, [])

  const handleChange = (colorSelected: string) => {
    setStateColor({ selected: true, backgroundColor: colorSelected });
    props.setState((state: any) => {
      state.color = colorSelected;
      return state;
    });
  };

  const colorsHex = [
    "#ff0000",
    "#ff6900",
    "#fcB900",

    "#009688",
    "#00d084",
    "#7bdCb5",

    "#2050ff",
    "#0693e3",
    "#82ceff",

    "#ff0f52",
    "#ff00a5",
    "#f78da7",

    "#9900ef",
    "#de00ff",
    "#ee89ff",

    "#484848",
    "#6f6f6f",
    "#abb8c3"
  ];

  const colorsPalette = colorsHex.map((color, i) => {
    return (
      <div
        key={i}
        onClick={() => handleChange(color)}
        style={{ backgroundColor: `${color}` }}
        className={css.color}
      >
        {stateColor.backgroundColor === color && stateColor.selected === true ? (
          <Check style={{ color: "#fff", fontSize: "1.6em" }} />
        ) : null}
      </div>
    );
  });

  return (
    <div className={css.colorBar}>
      {colorsPalette}
      <div
        onClick={() => setStateColor({ selected: false, backgroundColor: "" })}
        style={{ backgroundColor: "#fff", color: "#125ad3" }}
        className={css.color}
      >
        LIMPAR
      </div>
    </div>
  );
}

interface Props {
  setState: (state: any) => void;
  state?: any
}
