import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    listItemButton: {
      cursor: "pointer",
      paddingTop: "0px",
      paddingBottom: "0px",
      "&.MuiListItem-gutters": {
        paddingLeft: "4px",
        paddingRight: "4px"
      },
      "&.Mui-selected": {
        backgroundColor: "rgba(0, 0, 0, 0.05)"
      }
    },

    alphabeticList: {
      height: "calc(var(--vh, 1vh) * 100 - 265px)",
      padding: "0px"
    },
    itemTextFilter: {
      textAlign: "center",
      "& .MuiTypography-body1": {
        fontSize: "0.85rem"
      }
    },
    icon: {
      width: "0.9em",
      height: "0.9em",
      fontSize: "1.2rem",
      padding: "8px 0"
    }
  })
);
