import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    Item: {
      borderColor: "rgba(0, 0, 0, 0.12)",
      borderStyle: "solid",
      borderBottomWidth: "1px",
      display: "grid",
      paddingLeft: "0px",
      paddingRight: "8px",
      paddingTop: "1px",
      paddingBottom: "1px",
      height: "120px",
      gridGap: "5px 10px"
    },
    icon: {
      color: "#747474",
      width: "1.3em",
      height: "1em",
      fontSize: "1.1rem",
      textAlign: "center"
    },

    iconInfo: {
      color: "#747474",
      width: "1em",
      height: "1em",
      fontSize: "1rem",
      alignSelf: "center"
    }
  })
);
