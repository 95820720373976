/**
 * Compara se dois valores são iguais desconsiderando acentos, sinais e Uppercases.
 * @example compareWithoutDiacritics("DiLigEnçia", "diligencia");
 * return true;
 */
export function compareWithoutDiacritics(textBox: string, value: null | string | number): boolean {
  if (textBox === "") return false;
  if (value === undefined || value === null) return false;

  const expression = new RegExp(`${removeDiacritics(textBox)}`, "i");
  const sanitizedValue = removeDiacritics(String(value));
  return expression.test(sanitizedValue);
}

/**
 * Recebe string transforma para lowercase e retira caracteres especiais.
 * @example removeDiacritics("Diligência");
 * return "diligencia";
 */
function removeDiacritics(str: string): string {
  var map = {
    a: "á|à|ã|â|À|Á|Ã|Â",
    e: "é|è|ê|É|È|Ê",
    i: "í|ì|î|Í|Ì|Î",
    o: "ó|ò|ô|õ|Ó|Ò|Ô|Õ",
    u: "ú|ù|û|ü|Ú|Ù|Û|Ü",
    c: "ç|Ç",
    n: "ñ|Ñ"
  };

  str = str.toLowerCase();

  let pattern: "a" | "e" | "i" | "o" | "u" | "c" | "n";
  for (pattern in map) {
    str = str.replace(new RegExp(map[pattern], "g"), pattern);
  }

  return str;
}
