import React, { useState, useContext } from "react";
import css from "./Topbar.module.css";
import logo from "../../Image/logo_sisea.png";
import { Button, MenuItem, ListItemIcon, ListItemText, Popover, MenuList } from "@material-ui/core";
import { userContext } from "../../Root/Root";
import { KeyboardArrowDownOutlined, ExitToAppOutlined, AccountCircleOutlined } from "@material-ui/icons";
import { User } from "../../declarations";
import makeStyles from "./Styles";

export default Topbar;

function Topbar() {
  const [state, setState] = useState<HTMLButtonElement | null>(null);

  const { user, logout } = useContext(userContext);

  const { contactName } = user as User;

  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setState(event.currentTarget);
  }

  function handleClose() {
    setState(null);
  }

  const open = Boolean(state);
  const id = open ? "simple-popover" : undefined;

  const classes = makeStyles();

  return (
    <div className={css.topbar}>
      <div className={css.topBarLogo}>
        <img className={css.imgLogo} src={logo} alt="" />
      </div>

      <div className={css.topBarProfile}>
        <Button
          disableRipple
          className={classes.button}
          aria-describedby={id}
          onClick={handleClick}
        >
          <div>
            <p className={css.usernameProfile}>
              Olá, {(contactName || "").split(" ")[0] as string}
            </p>
          </div>
          <KeyboardArrowDownOutlined fontSize="small" />
        </Button>
        <Popover
          open={open}
          anchorEl={state}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <MenuList>
          <MenuItem className={classes.menuItem}>
            <ListItemIcon>
              <AccountCircleOutlined />
            </ListItemIcon>
            <ListItemText className={classes.listItemText} primary="Meu Perfil" />
          </MenuItem>
          <MenuItem onClick={logout} className={classes.menuItem}>
            <ListItemIcon>
              <ExitToAppOutlined />
            </ListItemIcon>
            <ListItemText className={classes.listItemText} primary="Sair" />
          </MenuItem>
          </MenuList>
        </Popover>
      </div>
      <div className={css.topBarLogout} />
    </div>
  );
}
