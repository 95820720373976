import React, { useContext } from "react";
import css from "./ExpensesSidebar.module.css";
import {
  List,
  Divider,
  Button,
  ListSubheader,
  RadioGroup,
  Radio,
  FormControlLabel,
  Icon
} from "@material-ui/core";

import { FilterListOutlined } from "@material-ui/icons";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/esm/locale";
import { Period, Account, PersonBasic } from "../../../declarations";
import AutoComplete from "../../../Components/AutoComplete/AutoComplete";
import makeStyles from "./Styles";
import { userContext } from "../../Root";

export default ExpensesSidebar;

function ExpensesSidebar({
  setSelectedExpenseType,
  selectedExpenseType,
  expenseTypes,
  chargeable,
  setChargeable,
  charged,
  setCharged,
  clearFilters,
  isFilterActive,
  setSelectedPeriod,
  selectedPeriod,
  displayMode,
  setDisplayMode,
  setDrawer,
  costCenters,
  selectedCostCenter,
  setSelectedCostCenter,
  people,
  setSelectedOwner,
  selectedOwner
}: Props) {

  const { user } = useContext(userContext);

  const classes = makeStyles();
  const periodDisabled = false;

  const typeDefaultOption = { label: "Pesquisar...", key: 0, value: null };
  const selectedTypeOption =
    selectedExpenseType === null
      ? typeDefaultOption
      : {
          label: selectedExpenseType.title,
          key: selectedExpenseType.id,
          value: selectedExpenseType
        };

  const ccDefaultOption = { label: "Pesquisar...", key: 0, value: null };
  const selectedCCOption =
    selectedCostCenter === null
      ? ccDefaultOption
      : {
          label: selectedCostCenter.title,
          key: selectedCostCenter.id,
          value: selectedCostCenter
        };

  const ownerDefaultOption =
    user === null || user.personId === null || user.contactName === null
      ? { label: "Pesquisar...", key: 0, value: null }
      : {
          label: user.contactName,
          key: user.id,
          value: {
            userId: user.id,
            personId: user.personId,
            class: user.class || "",
            contactName: user.contactName,
            contactId: user.contactId || 0
          }
        };

  const selectedOwnerOption =
    selectedOwner === null
      ? ownerDefaultOption
      : {
          label: selectedOwner.contactName,
          key: "" + selectedOwner.personId,
          value: selectedOwner
        };

  return (
    <div className={css.sidebarContainer}>
      <div className={css.sidebarSup}>
        <div className={css.sidebarIconTitle}>
          <Icon className={`fas fa-money-check-alt ${classes.iconTitle}`} />
          <p className={css.sidebarSupTitle}>Despesas</p>
        </div>
        <div className={css.selectExpenseAuthor}>
          <AutoComplete<PersonBasic | null>
            label="Lançado por"
            options={people.map((person, i) => ({
              key: person.personId || "",
              label: person.contactName,
              value: person
            }))}
            setSelected={option => setSelectedOwner(option.value)}
            selected={selectedOwnerOption}
          />
        </div>
      </div>
      <div className={css.sidebarInf}>
        <Divider />
        <div className={css.filterGroup}>
          <span className={css.filterTitle}>Faturável</span>
          <RadioGroup
            value={chargeable === null ? "Ambos" : chargeable ? "Sim" : "Não"}
            onChange={(_, value) =>
              setChargeable(value === "Ambos" ? null : value === "Sim" ? true : false)
            }
            row
          >
            <FormControlLabel
              className={classes.radioLabel}
              value="Sim"
              control={<Radio className={classes.radio} color="primary" />}
              label="Sim"
              labelPlacement="top"
            />
            <FormControlLabel
              className={classes.radioLabel}
              value="Não"
              control={<Radio className={classes.radio} color="primary" />}
              label="Não"
              labelPlacement="top"
            />
            <FormControlLabel
              className={classes.radioLabel}
              value="Ambos"
              control={<Radio className={classes.radio} color="primary" />}
              label="Ambos"
              labelPlacement="top"
            />
          </RadioGroup>
        </div>
        <Divider />
        <div className={css.filterGroup}>
          <span className={css.filterTitle}>Faturados</span>
          <RadioGroup
            value={charged === null ? "Ambos" : charged ? "Sim" : "Não"}
            onChange={(_, value) =>
              setCharged(value === "Ambos" ? null : value === "Sim" ? true : false)
            }
            row
          >
            <FormControlLabel
              className={classes.radioLabel}
              value="Sim"
              control={<Radio className={classes.radio} color="primary" />}
              label="Sim"
              labelPlacement="top"
            />
            <FormControlLabel
              className={classes.radioLabel}
              value="Não"
              control={<Radio className={classes.radio} color="primary" />}
              label="Não"
              labelPlacement="top"
            />
            <FormControlLabel
              className={classes.radioLabel}
              value="Ambos"
              control={<Radio className={classes.radio} color="primary" />}
              label="Ambos"
              labelPlacement="top"
            />
          </RadioGroup>
        </div>
        <Divider />
        <div className={css.selectCostCenter}>
          <AutoComplete<{ title: string; id: number } | null>
            label="Centro de custo"
            options={[
              ccDefaultOption,
              ...costCenters.map((cc, i) => ({
                key: cc.id,
                label: cc.title,
                value: cc
              }))
            ]}
            setSelected={option => setSelectedCostCenter(option.value)}
            selected={selectedCCOption}
          />
        </div>
        <div className={css.selectExpenseType}>
          <AutoComplete<Account | null>
            label="Tipo de despesa"
            options={[
              typeDefaultOption,
              ...expenseTypes.map((expenseType, i) => ({
                key: expenseType.id,
                label: expenseType.title,
                value: expenseType
              }))
            ]}
            setSelected={option => setSelectedExpenseType(option.value)}
            selected={selectedTypeOption}
          />
        </div>
        <Divider />
        <List className={classes.sidebarInfList}>
          <ListSubheader disableSticky={true}>Período</ListSubheader>
          <div className={css.pickerPeriod}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <KeyboardDatePicker
                DialogProps={{ className: classes.datePicker }}
                disabled={periodDisabled}
                showTodayButton={true}
                todayLabel="Hoje"
                cancelLabel="Cancelar"
                maxDate={selectedPeriod.toDate ? selectedPeriod.toDate : undefined}
                invalidLabel="Data inválida"
                invalidDateMessage="Data inválida"
                className={classes.picker}
                format="dd/MM/yyyy"
                margin="normal"
                label="De"
                value={selectedPeriod.fromDate}
                onChange={date => {
                  if (date === null || date.toString() === "Invalid Date") return;
                  date.setHours(0, 0, 0, 0);
                  setSelectedPeriod(period => ({ ...period, fromDate: date }));
                }}
              />
              <KeyboardDatePicker
                DialogProps={{ className: classes.datePicker }}
                showTodayButton={true}
                todayLabel="Hoje"
                cancelLabel="Cancelar"
                minDate={selectedPeriod.fromDate}
                disabled={!selectedPeriod.toDate || periodDisabled}
                invalidLabel="Data inválida"
                invalidDateMessage="Data inválida"
                className={classes.picker}
                format="dd/MM/yyyy"
                margin="normal"
                label="Até"
                value={selectedPeriod.toDate}
                onChange={date => {
                  if (date === null || date.toString() === "Invalid Date") return;
                  date.setHours(23, 59, 59, 0);
                  setSelectedPeriod(period => ({ ...period, toDate: date }));
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </List>
      </div>
      <div className={css.filter}>
        <Button
          fullWidth
          disabled={!isFilterActive}
          className={`${classes.button}`}
          onClick={clearFilters}
          variant="contained"
          color="primary"
        >
          <FilterListOutlined className={classes.iconFilter} />
          <span style={{ paddingRight: "20px" }}>Limpar filtros</span>
        </Button>
      </div>
    </div>
  );
}

interface Props {
  people: PersonBasic[];
  selectedOwner: PersonBasic | null;
  setSelectedOwner: (s: PersonBasic | null) => void;

  costCenters: { id: number; title: string }[];
  setSelectedCostCenter: (s: { id: number; title: string } | null) => void;
  selectedCostCenter: { id: number; title: string } | null;

  setSelectedExpenseType: (s: Account | null) => void;
  selectedExpenseType: Account | null;
  expenseTypes: Account[];
  chargeable: boolean | null;
  setChargeable: (b: boolean | null) => void;
  charged: boolean | null;
  setCharged: (b: boolean | null) => void;
  clearFilters: () => void;
  isFilterActive: boolean;
  setSelectedPeriod: (func: (period: Period) => Period) => void;
  selectedPeriod: { fromDate: Date | null; toDate: Date | null };
  displayMode: number;
  setDisplayMode: (mode: number) => void;
  setDrawer?: (state: boolean) => void;
}

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);
