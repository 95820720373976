import React, { useEffect, useState, Fragment, useCallback, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  LinearProgress,
  IconButton,
  Button,
  DialogActions,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  EditOutlined,
  LocationOnOutlined,
  NotesOutlined,
  PeopleOutlined,
  GavelOutlined,
  HowToRegOutlined,
  CloseOutlined,
  CreateOutlined,
  FolderOutlined,
  EventNoteOutlined,
  DateRangeOutlined,
  AccessTimeOutlined,
  CheckOutlined,
  ContactsOutlined,
} from "@material-ui/icons/";
import { ToggleButton } from "@material-ui/lab";
import apiCaller from "../../apiCaller";
import css from "./Assignment.module.css";
import makeStyles from "./Styles";
import AssignmentEdit from "../Edit/AssignmentEdit";
import { AssignmentType, UserBasic } from "../../../declarations";
import { Link, RouteComponentProps } from "react-router-dom";
import moment from "moment";
import "moment/locale/pt-br";
import { normalizeAssignment } from "../Assignments";
import { userContext } from "../../Root";

export default Assignment;

moment.locale("pt-br");

function Assignment(props: Props) {
  const { user } = useContext(userContext);
  let { assignment, updateAssignment } = props;
  const [expandNotes, setExpandNotes] = useState(false);
  const [, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [questionDialog, setQuestionDialog] = useState({ open: false, confirmed: false });
  const [linkedUsersDialog, setLinkedUsersDialog] = useState({ open: false, confirmed: false });
  const [linkedUsers, setLinkedUsers] = useState<(UserBasic & { contactId: number })[]>([]);

  const userLinked = linkedUsers.find(lu => user!.id === lu.id)

  const userPermissions =
  user!.permissionsAssignment === 0 && !(linkedUsers.some(lu => user!.id === lu.id))
  || user!.permissionsAssignment === 1 && false
  || user!.permissionsAssignment === 2 && (assignment && userLinked !== undefined ? userLinked!.permissionLevel : null)! < 1

  const permissions = canEdit ? userPermissions : true

  useEffect(() => {
    (async function () {
      const { error, data } = await apiCaller.get<any>(
        `/assignments/${props.openAssignmentId}/linked-users`
      );
      if (error) {
        setError(error);
      } else {
        setLinkedUsers(data.data);
      }
      setLoading(false);
    })();
  }, [props.openAssignmentId]);

  useEffect(() => {
    (async () => {
      const { error, data } = await apiCaller.get<any>("/assignments/" + props.openAssignmentId);
      if (error) {
        setError(error);
      } else {
        const { assignment: fetchedAssignment } = data;
        updateAssignment(props.openAssignmentId, () => normalizeAssignment(fetchedAssignment));
      }
      setCanDelete(data.canDelete);
      setCanEdit(data.canEdit);
      setLoading(false);
    })();
  }, [props.openAssignmentId, setError, updateAssignment]);


  async function setStatus(status: 0 | 1 | 2) {
    setLoading(true);
    const { error } = await apiCaller.patch(
      `/assignments/${props.openAssignmentId}/change-status`,
      {
        status,
      }
    );
    if (error) setError(error);
    else updateAssignment(props.openAssignmentId, (a) => ({ ...a, status }));
    setLoading(false);
    setQuestionDialog((s) => ({ ...s, open: true }));
  }

  const classes = makeStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  let dialogContent: JSX.Element | null = null;

  if (assignment) {
    let endDate, startDate;

    const dateFormated = (date: Date) => {
      const currentYear = new Date().getFullYear();
      const otherYears = date.getFullYear();

      return currentYear === otherYears
        ? moment(date).format("ddd[,] D [de] MMMM")
        : moment(date).format("ddd[,] D [de] MMMM [de] YYYY");
    };
    endDate = dateFormated(assignment.endDate);
    startDate = dateFormated(assignment.startDate);

    const today = moment().format("ddd[,] D [de] MMMM");

    startDate = today === startDate ? "Hoje" : startDate;
    endDate = today === endDate ? "Hoje" : endDate;

    const startTime = moment(assignment.startDate).format("HH:mm");
    const endTime = moment(assignment.endDate).format("HH:mm");

    let colorHex = parseInt(assignment.color).toString(16);
    for (let i = colorHex.length; i < 6; i++) {
      colorHex = "0" + colorHex;
    }
    colorHex = colorHex.slice(4, 6) + colorHex.slice(2, 4) + colorHex.slice(0, 2);

    const assignmentNotes = (
      <Fragment>
        {assignment.notes && assignment.notes.length > 300 && !expandNotes
          ? assignment.notes.slice(0, 300)
          : assignment.notes}
        {assignment.notes && assignment.notes.length > 300 ? (
          <Button onClick={() => setExpandNotes((s) => !s)} className={classes.button}>
            Ler {expandNotes ? "menos" : "mais"}...
          </Button>
        ) : null}
      </Fragment>
    );

    let assignmentMode;

    switch (assignment.mode) {
      case 0:
        assignmentMode = "Normal";
        break;
      case 1:
        assignmentMode = "Particular";
        break;
      case 2:
        assignmentMode = "Providência";
        break;
      case 3:
        assignmentMode = "Prazo";
        break;
      case 4:
        assignmentMode = "Atividade externa";
        break;
      case 5:
        assignmentMode = "Diligência";
        break;
    }

    dialogContent = (
      <Fragment>
        <DialogContent>
          <div className={css.main}>
            <div className={css.itemSubjectDate}>
              <div title="Cor" className={css.color} style={{ backgroundColor: `#${colorHex}` }} />
              <div title="Assunto" className={css.subject}>
                {assignment.subject}
              </div>
              <div title="Período" className={css.date}>
                {startDate === endDate ? (
                  <Fragment>
                    <div className={css.startDate}>
                      {!assignment.allDay
                        ? `${startDate} - ${startTime} às
                            ${endTime} `
                        : startDate}
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className={css.startDate}>
                      {!assignment.allDay ? `${startDate} às ${startTime}` : startDate}
                    </div>
                    <div className={css.startDate}>
                      {!assignment.allDay ? `${endDate} às ${endTime}` : endDate}
                    </div>
                  </Fragment>
                )}
              </div>

              <div className={css.recorrency}>
                {assignment.recorrency ? (
                  <Fragment>
                    <DateRangeOutlined className={classes.icon} />
                    <span title="Recorrência" className={css.recorrencyTitle}>
                      Recorrência
                    </span>
                  </Fragment>
                ) : undefined}
              </div>
            </div>
            <div title="Modo" className={css.item}>
              <EventNoteOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Modo</div>
                <div className={css.mode}>{assignmentMode}</div>
              </div>
            </div>
            <div title="Local" className={css.item}>
              <LocationOnOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Local</div>
                <div className={css.location}>{assignment.location}</div>
              </div>
            </div>
            <div title="Notas" className={css.item}>
              <NotesOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Notas</div>
                <div className={css.notes}>{assignmentNotes}</div>
              </div>
            </div>
            <Divider />
            <div title="Cliente" className={css.item}>
              <ContactsOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Cliente</div>
                <div className={css.clientName}>{assignment.clientName}</div>
              </div>
            </div>
            <div title="Pasta | Caso" className={css.item}>
              <FolderOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Pasta | Caso</div>
                <div className={css.processFolder}>
                  {assignment.folderNumber}{" "}
                  {assignment.folderTitle ? `- ${assignment.folderTitle}` : null}
                </div>
              </div>
            </div>
            <div title="Processo" className={css.item}>
              <GavelOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Processo</div>
                <div className={css.process}>
                  {((assignment.processNumberUnif && assignment.processNumberUnif.trim()) || "") ===
                  ""
                    ? assignment.processNumber
                    : assignment.processNumberUnif}
                </div>
              </div>
            </div>
            <Divider />
            <div title="Atribuído" className={css.item}>
              <HowToRegOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Atribuído à</div>
                <div className={css.assigned}>{assignment.assignedUserName}</div>
              </div>
            </div>
            <div title="Vinculados" className={css.item}>
              <PeopleOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Vinculado à</div>
                <div className={css.linkedUsers}>
                  {linkedUsers.length === 0 && loading ? (
                    "..."
                  ) : linkedUsers.length > 1 ? (
                    <Fragment>
                      <div>{`${linkedUsers.length} usuário(s)`}</div>
                      <div
                        className={css.linkedUsersLink}
                        key="0"
                        onClick={() => setLinkedUsersDialog((s) => ({ ...s, open: true }))}
                      >
                        Ver
                      </div>
                    </Fragment>
                  ) : (linkedUsers.map(lu => (`Somente ${lu.contactName} está vinculado(a)`)
                  ))}
                </div>
              </div>
            </div>
            <div title="Criado por" className={css.item}>
              <CreateOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Criado por</div>
                <div className={css.createBy}>{assignment.createdBy} </div>
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <span className={css.statusQuestion}>Qual o status deste prazo?</span>
          <div className={css.toggleButtonGroup}>
            <ToggleButton
              value=""
              onClick={() => setStatus(0)}
              disabled={assignment.status === 0 || userPermissions}
              className={classes.toggleButton}
              selected={assignment.status === 0}
              title="Pendente"
            >
              <AccessTimeOutlined fontSize="small" style={{ color: "#125ad3" }} />
              <span className={css.statusTitle}>Pendente</span>
            </ToggleButton>
            <ToggleButton
              value=""
              onClick={() => setStatus(1)}
              disabled={assignment.status === 1 || userPermissions}
              className={classes.toggleButton}
              title="Concluído"
              selected={assignment.status === 1}
            >
              <CheckOutlined fontSize="small" style={{ color: "#125ad3" }} />
              <span className={css.statusTitle}>Concluído</span>
            </ToggleButton>
            <ToggleButton
              value=""
              onClick={() => {
                setStatus(2);
              }}
              disabled={assignment.status === 2 || userPermissions}
              className={classes.toggleButton}
              title="Cancelado"
              selected={assignment.status === 2}
            >
              <CloseOutlined fontSize="small" style={{ color: "#125ad3" }} />
              <span className={css.statusTitle}>Cancelado</span>
            </ToggleButton>
          </div>
        </DialogActions>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Dialog
        className={classes.dialog}
        open={true}
        onClose={() => props.openAssignment(0)}
        maxWidth="md"
        fullWidth
        fullScreen={fullScreen}
      >
        <DialogTitle className={classes.dialogTitle}>
          <div className={css.header}>
            <IconButton
              className={classes.closeIcon}
              title="Fechar janela"
              onClick={() => props.openAssignment(0)}
            >
              <CloseOutlined />
            </IconButton>
            <IconButton
              disabled={permissions}
              className={classes.editIcon}
              title={"Editar prazo"}
              onClick={() => setOpenEdit(true)}
            >
              <EditOutlined />
            </IconButton>
          </div>
        </DialogTitle>
        {loading ? (
          <LinearProgress variant="query" className={classes.loading} />
        ) : (
          <div className={css.loading} />
        )}
        {dialogContent}
        <Dialog
          open={questionDialog.open}
          onClose={() => setQuestionDialog({ open: false, confirmed: false })}
        >
          <span className={css.dialogTitle}>Deseja adicionar uma nota ao prazo?</span>
          <DialogActions>
            <Button
              className={classes.dialogButton}
              onClick={() => {
                setQuestionDialog({ open: false, confirmed: false });
                props.openAssignment(0);
              }}
              color="primary"
              autoFocus
              size="small"
            >
              Mais tarde
            </Button>
            <Button
              className={classes.dialogButton}
              onClick={() => {
                setQuestionDialog({ open: false, confirmed: true });
                setOpenEdit(true);
              }}
              color="primary"
              autoFocus
              size="small"
            >
              Agora
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={linkedUsersDialog.open}
          onClose={() => setLinkedUsersDialog({ open: false, confirmed: false })}
        >
          <DialogTitle className={classes.dialogTitleLinked}>
            <div className={css.header}>
              <IconButton
                className={classes.closeIconLinked}
                title="Fechar janela"
                onClick={() => {
                  setLinkedUsersDialog({ open: false, confirmed: false });
                }}
              >
                <CloseOutlined />
              </IconButton>
              <span className={css.dialogTitleLinked}>Usuários vinculados</span>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className={css.dialogLinkedUsers}>
              {linkedUsers.map((lu) => {
                return (
                  <div key={lu.id} className={css.itemLinked}>
                    <div className={css.linkedUserList}>{lu.contactName}</div>
                    <Link to={"/contatos/" + lu.contactId} className={css.viewContact}>
                      ver
                    </Link>
                  </div>
                );
              })}
            </div>
          </DialogContent>
          <DialogActions />
        </Dialog>
      </Dialog>
      {assignment && openEdit ? (
        <AssignmentEdit
          linkedUsers={linkedUsers}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          updateAssignment={updateAssignment}
          assignment={assignment}
          focusNotes={questionDialog.confirmed}
        />
      ) : null}
    </Fragment>
  );
}

interface Props {
  assignment?: AssignmentType;
  updateAssignment: (id: number, updateFunction: (a: AssignmentType) => AssignmentType) => void;
  openAssignment: (state: number) => void;
  openAssignmentId: number;
  history: RouteComponentProps["history"];
}
