import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    button: {
      backgroundColor: "#125ad3",
      "&:hover": {
        backgroundColor: "#1c5793"
      }
    },

    checkBox: {
      color: "#125ad3",
      "&.PrivateSwitchBase-root-104": {
        padding: "4px"
      },
      "& .MuiSvgIcon-root": {
        width: "1.3rem",
        height: "1.3rem"
      }
    },
    textField: {
      color: "white",
      height: 48,
      margin: "0 20px",

      "& .MuiInputBase-input": {
        padding: "6px 7px"
      },

      "& label.Mui-focused": {
        color: "#125ad3"
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#125ad3"
      },
      "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
          borderColor: "#2f354a"
        },
        "&.Mui-focused fieldset": {
          borderColor: "#125ad3"
        }
      }
    }
  })
);
