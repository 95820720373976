import { createStyles, makeStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    dialog: {
      "& .MuiDialog-paperWidthMd": {
        minHeight: "90vh",
        "@media only screen and (max-width: 820px)": {
          width: "100%",
          height: "100%",
          margin: "0",
          maxWidth: "100%",
          maxHeight: "none",
          borderRadius: "0",
        },
      },
    },
    dialogTitle: {
      backgroundColor: "#fff",
      gridTemplateColumns: "1fr 1fr",
      padding: "0px 0px",
      boxShadow:
        "0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px 0px rgba(0, 0, 0, 0), 0px 1px 10px 0px rgba(0, 0, 0, 0.1)",
    },
    dialogContent: {
      padding: "0px",
    },
    saveButton: {
      gridColumn: "2",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "end",
      margin: "8px 8px",
      backgroundColor: "#125ad3",
      "&:hover": {
        backgroundColor: "#1c5793",
      },
    },
    closeIcon: {
      gridColumn: "1",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "start",
      padding: "4px",
      marginLeft: "6px",
    },

    searchIcon: {
      gridColumn: "1",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "start",
      padding: "4px",
      color: "#125ad3",
    },

    searchButton: {
      gridColumn: "2",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "end",
      margin: "8px 8px",
      backgroundColor: "#125ad3",
      "&:hover": {
        backgroundColor: "#1c5793",
      },

      fontSize: "0.675rem",
    },

    loading: {
      height: "5px",
      position: "relative",
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#125ad3",
      },
    },

    pickerStart: {
      width: "185px",
      marginBottom: "16px",
      marginTop: "0px",
      gridRow: "1",
      gridColumn: "1",
      "& .MuiInputBase-root": {
        fontSize: "0.9rem",
      },
      "& .MuiInput-underline": {
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: "2px solid #125ad3",
        },
        "&:after": {
          borderBottom: "2px solid #125ad3",
        },
        "&.Mui-error:after": {
          borderBottomColor: "#f44336",
        },
      },
    },
    select: {
      gridRow: "2",
      gridColumn: "1",
      minWidth: "160px",
      fontSize: "0.9rem",
      "&:hover:not(.Mui-disabled):before": {
        borderBottom: "2px solid #125ad3",
      },
      "&:after": {
        borderBottom: "2px solid #125ad3",
      },
    },
    addTimeIcon: {
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "start",
      padding: "4px",
      color: "#125ad3",
    },
    datePicker: {
      "& .MuiPickersToolbar-toolbar": {
        backgroundColor: "#125ad3",
      },
      "& .MuiPickersDay-daySelected:hover ": {
        backgroundColor: "#125ad3",
      },
      "& .MuiPickersDay-daySelected": {
        backgroundColor: "#125ad3",
        color: "#fff",
      },
      "& .MuiButton-textPrimary": {
        color: "#125ad3",
      },
      "& .MuiTypography-colorPrimary": {
        color: "#125ad3",
      },
      "& .MuiPickersClockPointer-pointer": {
        backgroundColor: "#125ad3",
      },
      "& .MuiPickersClock-pin": {
        backgroundColor: "#125ad3",
      },
      "& .MuiPickersClockPointer-thumb": {
        border: "14px solid #125ad3",
      },
    },

    iconFont: {
      alignSelf: "end",
      justifySelf: "center",
      color: "#125ad3",
      fontSize: "0.825rem",
      width: "1em",
      height: "1em",
      gridRow: "1",
      gridColumn: "1",
      paddingTop: "2px",
    },

    icon: {
      color: "#737373",
      fontSize: "20px",
      gridRow: "1",
      gridColumn: "1",
    },
    iconAlert: {
      color: "#e20137",
      fontSize: "1.5rem",
      padding: "8px 12px",
    },
    iconFolderSearch: {
      color: "#737373",
      fontSize: "1.3rem",
      gridRow: "1",
      gridColumn: "1",
      paddingTop: "2px",
    },
    folderSearch: {
      width: "100%",
      maxWidth: "100px",
    },

    totalTime: {
      width: "100%",
      maxWidth: "130px",
    },

    totalValue: {
      width: "100%",
      maxWidth: "130px",
    },
    textField: {
      color: "white",
      height: 48,
      "& .MuiInputBase-root": {
        fontSize: "0.9rem",
      },
      "&:hover:not(.Mui-disabled):before": {
        borderBottom: "2px solid #125ad3",
      },
      "&:after": {
        borderBottom: "2px solid #125ad3",
      },

      "& label.Mui-focused": {
        color: "#125ad3",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#125ad3",
      },
      "& .MuiInput-underline": {
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: "2px solid #125ad3",
        },
        "&:after": {
          borderBottom: "2px solid #125ad3",
        },
      },
    },
    textFieldMultiLine: {
      "& .MuiInputBase-root": {
        fontSize: "0.9rem",
      },
      "& label.Mui-focused": {
        color: "#125ad3",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#125ad3",
      },
      "& .MuiInput-underline": {
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: "2px solid #125ad3",
        },
        "&:after": {
          borderBottom: "2px solid #125ad3",
        },
      },
    },
    switchBase: {
      "& .MuiSwitch-colorPrimary.Mui-checked": {
        color: "#125ad3",
      },
      "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#125ad3",
      },
      "& .MuiSwitch-switchBase": {
        color: "#c6c6c6",
      },
    },
    dialogButton: {
      gridColumn: "2",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "end",
      color: "#125ad3",
    },
    dialogContentAlert: {
      padding: "8px 12px",
    },
  })
);
