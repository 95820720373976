import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      margin: "8px 0px",
      display: "grid",
      justifyContent: "start",
      gridTemplateColumns: "minmax(210px, 350px) 80px minmax(210px, 350px)",
      "@media only screen and (max-width: 580px)": {
        gridTemplateColumns: "minmax(200px, 350px)"
      },
      "& .MuiGrid-item": {
        padding: "8px 16px 8px 0px"
      }
    },
    list: {
      width: 350,
      height: 230,
      overflow: "auto"
    },
    loading: {
      alignSelf: "center",
      justifySelf: "center",
      color: "#125ad3"
    },
    button: {
      margin: "5px 0px"
    }
  })
);
