import React, { useState, Fragment, useEffect } from "react";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  IconButton,
  DialogContent,
  useMediaQuery,
  useTheme,
  Divider,
  Icon,
  Button,
  TextField,
  DialogActions,
} from "@material-ui/core";
import { ExpenseType, Account } from "../../../declarations";
import {
  CloseOutlined,
  FolderOutlined,
  ContactsOutlined,
  NotesOutlined,
  AttachMoneyOutlined,
  MoneyOutlined,
  EditOutlined,
} from "@material-ui/icons";
import { RouteComponentProps } from "react-router";
import makeStyles from "./Styles";
import css from "./Expense.module.css";
import moment from "moment";
import "moment/locale/pt-br";
import apiCaller from "../../apiCaller";
import { formatValue } from "../../../Utils/fieldFormat";
import { normalizeDateIn } from "../../../Utils/normalizeDateIn";
import ExpenseEdit from "../Edit/ExpenseEdit";

export default Expense;

moment.locale("pt-br");

function Expense({
  expense,
  expenseId,
  openExpense,
  updateExpense,
  deleteExpense,
  history,
  costCenters,
  expenseTypes,
}: {
  costCenters?: { title: string; id: number }[];
  expenseTypes?: Account[];
  history: RouteComponentProps["history"];
  expenseId: number;
  expense?: ExpenseType;
  openExpense: (state: number) => void;
  updateExpense: (id: number, updateFunction: (a: ExpenseType) => ExpenseType) => void;
  deleteExpense: (id: number) => void;
}) {
  const [loading, setLoading] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [attachmentUrl, setAttachmentUrl] = useState<null | string>(null);
  const [expandDescription, setExpandDescription] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [questionDialogEdit, setQuestionDialogEdit] = useState({ open: false, confirmed: false });
  const [attachedDoc, setAttachedDoc] = useState<string | null>(null);

  const [questionDialog, setQuestionDialog] = useState({
    open: false,
    loading: false,
    newStatus: -1,
    message: "",
  });

  useEffect(
    function () {
      (async function () {
        setLoading(true);
        const { data } = await apiCaller.get<any>(`/expenses/${expenseId}`);
        if (data) {
          updateExpense(data.expense.id, () => normalizeDateIn<ExpenseType>(data.expense));
        }
        setCanDelete(data.canDelete);
        setCanEdit(data.canEdit);

        const { data: dataAttachment } = await apiCaller.get<any>(
          `/expenses/${expenseId}/attachment`,
          {
            responseType: "blob",
          }
        );
        if (dataAttachment) setAttachmentUrl(URL.createObjectURL(dataAttachment));
        if (data.expense.linkedDoc !== null && data.expense.attachedDocExtension !== null) {
          setAttachedDoc(data.expense.linkedDoc + "." + data.expense.attachedDocExtension);
        } else setAttachedDoc(null);
        setLoading(false);
      })();
    },
    [expenseId, updateExpense, openEdit]
  );

  const classes = makeStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  let dialogContent: string | JSX.Element | null = null;

  async function submitDelete(): Promise<void> {
    const { error } = await apiCaller.delete(`/expenses/${expenseId}`);
    if (error === undefined) {
      deleteExpense(expenseId);
      history.push("/despesas");
    }
  }

  if (expense) {
    const newDate = expense.date;

    const currentYear = moment().format("YYYY");

    const movementDayMonth = moment(newDate).format("D MMM");
    const year = moment(newDate).format("YYYY");

    const movementYear =
      currentYear !== year ? (
        <div className={css.itemDateYear}>
          <div className={css.itemYear}>{year}</div>
        </div>
      ) : null;

    const expenseDiscrimination = (
      <Fragment>
        {expense.history && expense.history.length > 300 && !expandDescription
          ? expense.history.slice(0, 300)
          : expense.history}
        {expense.history && expense.history.length > 300 ? (
          <Button onClick={() => setExpandDescription((s) => !s)} className={classes.button}>
            Ler {expandDescription ? "menos" : "mais"}...
          </Button>
        ) : null}
      </Fragment>
    );
    dialogContent = (
      <Fragment>
        <DialogContent className={classes.dialogContent}>
          <div className={css.panelSup}>
            <div className={css.date}>
              <div className={css.itemDayMonth}>{movementDayMonth}</div>
              {movementYear}
            </div>
            <div className={css.infoProcess}>
              <div title="Cliente" className={css.client}>
                <ContactsOutlined className={classes.icon} />
                <div className={css.clientName}>{expense.clientName}</div>
              </div>
              <div title="Pasta/Caso" className={css.folder}>
                <FolderOutlined className={classes.icon} />
                <div className={css.folderNumber}>
                  {expense.process.folderNumber}
                  {expense.process.title ? `- ${expense.process.title}` : null}
                </div>
              </div>
            </div>
          </div>
          <div className={css.panelInf}>
            <div title="Tipo de despesa" className={css.item}>
              <Icon className={`fas fas fa-receipt ${classes.icon}`} />
              <div className={css.content}>
                <div className={css.label}>Tipo de despesa</div>
                <div className={css.textContent}>
                  {expense.expenseAccount.code} - {expense.expenseAccount.title}
                </div>
              </div>
            </div>
            <Divider />
            <div title="Operação" className={css.item}>
              <Icon className={`fas fa-exchange-alt ${classes.icon}`} />
              <div className={css.content}>
                <div className={css.label}>Operação</div>
                <div className={css.textContent}>
                  {expense.operation === "D" ? "Débito" : "Cŕedito"}
                </div>
              </div>
            </div>
            <Divider />
            <div title="Discriminação" className={css.item}>
              <NotesOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Discriminação</div>
                <div className={css.textContent}>{expenseDiscrimination}</div>
              </div>
            </div>
            <Divider />
            <div title="Documento anexo" className={css.item}>
              <Icon className={`fas fa-file-alt ${classes.icon}`} />
              <div className={css.content}>
                <div className={css.label}>Documento anexo</div>
                <div className={css.textContent}>
                  {(() => {
                    if (attachedDoc && attachmentUrl)
                      return (
                        <>
                          <div>{attachedDoc}</div>
                          <a target="_blank" rel="noopener noreferrer" href={attachmentUrl}>
                            Clique para abrir
                          </a>
                        </>
                      );

                    if (loading) return "Carregando";
                    return "Não encontrado";
                  })()}
                </div>
              </div>
            </div>
            <Divider />
            <div title="Centro de custo" className={css.item}>
              <Icon className={`fas fa-map-marker ${classes.icon}`} />
              <div className={css.content}>
                <div className={css.label}>Centro de custo</div>
                <div className={css.textContent}>
                  {expense.costCenter.title || "Não selecionado"}
                </div>
              </div>
            </div>
            <Divider />
            <div title="Valor" className={css.item}>
              <AttachMoneyOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Valor</div>
                <div className={css.textContent}>
                  {expense.currency.name + formatValue(expense.finacialValue)}
                </div>
              </div>
            </div>
            <Divider />
            <div title="Faturável" className={css.item}>
              <Icon className={`fas fa-hand-holding-usd ${classes.iconFont}`} />
              <div className={css.content}>
                <div className={css.label}>Faturável</div>
                <div className={css.textContent}>{expense.chargeable === true ? "Sim" : "Não"}</div>
              </div>
            </div>
            <Divider />
            <div title="Faturado" className={css.item}>
              <Icon className={`fas fa-file-invoice-dollar ${classes.iconFont}`} />
              <div className={css.content}>
                <div className={css.label}>Faturado</div>
                <div className={css.textContent}>{expense.status === "F" ? "Sim" : "Não"}</div>
              </div>
            </div>
            <Divider />
            <div title="ID" className={css.item}>
              <MoneyOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>ID</div>
                <div className={css.textContent}>{expense.group}</div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Dialog
        className={classes.dialog}
        open={true}
        onClose={() => openExpense(0)}
        maxWidth="md"
        fullWidth
        fullScreen={fullScreen}
      >
        <DialogTitle className={classes.dialogTitle}>
          <div className={css.header}>
            <IconButton
              className={classes.closeIcon}
              title="Fechar janela"
              onClick={() => openExpense(0)}
            >
              <CloseOutlined />
            </IconButton>
            <IconButton
              disabled={!canEdit}
              className={classes.editIcon}
              title={"Editar despesa"}
              onClick={() => setOpenEdit(true)}
            >
              <EditOutlined />
            </IconButton>
            <IconButton
              disabled={!canDelete}
              className={classes.deleteIcon}
              title="Excluir"
              onClick={() => {
                setQuestionDialog((s) => ({ ...s, open: true }));
              }}
            >
              <Icon className={`far fa-trash-alt ${classes.iconDelete}`} />
            </IconButton>
          </div>
        </DialogTitle>
        {loading ? (
          <LinearProgress variant="query" className={classes.loading} />
        ) : (
          <>
            <div className={css.loading} />
            {dialogContent}
          </>
        )}
        <Dialog
          className={classes.dialog}
          open={questionDialog.open}
          onClose={() => setQuestionDialog((s) => ({ ...s, open: false }))}
        >
          {questionDialog.loading ? <LinearProgress className={classes.loading} /> : null}
          <Icon className={`far fa-trash-alt ${classes.iconAlertDelete}`} />
          <DialogContent>
            <div className={css.dialogTitle}>
              Para confirmar a exclusão digite <span style={{ fontWeight: 600 }}>EXCLUIR</span>
            </div>
            <div className={css.notes}>
              <TextField
                fullWidth
                inputProps={{ maxLength: 7 }}
                onChange={(e) => {
                  e.persist();
                  setQuestionDialog((s) => ({ ...s, message: e.target.value }));
                }}
                autoFocus
                placeholder="Digite aqui"
                className={classes.textField}
                value={questionDialog.message}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={
                questionDialog.message === "EXCLUIR" || questionDialog.message === "excluir"
                  ? false
                  : true
              }
              className={classes.dialogButton}
              onClick={submitDelete}
              color="primary"
              autoFocus
              size="small"
            >
              Excluir
            </Button>
            <Button
              className={classes.dialogButton}
              onClick={() => {
                setQuestionDialog((s) => ({ ...s, open: false }));
              }}
              color="primary"
              autoFocus
              size="small"
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
      {expense && openEdit ? (
        <ExpenseEdit
          attachedDoc={attachedDoc}
          costCenters={costCenters}
          expenseTypes={expenseTypes}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          updateExpense={updateExpense}
          expense={expense}
          focusNotes={questionDialogEdit.confirmed}
        />
      ) : null}
    </Fragment>
  );
}
