import React from "react";
import css from "./ContactsSidebar.module.css";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Switch,
  Button
} from "@material-ui/core";
import { ContactsOutlined, ReorderOutlined, FilterListOutlined } from "@material-ui/icons";
import makeStyles from "./Styles";

export default ContactsSidebar;

function ContactsSidebar({
  displayMode,
  setDisplayMode,
  setDrawer,
  withContactInfo,
  setWithContactInfo,
  clearFilters,
  isFilterActive,
  includeInactives,
  setIncludeInactives
}: Props) {
  const closeDrawer = () => (setDrawer ? setDrawer(false) : null);
  const classes = makeStyles();
  return (
    <div className={css.sidebarContainer}>
      <div className={css.sidebarSup}>
        <div className={css.sidebarIconTitle}>
          <ContactsOutlined className={classes.iconTitle} />
          <p className={css.sidebarSupTitle}>Contatos</p>
        </div>
      </div>

      <div className={css.sidebarInf}>
        <Divider />
        <div className={css.filterGroup}>
          <span className={css.filterTitle}>Sem inf. de contato</span>
          <Switch
            checked={!withContactInfo}
            onClick={() => {
              setWithContactInfo(!withContactInfo);
            }}
            value={withContactInfo}
            color="primary"
            className={classes.switchBase}
          />
        </div>
        <Divider />
        <div className={css.filterGroup}>
          <span className={css.filterTitle}>Mostrar inativos</span>
          <Switch
            checked={includeInactives}
            onClick={() => setIncludeInactives(!includeInactives)}
            value={includeInactives}
            color="primary"
            className={classes.switchBase}
          />
        </div>
        <Divider />
        <List className={classes.sidebarInfList}>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 0}
            onClick={() => {
              setDisplayMode(0);
              closeDrawer();
            }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <ReorderOutlined className={classes.itemIcon} />
            </ListItemIcon>
            <ListItemText className={classes.subHeaderText} primary="Todos" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 1}
            onClick={() => {
              setDisplayMode(1);
              closeDrawer();
            }}
          >
            <ListItemText className={classes.subHeaderText} primary="Clientes" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 2}
            onClick={() => {
              setDisplayMode(2);
              closeDrawer();
            }}
          >
            <ListItemText className={classes.subHeaderText} primary="Possíveis clientes" />
          </ListItem>
          <Divider />
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 3}
            onClick={() => {
              setDisplayMode(3);
              closeDrawer();
            }}
          >
            <ListItemText className={classes.subHeaderText} primary="Advogados" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 4}
            onClick={() => {
              setDisplayMode(4);
              closeDrawer();
            }}
          >
            <ListItemText className={classes.subHeaderText} primary="Estagiários" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 5}
            onClick={() => {
              setDisplayMode(5);
              closeDrawer();
            }}
          >
            <ListItemText className={classes.subHeaderText} primary="Administrativos" />
          </ListItem>
          <Divider />
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 6}
            onClick={() => {
              setDisplayMode(6);
              closeDrawer();
            }}
          >
            <ListItemText className={classes.subHeaderText} primary="Partes" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 7}
            onClick={() => {
              setDisplayMode(7);
              closeDrawer();
            }}
          >
            <ListItemText className={classes.subHeaderText} primary="Fornecedores" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 8}
            onClick={() => {
              setDisplayMode(8);
              closeDrawer();
            }}
          >
            <ListItemText className={classes.subHeaderText} primary="Correspondentes" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 9}
            onClick={() => {
              setDisplayMode(9);
              closeDrawer();
            }}
          >
            <ListItemText className={classes.subHeaderText} primary="Captadores" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 10}
            onClick={() => {
              setDisplayMode(10);
              closeDrawer();
            }}
          >
            <ListItemText className={classes.subHeaderText} primary="Outros" />
          </ListItem>
          <Divider />
        </List>
      </div>
      <div className={css.filter}>
        <Button
          fullWidth
          disabled={!isFilterActive}
          className={`${classes.button}`}
          onClick={clearFilters}
          variant="contained"
          color="primary"
        >
          <FilterListOutlined className={classes.iconFilter} />
          <span style={{ paddingRight: "20px" }}>Limpar filtros</span>
        </Button>
      </div>
    </div>
  );
}

interface Props {
  includeInactives: boolean;
  setIncludeInactives: (b: boolean) => void;
  clearFilters?: () => void;
  isFilterActive?: boolean;
  displayMode: number;
  setDisplayMode: (mode: number) => void;
  setDrawer?: (state: boolean) => void;
  withContactInfo: boolean;
  setWithContactInfo: (b: boolean) => void;
}
