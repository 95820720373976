import React from "react";
import css from "./ProcMovementSidebar.module.css";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
  ListSubheader,
  Icon,
  Switch,
  RadioGroup,
  Radio,
  FormControlLabel
} from "@material-ui/core";

import { FilterListOutlined } from "@material-ui/icons";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/esm/locale";
import { Period } from "../../../declarations";
import AutoComplete from "../../../Components/AutoComplete/AutoComplete";
import makeStyles from "./Styles";

export default ProcMovementsSidebar;

function ProcMovementsSidebar({
  clearFilters,
  isFilterActive,
  setSelectedPeriod,
  selectedPeriod,
  displayMode,
  setDisplayMode,
  setDrawer,
  fromMyTeams,
  setFromMyTeams,
  revised,
  setRevisedFilter,
  selectedType,
  setSelectedType,
  procMovementsTypes
}: Props) {
  const closeDrawer = () => (setDrawer ? setDrawer(false) : null);
  const classes = makeStyles();

  const periodDisabled = false;

  const defaultOption = { key: 0, label: "Pesquisar...", value: null };
  const options = [
    defaultOption,
    ...procMovementsTypes.map(p => ({
      label: p.name,
      key: p.id,
      value: p
    }))
  ];

  return (
    <div className={css.sidebarContainer}>
      <div className={css.sidebarSup}>
        <div className={css.sidebarIconTitle}>
          <Icon className={`far fa-newspaper ${classes.iconTitle}`} />
          <p className={css.sidebarSupTitle}>Andamentos</p>
        </div>
        <div className={css.movementType}>
          <AutoComplete<{ name: string; id: number }>
            label="Tipo de andamento"
            options={options}
            selected={
              selectedType === null
                ? defaultOption
                : {
                    value: selectedType,
                    key: selectedType.id,
                    label: selectedType.name
                  }
            }
            setSelected={option => setSelectedType(option.value)}
          />
        </div>
      </div>
      <div className={css.sidebarInf}>
        <div className={css.filterGroup}>
          <span className={css.filterTitle}>Das minhas equipes</span>
          <Switch
            checked={fromMyTeams}
            onClick={() => setFromMyTeams(!fromMyTeams)}
            value=""
            color="primary"
            className={classes.switchBase}
          />
        </div>
        <Divider />
        <div className={css.filterGroup}>
          <span className={css.filterTitle}>Revisados</span>
          <RadioGroup
            value={revised === null ? "Ambos" : revised ? "Sim" : "Não"}
            onChange={(_, value) =>
              setRevisedFilter(value === "Ambos" ? null : value === "Sim" ? true : false)
            }
            row
          >
            <FormControlLabel
              className={classes.radioLabel}
              value="Sim"
              control={<Radio className={classes.radio} color="primary" />}
              label="Sim"
              labelPlacement="top"
            />
            <FormControlLabel
              className={classes.radioLabel}
              value="Não"
              control={<Radio className={classes.radio} color="primary" />}
              label="Não"
              labelPlacement="top"
            />
            <FormControlLabel
              className={classes.radioLabel}
              value="Ambos"
              control={<Radio className={classes.radio} color="primary" />}
              label="Ambos"
              labelPlacement="top"
            />
          </RadioGroup>
        </div>
        <Divider />
        <List className={classes.sidebarInfList}>
          <ListSubheader className={classes.subHeader} disableSticky={true}>
            <ListItemIcon className={classes.subHeaderIcon}>
              <Icon className={`far fa-edit ${classes.iconFont}`} />
            </ListItemIcon>
            <ListItemText className={classes.subHeaderText} primary="Origem" />
          </ListSubheader>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 0}
            onClick={() => {
              setDisplayMode(0);
              closeDrawer();
            }}
          >
            <ListItemText className={classes.subHeaderText} primary="Todos" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 1}
            onClick={() => {
              setDisplayMode(1);
              closeDrawer();
            }}
          >
            <ListItemText className={classes.subHeaderText} primary="Site do tribunal" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 2}
            onClick={() => {
              setDisplayMode(2);
              closeDrawer();
            }}
          >
            <ListItemText className={classes.subHeaderText} primary="Diário Oficial" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 3}
            onClick={() => {
              setDisplayMode(3);
              closeDrawer();
            }}
          >
            <ListItemText className={classes.subHeaderText} primary="Usuário" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 4}
            onClick={() => {
              setDisplayMode(4);
              closeDrawer();
            }}
          >
            <ListItemText className={classes.subHeaderText} primary="Importação" />
          </ListItem>
          <Divider />
          <ListSubheader disableSticky={true}>Período</ListSubheader>
          <div className={css.pickerPeriod}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <KeyboardDatePicker
                DialogProps={{ className: classes.datePicker }}
                disabled={periodDisabled}
                showTodayButton={true}
                todayLabel="Hoje"
                cancelLabel="Cancelar"
                maxDate={selectedPeriod.toDate ? selectedPeriod.toDate : undefined}
                invalidLabel="Data inválida"
                invalidDateMessage="Data inválida"
                className={classes.picker}
                format="dd/MM/yyyy"
                margin="normal"
                label="De"
                value={selectedPeriod.fromDate}
                onChange={date => {
                  if (date === null || date.toString() === "Invalid Date") return;
                  date.setHours(0, 0, 0, 0);
                  setSelectedPeriod(period => ({ ...period, fromDate: date }));
                }}
              />
              <KeyboardDatePicker
                DialogProps={{ className: classes.datePicker }}
                showTodayButton={true}
                todayLabel="Hoje"
                cancelLabel="Cancelar"
                minDate={selectedPeriod.fromDate}
                disabled={!selectedPeriod.toDate || periodDisabled}
                invalidLabel="Data inválida"
                invalidDateMessage="Data inválida"
                className={classes.picker}
                format="dd/MM/yyyy"
                margin="normal"
                label="Até"
                value={selectedPeriod.toDate}
                onChange={date => {
                  if (date === null || date.toString() === "Invalid Date") return;
                  date.setHours(23, 59, 59, 0);
                  setSelectedPeriod(period => ({ ...period, toDate: date }));
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </List>
      </div>
      <div className={css.filter}>
        <Button
          fullWidth
          disabled={!isFilterActive}
          className={`${classes.button}`}
          onClick={clearFilters}
          variant="contained"
          color="primary"
        >
          <FilterListOutlined className={classes.iconFilter} />
          <span style={{ paddingRight: "20px" }}>Limpar filtros</span>
        </Button>
      </div>
    </div>
  );
}

interface Props {
  procMovementsTypes: ({ id: number; name: string })[];
  selectedType: { id: number; name: string } | null;
  setSelectedType: (p: { id: number; name: string } | null) => void;
  revised: boolean | null;
  setRevisedFilter: (value: boolean | null) => void;
  setFromMyTeams: (value: boolean) => void;
  fromMyTeams: boolean;
  clearFilters: () => void;
  isFilterActive: boolean;
  setSelectedPeriod: (func: (period: Period) => Period) => void;
  selectedPeriod: { fromDate: Date | null; toDate: Date | null };
  displayMode: number;
  setDisplayMode: (mode: number) => void;
  setDrawer?: (state: boolean) => void;
}

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);
