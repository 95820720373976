import React, { useState, useEffect, useCallback, createRef } from "react";
import { ContactType, SetOrderFunc } from "../../declarations";
import ContactsListItem from "./ListItem/ContactsListItem";
import ContactsSidebar from "./Sidebar/ContactsSidebar";
import MainList from "../../Components/MainList/MainList";
import BackDrawer from "../../Components/BackDrawer/BackDrawer";
import { List, ListItem, Divider, ListItemText } from "@material-ui/core";
import { ReorderOutlined } from "@material-ui/icons";
import { Action, State, OrderFieldNames } from "./ContactsStore";
import apiCaller from "../apiCaller";
import css from "../Contacts/Contacts.module.css";
import Contact from "./Contact/Contact";
import SearchList from "../../Components/SearchList/SearchList";
import { RouteComponentProps } from "react-router";
import makeStyles from "./Styles";
import { compareWithoutDiacritics } from "../../Utils/compareWithoutDiacritics";

export default Contacts;

function Contacts({
  dispatch,
  fetched,
  query,
  displayMode,
  order,
  orderFields,
  refreshing,
  withContactInfo,
  startsWith,
  includeInactives,
  ...props
}: { dispatch: (a: Action) => void } & State & RouteComponentProps) {
  const currentMode = props.modes[displayMode];
  const currentQuery = props.queryPaginations[query];
  const idParam = (props.match.params as { id?: string }).id;
  const openContactId = idParam !== undefined && !isNaN(+idParam) ? +idParam : undefined;

  useEffect(
    function() {
      dispatch({ type: "updateQuery" });
    },
    [
      dispatch,
      currentMode.search,
      refreshing,
      order,
      withContactInfo,
      startsWith,
      displayMode,
      includeInactives
    ]
  );

  let currentQueryLastPage = -1;
  if (currentQuery) currentQueryLastPage = currentQuery.lastPage;

  const fetchNextPage = useCallback(
    function() {
      dispatch({ type: "loading", payload: { query, loading: true } });
      (async function() {
        const { data, error } = await apiCaller.get<any>(
          query + `&pageLength=20&pageNumber=${currentQueryLastPage}`
        );
        if (!error) dispatch({ type: "fetchNextPage", payload: { data: data.data, query } });
        else dispatch({ type: "error", payload: error });

        dispatch({ type: "loading", payload: { query, loading: false } });
      })();
    },
    [currentQueryLastPage, dispatch, query]
  );

  const refresh = () => {
    dispatch({ type: "refresh" });
  };

  useEffect(() => {
    if (currentQuery && !currentQuery.endReached && currentQuery.owns.size === 0) fetchNextPage();
  }, [currentQuery, fetchNextPage, refreshing]);

  const search = (searchString: string) => {
    dispatch({ type: "search", payload: searchString });
  };

  const isFilterActive =
    displayMode !== 0 ||
    !!startsWith ||
    currentMode.search.length > 0 ||
    withContactInfo ||
    !includeInactives;

  const clearFilters = function() {
    dispatch({ type: "clearFilters" });
  };

  const [showDrawer, setDrawer] = useState<boolean>(false);
  const toggleDrawer = function() {
    setDrawer(s => !s);
  };

  const setDisplayMode = (i: number) => dispatch({ type: "displayMode", payload: i });
  const setWithContactInfo = (i: boolean) => dispatch({ type: "withContactInfo", payload: i });
  const setOrder = (func: SetOrderFunc<OrderFieldNames>) =>
    dispatch({ type: "order", payload: func(order) });
  const openContact = (id?: number) =>
    props.history.push("/contatos/" + (id === undefined ? "" : id));
  const setStartsWith = (letter: string | null) =>
    dispatch({ type: "startsWith", payload: letter });
  const setIncludeInactives = (i: boolean) => dispatch({ type: "includeInactives", payload: i });
  (window as any).setIncludeInactives = setIncludeInactives;
  const sidebar = (
    <ContactsSidebar
      includeInactives={includeInactives}
      setIncludeInactives={setIncludeInactives}
      clearFilters={clearFilters}
      isFilterActive={isFilterActive}
      displayMode={displayMode}
      setDisplayMode={setDisplayMode}
      withContactInfo={withContactInfo}
      setWithContactInfo={setWithContactInfo}
      setDrawer={setDrawer}
    />
  );
  const drawer = { showDrawer, setDrawer, toggleDrawer, drawerContent: sidebar };

  const searchFields = [
    "name",
    "role",
    "phoneNumber",
    "cellPhoneNumber",
    "email",
    "CPF",
    "address"
  ];

  const displayingContacts = Object.values(fetched)
    .filter(c => {
      let show =
        currentMode.filterFunction(c) &&
        currentQuery.owns.has(c.id) &&
        (!startsWith || c.name.indexOf(startsWith) === 0) &&
        (!withContactInfo ||
          c.email.length > 0 ||
          c.cellPhoneNumber.length > 0 ||
          c.phoneNumber.length > 0);

      if (show && currentMode.search.length > 0) {
        show = false;
        for (let searchField of searchFields) {
          let value = c[searchField as keyof ContactType] as null | string | number;
          show = show || compareWithoutDiacritics(currentMode.search, value);
        }
      }
      return show;
    })
    .sort((a, b) => {
      if (order.field.name === "name")
        return order.mode === "ASC" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
      return 0;
    });

  const updateContact = useCallback(
    (id: number, updateFunction: (a: ContactType) => ContactType) => {
      const contact = updateFunction(fetched[id]);
      dispatch({ type: "updateContact", payload: contact });
    },
    [dispatch, fetched]
  );

  const loading = !currentQuery || currentQuery.loading;

  const propsMainList = {
    orderFields,
    endReached: currentQuery && currentQuery.endReached,
    loading,
    refresh,
    currentSearch: currentMode.search,
    setOrder,
    order,
    isFilterActive,
    clearFilters,
    contentArray: displayingContacts,
    ListComponent: ContactsListItem,
    listComponentProps: { openContact },
    fetchNextPage: () => {
      if (!currentQuery.loading && !currentQuery.endReached) fetchNextPage();
    }
  };

  const classes = makeStyles();

  const filterAlphabetic = [
    <>
      <ListItem
        style={
          startsWith === null
            ? { backgroundColor: "rgba(18, 58, 211, 0.84)", color: "rgb(255, 255, 255)" }
            : { color: "rgb(86, 86, 86" }
        }
        className={classes.listItemButton}
        onClick={() => setStartsWith(null)}
        button
      >
        <ReorderOutlined className={classes.icon}></ReorderOutlined>
      </ListItem>
      <Divider />
    </>,
    ..."ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").map((letter, i) => {
      return (
        <React.Fragment>
          <ListItem
            key={letter}
            style={
              letter === startsWith
                ? { backgroundColor: "rgba(18, 58, 211, 0.84)", color: "rgb(255, 255, 255)" }
                : { color: "rgb(86, 86, 86" }
            }
            onClick={() => setStartsWith(letter)}
            className={classes.listItemButton}
            button
          >
            <ListItemText className={classes.itemTextFilter} primary={letter} />
          </ListItem>
          <Divider key={letter + "d"} />
        </React.Fragment>
      );
    })
  ];

  const [scrollStart, setScrollStart] = useState(0);
  const scrollDiv = createRef<HTMLDivElement>();

  return (
    <>
      <BackDrawer {...drawer}></BackDrawer>
      {openContactId ? (
        <Contact
          openContactId={openContactId}
          updateContact={updateContact}
          contact={fetched[openContactId]}
          setOpenContact={openContact}
        />
      ) : null}
      <div className={css.main}>
        <div className={css.contactToolbar} />
        <div className={css.contactMain}>
          <div className={css.contactSidebar}>{sidebar}</div>
          <div className={css.contactList}>
            <SearchList search={search} {...drawer} />
            <MainList<ContactType, { openContact: (id: number) => void }, OrderFieldNames>
              {...propsMainList}
            ></MainList>
            <div
              ref={scrollDiv}
              onWheelCapture={e => {
                scrollDiv.current!.scrollBy(0, Math.sign(e.deltaY) * 45);
              }}
              onTouchStart={e => setScrollStart(e.currentTarget.scrollTop + e.touches[0].pageY)}
              onTouchMove={e => {
                scrollDiv.current!.scrollTo(0, scrollStart - e.touches[0].pageY);
              }}
              className={css.alphabeticFilter}
            >
              <List className={classes.alphabeticList}>{filterAlphabetic}</List>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
