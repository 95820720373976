import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    Item: {
      borderColor: "rgba(0, 0, 0, 0.12)",
      borderStyle: "solid",
      borderBottomWidth: "1px",
      display: "grid",
      paddingLeft: "0px",
      paddingRight: "8px",
      height: "100px",
      gridTemplateColumns: "auto 1fr"
    },

    icon: {
      color: "#125ad3",
      width: "0.7em",
      height: "0.7em"
    },
    iconButton: {
      padding: "4px",
      "& .MuiIconButton-label": {
        display: "grid"
      }
    },
    iconOrigin: {
      alignSelf: "center",
      justifySelf: "center",
      color: "#747474",
      width: "0.85em",
      height: "0.85em"
    },
    popover: {
      "& .MuiPaper-root": {
        padding: "8px"
      }
    }
  })
);
