import React, { useState, Fragment, useEffect } from "react";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  IconButton,
  DialogContent,
  useMediaQuery,
  useTheme,
  Divider,
  Icon,
  Button,
  TextField,
  DialogActions
} from "@material-ui/core";
import { TimesheetType } from "../../../declarations";
import {
  CloseOutlined,
  FolderOutlined,
  ContactsOutlined,
  NotesOutlined,
  TimerOutlined,
  MoneyOutlined,
  EditOutlined
} from "@material-ui/icons";
import css from "./Timesheet.module.css";
import moment from "moment";
import "moment/locale/pt-br";
import apiCaller from "../../apiCaller";
import makeStyles from "./Styles";
import { RouteComponentProps } from "react-router";
import TimesheetEdit from "../Edit/TimesheetEdit";

export default Timesheet;

moment.locale("pt-br");

function Timesheet({
  timesheet,
  timesheetId,
  openTimesheet,
  updateTimesheet,
  deleteTimesheet,
  history
}: {
  timesheetId: number;
  timesheet?: TimesheetType;
  openTimesheet: (state: number) => void;
  updateTimesheet: (id: number, updateFunction: (a: TimesheetType) => TimesheetType) => void;
  deleteTimesheet: (id: number) => void;
  history: RouteComponentProps["history"];
}) {
  const [loading, setLoading] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [questionDialogEdit, setQuestionDialogEdit] = useState({ open: false, confirmed: false });
  /* const [expandNotes, setExpandNotes] = useState(false);
  const [expandDescription, setExpandDescription] = useState(false);
 */

  const [expandDescription, setExpandDescription] = useState(false);
  useEffect(
    function() {
      (async function() {
        setLoading(true);
        const { data } = await apiCaller.get<any>(`/timesheets/${timesheetId}`);
        if (data) {
          // data.timesheet.date = new Date(data.timesheet.date);
          // const normalized = await normalizeTimesheet(data.timesheet);
          updateTimesheet(data.timesheet.id, () => data.timesheet);
        }

        setCanDelete(data.canDelete);
        setCanEdit(data.canEdit);
        setLoading(false);
      })();
    },
    [timesheetId, updateTimesheet, openEdit]
  );

  async function submitDelete(): Promise<void> {
    const { error } = await apiCaller.delete(`/timesheets/${timesheetId}`);
    if (error === undefined) {
      history.push("/honorarios");
      deleteTimesheet(timesheetId);
    }
  }

  const [questionDialog, setQuestionDialog] = useState({
    open: false,
    loading: false,
    newStatus: -1,
    message: ""
  });

  const classes = makeStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  let dialogContent: JSX.Element | null = null;

  if (timesheet) {
    const newDate = timesheet.date;

    const currentYear = moment().format("YYYY");

    const movementDayMonth = moment(newDate).format("D MMM");
    const year = moment(newDate).format("YYYY");

    const movementYear =
      currentYear !== year ? (
        <div className={css.itemDateYear}>
          <div className={css.itemYear}>{year}</div>
        </div>
      ) : null;

    const revisionDate =
      timesheet.revisionDate === null
        ? "Não revisado"
        : moment(timesheet.revisionDate).format("D [de] MMMM [de] YYYY");

    const timeFormat = (time: string) => {
      return time.replace(/(\d{2})(\d{2})/, function(_: any, arg1: string, arg2: string): string {
        return arg1 + ":" + arg2;
      });
    };

    const timesheetDiscrimination = (
      <Fragment>
        {timesheet.discrimination && timesheet.discrimination.length > 300 && !expandDescription
          ? timesheet.discrimination.slice(0, 300)
          : timesheet.discrimination}
        {timesheet.discrimination && timesheet.discrimination.length > 300 ? (
          <Button onClick={() => setExpandDescription(s => !s)} className={classes.button}>
            Ler {expandDescription ? "menos" : "mais"}...
          </Button>
        ) : null}
      </Fragment>
    );

    dialogContent = (
      <Fragment>
        <DialogContent className={classes.dialogContent}>
          <div className={css.panelSup}>
            <div className={css.date}>
              <div className={css.itemDayMonth}>{movementDayMonth}</div>
              {movementYear}
            </div>
            <div className={css.infoProcess}>
              <div title="Cliente" className={css.client}>
                <ContactsOutlined className={classes.icon} />
                <div className={css.clientName}>{timesheet.client.contactName}</div>
              </div>
              <div title="Pasta/Caso" className={css.folder}>
                <FolderOutlined className={classes.icon} />
                <div className={css.folderNumber}>
                  {timesheet.process.folderNumber}{" "}
                  {timesheet.process.title ? `- ${timesheet.process.title}` : null}
                </div>
              </div>
            </div>
          </div>
          <div className={css.panelInf}>
            <div title="Advogado executor" className={css.item}>
              <Icon className={`fas fa-user-tie ${classes.icon}`} />
              <div className={css.content}>
                <div className={css.label}>Advogado executor</div>
                <div className={css.textContent}>{timesheet.executionLawyer.contactName}</div>
              </div>
            </div>
            <Divider />
            <div title="Tempo" className={css.item}>
              <TimerOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Tempo executado</div>
                <div className={css.textContent}>
                  {timeFormat(timesheet.startTime)} - {timeFormat(timesheet.endTime)} |{" "}
                  {timesheet.timeSpent} Minutos
                </div>
              </div>
            </div>
            <Divider />
            <div title="Discriminação" className={css.item}>
              <NotesOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Discriminação</div>
                <div className={css.textContent}>{timesheetDiscrimination}</div>
              </div>
            </div>
            <Divider />
            <div title="Revisado" className={css.item}>
              <Icon className={`far fa-calendar-check ${classes.iconFont}`} />
              <div className={css.content}>
                <div className={css.label}>Revisado em</div>
                <div className={css.textContent}>{revisionDate}</div>
              </div>
            </div>
            <Divider />
            <div title="Faturável" className={css.item}>
              <Icon className={`fas fa-hand-holding-usd ${classes.iconFont}`} />
              <div className={css.content}>
                <div className={css.label}>Faturável</div>
                <div className={css.textContent}>
                  {timesheet.chargeable === true ? "Sim" : "Não"}
                </div>
              </div>
            </div>
            <Divider />
            <div title="Faturado" className={css.item}>
              <Icon className={`fas fa-file-invoice-dollar ${classes.iconFont}`} />
              <div className={css.content}>
                <div className={css.label}>Faturado</div>
                <div className={css.textContent}>{timesheet.status === "F" ? "Sim" : "Não"}</div>
              </div>
            </div>
            <Divider />
            <div title="ID" className={css.item}>
              <MoneyOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>ID</div>
                <div className={css.textContent}>{timesheet.id}</div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Dialog
        className={classes.dialog}
        open={true}
        onClose={() => openTimesheet(0)}
        maxWidth="md"
        fullWidth
        fullScreen={fullScreen}
      >
        <DialogTitle className={classes.dialogTitle}>
          <div className={css.header}>
            <IconButton
              className={classes.closeIcon}
              title="Fechar janela"
              onClick={() => openTimesheet(0)}
            >
              <CloseOutlined />
            </IconButton>
            <IconButton
              disabled={!canEdit}
              className={classes.editIcon}
              title={"Editar honorário"}
              onClick={() => setOpenEdit(true)}
            >
              <EditOutlined />
            </IconButton>
            <IconButton
              disabled={!canDelete}
              className={classes.deleteIcon}
              title="Excluir"
              onClick={() => {
                setQuestionDialog(s => ({ ...s, open: true }));
              }}
            >
              <Icon className={`far fa-trash-alt ${classes.iconDelete}`} />
            </IconButton>
          </div>
        </DialogTitle>
        {loading ? (
          <LinearProgress variant="query" className={classes.loading} />
        ) : (
          <>
            <div className={css.loading} />
            {dialogContent}
          </>
        )}

        <Dialog
          className={classes.dialog}
          open={questionDialog.open}
          onClose={() => setQuestionDialog(s => ({ ...s, open: false }))}
        >
          {questionDialog.loading ? <LinearProgress className={classes.loading} /> : null}
          <Icon className={`far fa-trash-alt ${classes.iconAlertDelete}`} />
          <DialogContent>
            <div className={css.dialogTitle}>
              Para confirmar a exclusão digite <span style={{ fontWeight: 600 }}>EXCLUIR</span>
            </div>
            <div className={css.notes}>
              <TextField
                fullWidth
                inputProps={{ maxLength: 7 }}
                onChange={e => {
                  e.persist();
                  setQuestionDialog(s => ({ ...s, message: e.target.value }));
                }}
                autoFocus
                placeholder="Digite aqui"
                className={classes.textField}
                value={questionDialog.message}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={
                questionDialog.message === "EXCLUIR" || questionDialog.message === "excluir"
                  ? false
                  : true
              }
              className={classes.dialogButton}
              onClick={submitDelete}
              color="primary"
              autoFocus
              size="small"
            >
              Excluir
            </Button>
            <Button
              className={classes.dialogButton}
              onClick={() => {
                setQuestionDialog(s => ({ ...s, open: false }));
              }}
              color="primary"
              autoFocus
              size="small"
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
      {timesheet && openEdit ? (
        <TimesheetEdit
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          updateTimesheet={updateTimesheet}
          timesheet={timesheet}
          focusNotes={questionDialogEdit.confirmed}
        />
      ) : null}
    </Fragment>
  );
}
