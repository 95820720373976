import React, { useState, useEffect } from "react";
import {
  Dialog,
  useTheme,
  useMediaQuery,
  DialogTitle,
  IconButton,
  Button,
  TextField,
  DialogContent,
  Divider,
  List,
  ListItem
} from "@material-ui/core";
import {
  CloseOutlined,
  ContactsOutlined,
  SearchOutlined,
  FolderOutlined
} from "@material-ui/icons";
import css from "./ClientSearch.module.css";
import { CaseType } from "../../declarations";
import apiCaller from "../../Root/apiCaller";
import makeStyles from "./Styles";
import { formatDoc } from "../../Utils/fieldFormat";

export default ClientSearch;

function ClientSearch(props: Props) {
  const { openSearch, setOpenSearch, submitSearch } = props;

  const classes = makeStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [loading, setLoading] = useState(0);
  const [error, setError] = useState<null | string>(null);

  const [clientSearchString, setClientSearchString] = useState("");
  const [caseSearchString, setCaseSearchString] = useState("");

  interface Client {
    nickname: string;
    code: string;
    CPFCNPJ: string;
    contactName: string;
    personId: number;
    contactId: number;
  }

  const [clientArray, setClientArray] = useState<Client[]>([]);
  const [caseArray, setCaseArray] = useState<CaseType[]>([]);

  const [selectedClient, setSelectedClient] = useState<Client | null>(null);

  async function getClients() {
    setClientArray([]);
    setCaseArray([]);

    setLoading(s => s + 1);
    const { data, error } = await apiCaller.get<{ data: Client[] }>(
      "/searchClients/" + clientSearchString
    );
    if (data) {
      setClientArray(data.data);
    } else {
      setError(error);
    }
    setLoading(s => s - 1);
  }

  useEffect(() => {
    if (selectedClient !== null)
      (async function() {
        setLoading(s => s + 1);
        const { data, error } = await apiCaller.get<{ data: CaseType[] }>(
          "/cases/searchByClient/" + selectedClient!.personId
        );
        if (data) {
          setCaseArray(data.data);
        } else {
          setError(error);
        }
        setLoading(s => s - 1);
      })();
  }, [selectedClient]);

  return (
    <Dialog
      className={classes.dialog}
      open={openSearch}
      onClose={() => setOpenSearch(false)}
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle className={classes.dialogTitle} id="responsive-dialog-title">
        <div className={css.header}>
          <IconButton
            className={classes.closeIcon}
            title="Fechar janela"
            onClick={() => setOpenSearch(false)}
          >
            <CloseOutlined />
          </IconButton>
          <Button
            className={classes.saveButton}
            onClick={() => setOpenSearch(false)}
            variant="contained"
            color="primary"
            autoFocus
            size="small"
          >
            OK
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={css.searchContent}>
          <div title="Cliente" className={css.searchHeader}>
            <ContactsOutlined className={classes.icon} />
            <div className={css.contentTitle}>Cliente</div>
            <div className={css.fieldSearch}>
              <TextField
                inputProps={{ maxLength: 100 }}
                placeholder="Nome do cliente"
                className={`${classes.textField} ${classes.search}`}
                value={clientSearchString}
                onChange={e => {
                  setClientSearchString(e.target.value);
                }}
                onKeyDown={e =>
                  e.keyCode === 13 && clientSearchString.length >= 3 ? getClients() : null
                }
              />
              <IconButton
                className={classes.searchIcon}
                title="Pesquisar"
                disabled={clientSearchString.length < 3}
                onClick={getClients}
              >
                <SearchOutlined />
              </IconButton>
            </div>
          </div>
          <List className={`${classes.list} ${css.searchList}`}>
            {clientArray.map(c => {
              return (
                <ListItem
                  key={c.personId}
                  style={
                    selectedClient && c.personId === selectedClient.personId
                      ? { backgroundColor: "#d0d0d0" }
                      : {}
                  }
                  button
                  onClick={() => setSelectedClient(c)}
                  className={`${classes.listItem} ${css.itemSearchList}`}
                >
                  <div className={css.content}>
                    {c.code} - {c.contactName}
                    {c.CPFCNPJ.trim() === "" ? "" : " - " + formatDoc(c.CPFCNPJ)}
                  </div>
                </ListItem>
              );
            })}
          </List>
        </div>
        <Divider />
        <div className={css.searchContent}>
          <div title="Casos" className={css.searchHeader}>
            <FolderOutlined className={classes.icon} />
            <div className={css.contentTitle}>Casos</div>
            <div className={css.fieldSearch}>
              <TextField
                inputProps={{ maxLength: 100 }}
                placeholder="Busca"
                className={`${classes.textField} ${classes.search}`}
                value={caseSearchString}
                onChange={e => {
                  setCaseSearchString(e.target.value);
                }}
              />
              <IconButton className={classes.searchIcon} title="Pesquisar" onClick={() => false}>
                <SearchOutlined />
              </IconButton>
            </div>
          </div>
          <List className={`${classes.list} ${css.searchList}`}>
            {caseArray
              .filter(c => {
                if (c.title.toLowerCase().indexOf(caseSearchString.toLowerCase()) >= 0) return true;
                if (c.folderNumber.toLowerCase().indexOf(caseSearchString.toLowerCase()) >= 0)
                  return true;

                return false;
              })
              .map(c => (
                <ListItem
                  key={c.id}
                  button
                  onClick={() => submitSearch(c.folderNumber)}
                  className={`${classes.listItem} ${css.itemSearchList}`}
                >
                  <div className={css.content}>
                    {c.folderNumber} - {c.title}
                  </div>
                </ListItem>
              ))}
          </List>
        </div>
      </DialogContent>
    </Dialog>
  );
}

interface Props {
  submitSearch: (n: string) => void;
  openSearch: boolean;
  setOpenSearch: (s: boolean) => void;
}
