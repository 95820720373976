/**
 * Percorre as keys de um objeto e verifica se string passada existe
 * dentro deste objeto.
 * @example isKeyIncludedIn<TimesheetType>(timesheet, "date");
 * return true;
 * @example isKeyIncludedIn<TimesheetType>(timesheet, "keyQueNemExiste");
 * return false;
 */
export function isKeyIncludedIn<obj>(obj: obj, keyName: string): boolean {
  const objKeys = Object.keys(obj);
  return objKeys.includes(keyName);
}
