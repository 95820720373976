import React, { useState, Fragment, useEffect } from "react";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  IconButton,
  DialogContent,
  Divider,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import { ContactType } from "../../../declarations";
import {
  CloseOutlined,
  EmailOutlined,
  SmartphoneOutlined,
  PhoneOutlined,
  WorkOutlined,
  BusinessOutlined,
  AssignmentIndOutlined,
  HomeOutlined,
  ContactsOutlined,
  MapOutlined
} from "@material-ui/icons";
import css from "./Contact.module.css";
import apiCaller from "../../apiCaller";
import { formatPhone, formatCellPhone, formatDoc } from "../../../Utils/fieldFormat";
import makeStyles from "./Styles";

export default Contact;
function Contact({
  contact,
  updateContact,
  setOpenContact,
  openContactId
}: {
  contact?: ContactType;
  updateContact: (id: number, updateFunction: (a: ContactType) => ContactType) => void;
  setOpenContact: (id?: number) => void;
  openContactId: number;
}) {
  const [loading, setLoading] = useState(false);

  const classes = makeStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [relatedContacts, setRelatedContacts] = useState<ContactType[]>([]);

  useEffect(
    function() {
      (async function() {
        setLoading(true);
        const { data: dataContact } = await apiCaller.get(`/contacts/${openContactId}`);
        const { data: dataRelated } = await apiCaller.get(`/contacts/${openContactId}/related`);

        if (dataRelated) {
          setRelatedContacts(dataRelated.data);
        }
        if (dataContact) {
          updateContact(openContactId, () => dataContact.contact);
        }
        setLoading(false);
      })();
    },
    [openContactId, updateContact]
  );

  let dialogContent: JSX.Element | null = null;

  if (contact) {
    const phoneNumber = formatPhone(contact.phoneNumber === null ? "" : contact.phoneNumber);
    const cellPhoneNumber = formatCellPhone(
      contact.cellPhoneNumber === null ? "" : contact.cellPhoneNumber
    );
    const docNumber = formatDoc(contact.CPF === null ? "" : contact.CPF);
    const classInitials = contact.contactClass.trim().split("");
    const classFull = classInitials
      .map((initials: string) => {
        switch (initials) {
          case "C":
            return "cliente";
          case "S":
            return "possível cliente";
          case "I":
            return "cliente interno";
          case "A":
            return "advogado";
          case "E":
            return "estagiário";
          case "U":
            return "administrativo";
          case "F":
            return "fornecedor";
          case "D":
            return "correspondente";
          case "P":
            return "parte";
          case "R":
            return "captador externo";
          case "O":
            return "outros";
          default:
            return "classe desconhecida";
        }
      })
      .join(" | ");

    const cityState = [contact.district, contact.city, contact.state]
      .filter(s => s !== null && s !== "")
      .join(" - ");

    const endCompleto = `${contact.address} ${contact.addressComplement} ${cityState}`;
    const stringSearchMap = endCompleto.replace(
      /[^0-9a-zA-Zçâ-ôÂ-Ôá-úÁ-Úà-ùÀ-Ùã-õÃ-ÕºªüÜäÄ,.-]/g,
      "+"
    );

    let relatedContactsList = null;

    if (!loading && relatedContacts.length > 0)
      relatedContactsList = (
        <Fragment>
          <Divider />
          <div title="Contatos" className={css.item}>
            <ContactsOutlined className={classes.icon} />
            <div className={css.contentTitle}>Contatos vinculados</div>
          </div>
          <div className={css.listRelatedContacts}>
            {relatedContacts.map(rc => {
              return (
                <div key={rc.id} className={css.itemRelatedContacts}>
                  <div className={css.contact}>{rc.name}</div>
                  <div onClick={() => setOpenContact(rc.id)} className={css.viewContact}>
                    ver
                  </div>
                </div>
              );
            })}
          </div>
        </Fragment>
      );

    dialogContent = (
      <Fragment>
        <DialogContent className={classes.dialogContent}>
          <div className={css.panelSup}>
            <div className={css.itemContacts}>
              <div title="Nome" className={css.name}>
                {contact.name}
              </div>
              <div title="cargo" className={css.cargo}>
                {contact.role}
              </div>
              <a
                onClick={e => e.stopPropagation()}
                href={"mailto:" + contact.email}
                title="Email"
                className={css.email}
              >
                <EmailOutlined className={classes.icon} />
                <div className={css.emailAdress}>{contact.email}</div>
              </a>
              <div title="Telefone" className={css.phoneNumbers}>
                <a
                  onClick={e => e.stopPropagation()}
                  href={"tel:" + contact.cellPhoneNumber}
                  className={css.phone}
                >
                  <PhoneOutlined className={classes.icon} />
                  <div className={css.content}>{phoneNumber}</div>
                </a>
                <a
                  onClick={e => e.stopPropagation()}
                  href={"tel:" + contact.cellPhoneNumber}
                  className={css.cellPhone}
                >
                  <SmartphoneOutlined className={classes.icon} />
                  <div className={css.content}>{cellPhoneNumber}</div>
                </a>
              </div>
            </div>
          </div>
          <div className={css.panelInf}>
            <div title="classe" className={css.item}>
              <WorkOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Classe</div>
                <div className={css.contactClass}>{classFull}</div>
              </div>
            </div>
            {contact.id !== contact.contactOriginId ? (
              <div title="Origem" className={css.item}>
                <BusinessOutlined className={classes.icon} />
                <div className={css.content}>
                  <div className={css.label}>Origem</div>
                  <div className={css.contactOrigin}>{contact.contactOrigin}</div>
                  <div
                    onClick={() => setOpenContact(contact.contactOriginId)}
                    className={css.viewContactOrigin}
                  >
                    Ver
                  </div>
                </div>
              </div>
            ) : null}
            <div title="CPF | CNPJ" className={css.item}>
              <AssignmentIndOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>CPF | CNPJ</div>
                <div className={css.docNumber}>{docNumber}</div>
              </div>
            </div>

            <Divider />
            <div title="Endereço Completo" className={css.item}>
              <HomeOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Endereço Completo</div>
                {cityState !== "" && cityState !== null ? (
                  <Fragment>
                    <a
                      href={`https://www.google.com.br/maps/search/${stringSearchMap}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={css.contentAdress}
                    >
                      <div title="Endereço" className={css.adress}>
                        {contact.address}
                      </div>
                      <div title="Complemento" className={css.addressComplement}>
                        {contact.addressComplement}
                      </div>
                      <div title="Bairro/Cidade" className={css.adressCity}>
                        {cityState}
                      </div>
                    </a>
                    <IconButton
                      className={classes.viewMaps}
                      title="Visualizar no mapa"
                      href={`https://www.google.com.br/maps/search/${stringSearchMap}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <MapOutlined className={classes.iconMap} />
                    </IconButton>{" "}
                  </Fragment>
                ) : null}
              </div>
            </div>
            {relatedContactsList}
          </div>
        </DialogContent>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Dialog
        className={classes.dialog}
        open={true}
        onClose={() => setOpenContact()}
        maxWidth="sm"
        fullWidth
        fullScreen={fullScreen}
      >
        <DialogTitle className={classes.dialogTitle}>
          <div className={css.header}>
            <IconButton
              className={classes.closeIcon}
              title="Fechar janela"
              onClick={() => setOpenContact()}
            >
              <CloseOutlined />
            </IconButton>
          </div>
        </DialogTitle>
        {loading ? (
          <LinearProgress variant="query" className={classes.loading} />
        ) : (
          <div className={css.loading} />
        )}
        {dialogContent}
      </Dialog>
    </Fragment>
  );
}
