import React from "react";
import { ListItem, Icon } from "@material-ui/core";
import css from "./ProcMovementsListItem.module.css";
import { ContactsOutlined, FolderOutlined } from "@material-ui/icons";
import makeStyles from "./Styles";
import moment from "moment";
import "moment/locale/pt-br";

export default ProcMovementsListItem;

moment.locale("pt-br");

function ProcMovementsListItem(props: any) {
  const { content: procMovement } = props;

  const newDate = moment(procMovement.date).format("YYYY-MM-DD")

  const currentYear = moment().format("YYYY")

  const movementDayMonth = moment(newDate).format("D MMM")
  const year = moment(newDate).format("YYYY")

  const movementYear =
    currentYear !== year ? (
      <div className={css.itemDateYear}>
        <div className={css.itemYear}>{year}</div>
      </div>
    ) : null;

  const classes = makeStyles();

  return (
    <div>
      <ListItem
        onClick={() => props.openProcMovement(procMovement.id)}
        className={`${classes.Item} ${css.listItem}`}
        button
      >
        <div className={css.ItemDate}>
          <div className={css.itemDayMonth}>{movementDayMonth}</div>
          {movementYear}
        </div>

        <div className={css.listItemInfo}>
          <div className={css.itemProcess}>{procMovement.processNumber}</div>
          <div className={css.itemDesc}>{procMovement.description}</div>
          <div className={css.itemClientIcon}>
            <ContactsOutlined className={classes.iconInfo} />
          </div>
          <div className={css.itemClientName}>{procMovement.client}</div>
          <div title="Pasta/Caso" className={css.itemFolderIcon}>
            <FolderOutlined className={classes.iconInfo} />
          </div>
          <div className={css.itemFolderNumber}>
            {procMovement.folderNumber}{" "}
            {procMovement.processTitle ? `- ${procMovement.processTitle}` : null}
          </div>
        </div>
        <div className={css.listItemTools}>
          {procMovement.origin > 0 ? (
            <>
              <Icon
                title="Publicação Diario Oficial"
                className={`far fa-newspaper ${classes.icon}`}
              />
              <span className={css.originTitle}>Diário</span>
            </>
          ) : null}
        </div>
      </ListItem>
    </div>
  );
}

interface Props {}
