import {
  IDictionary,
  QueryPagination,
  DisplayMode,
  Period,
  TimesheetType,
  PersonBasic,
  Order,
  OrderField
} from "../../declarations";
import { endOfToday, startOfToday } from "date-fns";
import { subDays } from "date-fns/esm";
export const initialState: State = {
  defaultExecutionLawyer: null,
  chargeable: null,
  charged: null,
  selectedExecutionLawyer: null,
  error: null,
  displayMode: 0,
  refreshing: 0,
  order: { mode: "ASC", field: { name: "date", label: "Data de lançamento" } },
  orderFields: [
    { name: "date", label: "Data de lançamento" },
    { name: "id", label: "Data de inclusão" }
  ],
  selectedPeriod: { fromDate: subDays(startOfToday(), 7), toDate: endOfToday() },
  query: "/timesheets?",
  fetched: {},
  modes: [
    {
      label: "Todos",
      filterFunction: () => true,
      query: "",
      search: ""
    }
  ],
  queryPaginations: {}
};

export function reducer(state: State, action: Action): State {
  const currentMode = { ...state.modes[state.displayMode] };
  let newState = { ...state };
  if (action.type === "updateQuery") {
    let query = "/timesheets";
    if (currentMode.search) {
      query += "/search/" + currentMode.search;
    }
    query += "?" + currentMode.query;

    query += `&orderMode=${state.order.mode}`;
    query += `&orderField=${state.order.field.name}`;

    if (state.selectedPeriod.fromDate !== null && state.selectedPeriod.toDate !== null)
      query += `&fromDate=${state.selectedPeriod.fromDate.toString()}&toDate=${state.selectedPeriod.toDate.toString()}`;

    if (state.chargeable !== null) query += `&chargeable=` + state.chargeable;
    if (state.charged !== null) query += `&charged=` + state.charged;

    if (state.selectedExecutionLawyer !== null)
      query += `&executionLawyerPersonId=` + state.selectedExecutionLawyer.personId;
    else if (state.defaultExecutionLawyer !== null)
      query += `&executionLawyerPersonId=` + state.defaultExecutionLawyer.personId;

    newState.query = query;
    if (state.queryPaginations[query] === undefined) {
      newState.queryPaginations[query] = {
        owns: new Set(),
        loading: true,
        lastPage: 1,
        endReached: false
      };
    }
  }
  if (action.type === "fetchNextPage") {
    const { data, query } = action.payload;
    const dataObj: IDictionary<TimesheetType> = {};
    const fetchedTimesheets = data;
    const fetchedQuery = query;
    const fetchQueryPagination = { ...state.queryPaginations[fetchedQuery] };

    fetchedTimesheets.forEach((c: TimesheetType) => {
      c.date = new Date(c.date);
      dataObj[c.id] = c;
      fetchQueryPagination.owns.add(c.id);
    });

    fetchQueryPagination.lastPage++;
    if (fetchedTimesheets.length === 0) fetchQueryPagination.endReached = true;

    newState.fetched = { ...state.fetched, ...dataObj };
    newState.queryPaginations = {
      ...state.queryPaginations,
      [fetchedQuery]: fetchQueryPagination
    };
  }
  if (action.type === "loading") {
    const { query, loading } = action.payload;
    newState.queryPaginations[query].loading = loading;
  }
  if (action.type === "search") {
    newState.modes[state.displayMode].search = action.payload;
  }
  if (action.type === "clearFilters") {
    newState.displayMode = 0;
    newState.modes.forEach(mode => (mode.search = ""));
    newState.selectedPeriod = { fromDate: null, toDate: null };
    newState.chargeable = null;
    newState.charged = null;
    newState.selectedExecutionLawyer = state.defaultExecutionLawyer;
  }
  if (action.type === "displayMode") {
    newState.displayMode = action.payload;
  }
  if (action.type === "refresh") {
    newState.fetched = {};
    newState.queryPaginations = {};
    newState.refreshing++;
  }
  if (action.type === "order") {
    newState.order = action.payload;
  }
  if (action.type === "chargeable") {
    newState.chargeable = action.payload;
  }
  if (action.type === "charged") {
    newState.charged = action.payload;
  }
  if (action.type === "selectedExecutionLawyer") {
    newState.selectedExecutionLawyer = action.payload;
  }
  if (action.type === "selectedPeriod") {
    const newPeriod = action.payload;
    if (newPeriod.fromDate !== null && newPeriod.toDate === null) {
      newPeriod.toDate = newPeriod.fromDate;
    }
    newState.selectedPeriod = action.payload;
  }
  if (action.type === "updateTimesheet") {
    newState.fetched[action.payload.id] = action.payload;
  }
  if (action.type === "deleteTimesheet") {
    const newFetched: IDictionary<TimesheetType> = {};
    Object.entries(state.fetched).forEach(([key, value]) => {
      if (key !== "" + action.payload) newFetched[key] = value;
    });
    newState.fetched = newFetched;
  }

  return newState;
}

export type Action =
  | SimpleAction
  | FetchNextPageAction
  | LoadingAction
  | OrderAction
  | SelectedPeriodAction
  | SearchAction
  | ErrorAction
  | DisplayModeAction
  | UpdateTimesheetAction
  | ChargableFilterAction
  | ChargedFilterAction
  | SelectedExecutionLawyerAction
  | DeleteTimesheetAction;

type SimpleAction = {
  type: "updateQuery" | "clearFilters" | "refresh";
};

interface FetchNextPageAction {
  type: "fetchNextPage";
  payload: { data: TimesheetType[]; query: string };
}
interface LoadingAction {
  type: "loading";
  payload: { query: string; loading: boolean };
}
interface SearchAction {
  type: "search";
  payload: string;
}
interface OrderAction {
  type: "order";
  payload: State["order"];
}
interface SelectedPeriodAction {
  type: "selectedPeriod";
  payload: Period;
}
interface ErrorAction {
  type: "error";
  payload: Error;
}
interface DisplayModeAction {
  type: "displayMode";
  payload: number;
}
interface UpdateTimesheetAction {
  type: "updateTimesheet";
  payload: TimesheetType;
}
interface ChargableFilterAction {
  type: "chargeable";
  payload: boolean | null;
}
interface ChargedFilterAction {
  type: "charged";
  payload: boolean | null;
}
interface SelectedExecutionLawyerAction {
  type: "selectedExecutionLawyer";
  payload: PersonBasic | null;
}
interface DeleteTimesheetAction {
  type: "deleteTimesheet";
  payload: number;
}

export interface State {
  defaultExecutionLawyer: PersonBasic | null;
  selectedExecutionLawyer: PersonBasic | null;
  chargeable: boolean | null;
  charged: boolean | null;
  error: Error | null;
  displayMode: number;
  refreshing: number;
  order: Order<OrderFieldNames>;
  orderFields: OrderField<OrderFieldNames>[];
  selectedPeriod: Period;
  query: string;
  fetched: IDictionary<TimesheetType>;
  modes: DisplayMode<TimesheetType>[];
  queryPaginations: IDictionary<QueryPagination>;
}

export type OrderFieldNames = "date" | "id";
