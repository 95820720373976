import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    iconButton: {
      padding: "4px"
    },
    loading: {
      alignSelf: "center",
      justifySelf: "center",
      color: "#125ad3"
    }
  })
);
