import React, { useState, SyntheticEvent, ChangeEvent, useEffect, useContext } from "react";
import { RouteComponentProps } from "react-router";
import { TextField, Button, InputAdornment, IconButton, FormHelperText } from "@material-ui/core";
import css from "./Recovery.module.css";
import Title from "../../../Components/Title/Title";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import apiCaller from "../../apiCaller";
import InvalidToken from "./InvalidToken";
import { userContext } from "../../Root";
import { ErrorOutlined } from "@material-ui/icons";
import makeStyles from "./Styles";

export default function (
  props: RouteComponentProps & { loading: boolean; setLoading: (newState: boolean) => void }
) {
  const { setLoading } = props;
  const [error, setError] = useState("");

  const [state, setState] = useState<State>({
    newPassword: "",
    newPasswordConfirm: "",
    showPassword: false,
  });

  const { token } = props.match.params as { token?: string };

  const { fetchUser } = useContext(userContext);
  useEffect(() => {
    (async () => {
      const { error } = await apiCaller.get("/auth/passwordRecovery/" + token);
      if (error) {
        if (error !== 1) {
          setError(error === "Invalid Token" ? "Token Invalido" : "");
        }
      }
      setLoading(false);
    })();
  }, [error, setLoading, token]);

  const handleSubmit = async (e: SyntheticEvent<EventTarget>) => {
    e.preventDefault();
    const { newPassword } = state;
    setLoading(true);
    const { error, data } = await apiCaller.post<any>("/auth/passwordRecovery/" + token, {
      password: newPassword,
    });
    if (error) {
      if (error !== 1) setError(error);
    } else {
      apiCaller.setAuthToken(data.token, true);
      await fetchUser();
      props.history.push("/prazos");
    }
    setLoading(false);
  };

  const handleChange = (field: keyof State) => (e: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [field]: e.target.value });
  };

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const canBeSubmitted = () => {
    const { newPassword, newPasswordConfirm } = state;
    return (
      newPasswordConfirm.length >= 5 &&
      newPassword.length >= 5 &&
      newPassword === newPasswordConfirm
    );
  };

  const classes = makeStyles();
  if (error === "Token Invalido") {
    return (
      <div className={css.recoveryForm}>
        <InvalidToken />
      </div>
    );
  } else {
    return (
      <form className={css.recoveryForm} onSubmit={handleSubmit}>
        <Title className={css.titleRecovery}>INFORME A NOVA SENHA</Title>
        <div className={css.fieldsRecovery}>
          <TextField
            required
            className={`${css.formControl} ${classes.textField}`}
            type={state.showPassword ? "text" : "Password"}
            label="Senha"
            onChange={handleChange("newPassword")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="Toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            required
            className={`${css.formControl} ${classes.textField}`}
            type={state.showPassword ? "text" : "Password"}
            label="Confirmar senha"
            onChange={handleChange("newPasswordConfirm")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="Toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormHelperText style={{ justifySelf: "center" }}>
            A senha deverá conter no mínimo 5 dígitos
          </FormHelperText>
        </div>
        {state.newPassword !== "" &&
        state.newPasswordConfirm !== "" &&
        state.newPassword !== state.newPasswordConfirm ? (
          <div className={css.errorRecovery}>
            <ErrorOutlined className={css.iconError} color="error" fontSize="small" />
            <p className={css.errorText}>Senhas não coincidem</p>
          </div>
        ) : (
          ""
        )}
        <Button
          type="submit"
          className={`${classes.button} ${css.recoveryBtn}`}
          variant="contained"
          color="primary"
          disabled={!canBeSubmitted()}
        >
          Entrar
        </Button>
      </form>
    );
  }
}

interface State {
  newPassword: string;
  newPasswordConfirm: string;
  showPassword: boolean;
}
