import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    dialog: {
      "& .MuiDialog-paperWidthMd": {
        minHeight: "90vh",
        "@media only screen and (max-width: 820px)": {
          width: "100%",
          height: "100%",
          margin: "0",
          maxWidth: "100%",
          maxHeight: "none",
          borderRadius: "0"
        }
      }
    },
    dialogTitle: {
      backgroundColor: "#fff",
      gridTemplateColumns: "1fr 1fr",
      padding: "0px 0px",
      boxShadow:
        "0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px 0px rgba(0, 0, 0, 0), 0px 1px 10px 0px rgba(0, 0, 0, 0.1)"
    },

    listItem: {
      borderColor: "rgba(0, 0, 0, 0.12)",
      borderStyle: "solid",
      borderWidth: "0.25px 0",
      display: "grid",
      paddingTop: "1px",
      paddingBottom: "1px",
      height: "40px"
    },
    list: {
      paddingTop: "0",
      paddingBottom: "0"
    },
    saveButton: {
      gridColumn: "2",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "end",
      margin: "8px 8px",
      backgroundColor: "#125ad3",
      "&:hover": {
        backgroundColor: "#1c5793"
      }
    },
    closeIcon: {
      gridColumn: "1",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "start",
      padding: "4px",
      marginLeft: "6px"
    },

    searchIcon: {
      gridColumn: "2",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "start",
      padding: "4px",
      color: "#125ad3"
    },

    loading: {
      height: "5px",
      position: "relative",
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#125ad3"
      }
    },

    icon: {
      color: "#737373",
      fontSize: "20px",
      gridRow: "1",
      gridColumn: "1",
      alignSelf: "center"
    },

    search: {
      width: "100%",
      maxWidth: "130px"
    },

    textField: {
      display: "grid",
      gridRow: "1",
      gridColumn: "1",
      color: "white",
      height: 48,
      "& .MuiInputBase-root": {
        alignSelf: "center",
        fontSize: "0.9rem"
      },
      "&:hover:not(.Mui-disabled):before": {
        borderBottom: "2px solid #125ad3"
      },
      "&:after": {
        borderBottom: "2px solid #125ad3"
      },

      "& label.Mui-focused": {
        color: "#125ad3"
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#125ad3"
      },
      "& .MuiInput-underline": {
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: "2px solid #125ad3"
        },
        "&:after": {
          borderBottom: "2px solid #125ad3"
        }
      }
    }
  })
);
