import axios, { AxiosInstance, AxiosResponse } from "axios";
import { Result } from "../declarations";

const TOKEN_KEY = "@token_sisea";
const REMEMBER_KEY = "@remember_sisea";

class apiCaller {
  public token: string;
  public shouldRemember: boolean;
  public axiosInstance: AxiosInstance;
  public authInterceptor?: number;
  public expiredInterceptor: number;
  public get: <Data, Error = any>(
    ...args: Parameters<AxiosInstance["get"]>
  ) => Promise<Result<Data, Error>>;
  public post: <Data, Error = any>(
    ...args: Parameters<AxiosInstance["post"]>
  ) => Promise<Result<Data, Error>>;
  public patch: <Data, Error = any>(
    ...args: Parameters<AxiosInstance["patch"]>
  ) => Promise<Result<Data, Error>>;
  public delete: <Data, Error = any>(
    ...args: Parameters<AxiosInstance["delete"]>
  ) => Promise<Result<Data, Error>>;

  constructor() {
    const token = localStorage.getItem(TOKEN_KEY);
    this.token = token ? token : "";
    this.shouldRemember = localStorage.getItem(REMEMBER_KEY) === "true";
    this.axiosInstance = axios.create({
      baseURL: (process.env.NODE_ENV === "development" ? "http://localhost:3001" : "") + "/api"
    });

    type Method = "get" | "post" | "patch" | "delete";
    const requestHandler = <Methodd extends Method>(method: Method) => async <Data>(
      ...args: Parameters<AxiosInstance[Methodd]>
    ): Promise<Result<Data, any>> => {
      try {
        const response = (await (this.axiosInstance[method] as any)(...args)) as AxiosResponse;
        return { data: response.data as Data };
      } catch (error) {
        return { error };
      }
    };

    this.get = requestHandler("get");
    this.post = requestHandler("post");
    this.patch = requestHandler("patch");
    this.delete = requestHandler("delete");

    if (!this.shouldRemember) {
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(REMEMBER_KEY);
    }

    this.authInterceptor = this.axiosInstance.interceptors.request.use(config => {
      config.headers.Authorization = "Bearer " + this.token;
      return config;
    });

    this.expiredInterceptor = this.axiosInstance.interceptors.response.use(
      res => {
        return res;
      },
      error => {
        if (error.response && error.response.data.message === "Invalid Session") {
          this.setAuthToken("", false);
          window.location.reload();
        }
        return Promise.reject(error);
      }
    );
  }

  public setAuthToken(newToken: string, remember: boolean = true) {
    this.token = newToken;
    this.shouldRemember = remember;
    if (this.shouldRemember) {
      localStorage.setItem(TOKEN_KEY, newToken);
      localStorage.setItem(REMEMBER_KEY, remember + "");
    }
    this.authInterceptor = this.axiosInstance.interceptors.request.use(config => {
      config.headers.Authorization = "Bearer " + this.token;
      return config;
    });
  }
}

export default new apiCaller();
