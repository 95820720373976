import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    button: {
      height: "35px",
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.05)"
      }
    },
    listItemText: {
      "& span.MuiTypography-body1": {
        fontSize: "14px"
      }
    },
    menuItem: {
      minWidth: "150px",
      "&.MuiListItem-button:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.05)"
      }
    }
  })
);
