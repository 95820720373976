import React, { useState, ReactNode } from "react";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { UserBasic } from "../../declarations";
import makeStyles from "./Styles";
import { CircularProgress } from "@material-ui/core";

export default TransferListAsync;

function TransferListAsync(props: Props) {
  const { left, setLeft, setValueRight, right, setRight, loadingTransfer, valueDefault } = props;

  left.sort((a: any, b: any) => a.contactName.localeCompare(b.contactName))
  right.sort((a: any, b: any) => a.contactName.localeCompare(b.contactName))

  const classes = makeStyles();

  const [checked, setChecked] = useState<Array<Object>>([]);


  props.setValueRight(right);

  function not(a: Array<Object>, b: Array<Object>) {
    return a!.filter((value: any) => b!.indexOf(value) === -1);
  }

  function intersection(a: Array<Object>, b: Array<Object>) {
    return a.filter(value => b!.indexOf(value) !== -1);
  }

  function union(a: Array<Object>, b: Array<Object>) {
    return [...a, ...not(b, a)];
  }

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (item: UserBasic) => () => {
    const itemChecked = item.id !== valueDefault.id? item : ''
    const currentIndex = checked!.indexOf(itemChecked);
    const newChecked = [...checked]
    newChecked.filter((a: any) => a.id !== valueDefault.id)

    if (currentIndex === -1) {
      newChecked!.push(itemChecked);
    } else {
      newChecked!.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: Array<Object>) => intersection(checked, items).length;

  const handleToggleAll = (items: Array<Object>) => () => {
    const itemsChecked = items.filter((a: any) => a.id !== valueDefault.id)
    if (numberOfChecked(itemsChecked) === itemsChecked!.length) {
      setChecked(not(checked, itemsChecked));
    } else {
      setChecked(union(checked, itemsChecked));
    }
  };

  const handleCheckedRight = () => {
    setRight(right!.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left!.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  let customList;

  if (left !== undefined) {
    customList = (title: ReactNode, items: Array<Object>) => (
      <Card>
        <CardHeader
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={numberOfChecked(items) === items!.length && items!.length !== 0}
              indeterminate={
                numberOfChecked(items) !== items!.length && numberOfChecked(items) !== 0
              }
              disabled={items!.length === 0}
              inputProps={{ "aria-label": "all items selected" }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items!.length} selecionados`}
        />
        <Divider />
        <List className={classes.list} dense component="div" role="list">
          {items !== undefined
            ? items!.map((value: any) => {
                return (
                  <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
                    <ListItemIcon>
                      {value.id === valueDefault.id ? (
                        <Checkbox checked={false} disabled={true} tabIndex={-1} disableRipple />
                      ) : (
                        <Checkbox
                          checked={checked!.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText id={value.id} primary={value.contactName} />
                  </ListItem>
                );
              })
            : "..."}
          <ListItem />
        </List>
      </Card>
    );

    return (
      <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
        <Grid item>{customList("Não vinculados", left)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
              className={classes.button}
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
              className={classes.button}
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>{customList("Vinculados", right)}</Grid>
      </Grid>
    );
  }
  return <>{loadingTransfer ? <CircularProgress className={classes.loading} /> : null}</>;
}

interface Props {
  left: any;
  setLeft: (state: any) => void;
  right: any,
  setRight: (state: any) => void;
  setValueRight: (state: any) => void;
  loadingTransfer: boolean;
  valueDefault: any;
}
