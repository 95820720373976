import React from "react";
import {
  LinearProgress,
  IconButton,
  List,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import css from "./MainList.module.css";
import { SortOutlined, FilterListOutlined, RefreshOutlined } from "@material-ui/icons";
import { SetOrderFunc, Order, OrderField } from "../../declarations";
import makeStyles from "./Styles";

export default MainList;

function MainList<Content, ListComponentProps, OrderFieldNames>({
  loading,
  currentSearch,
  setOrder,
  order,
  orderFields,
  isFilterActive,
  clearFilters,
  refresh,
  contentArray,
  ListComponent,
  listComponentProps,
  fetchNextPage,
  endReached
}: Props<Content, ListComponentProps, OrderFieldNames>) {
  const classes = makeStyles();

  const toolbarList = (
    <div className={css.listHeader}>
      <div className={css.filterToolbar}>
        <div className={css.orderBy}>
          <FormControl>
            <InputLabel className={classes.input}>Ordenar por</InputLabel>
            <Select
              className={classes.select}
              value={orderFields.findIndex(f => order.field.name === f.name)}
              onChange={e => {
                setOrder(() => ({ ...order, field: orderFields[e.target.value as number] }));
              }}
            >
              {orderFields.map((orderField, i) => {
                return (
                  <MenuItem key={i} value={i}>
                    {orderField.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div title="Ordenar" className={css.sort}>
          <IconButton
            className={classes.iconButton}
            onClick={() =>
              setOrder(order =>
                order.mode === "ASC" ? { ...order, mode: "DESC" } : { ...order, mode: "ASC" }
              )
            }
          >
            <SortOutlined
              style={{ transform: order.mode === "ASC" ? "scaleY(-1)" : "scaleY(1)" }}
            />
          </IconButton>
        </div>
        <div title="Limpar filtros" className={css.filter}>
          {isFilterActive ? (
            <IconButton className={classes.iconButton} onClick={clearFilters}>
              <FilterListOutlined className={classes.iconFilter} />
            </IconButton>
          ) : null}
        </div>
        <div title="Atualizar" className={css.refresh}>
          <IconButton className={classes.iconButton} onClick={refresh}>
            <RefreshOutlined />
          </IconButton>
        </div>
      </div>
      {/* <div className={css.searchResults}>
        {currentSearch ? (
          <p className={css.descSearch}>
            Resultados para <span className={css.descSearchKey}>"{currentSearch}"</span>
          </p>
        ) : null}
      </div> */}
    </div>
  );

  const listContent = contentArray.map(contentItem => {
    return <ListComponent {...listComponentProps} content={contentItem} key={contentItem.id} />;
  });

  const scrollHandler = ({ currentTarget }: { currentTarget: HTMLDivElement }) => {
    const { scrollTop, scrollHeight, clientHeight } = currentTarget;
    //const scrollProportion = scrollTop === scrollHeight - clientHeight;
    if (scrollTop === scrollHeight - clientHeight && !endReached && !loading) fetchNextPage();
  };

  return (
    <div className={css.listFull}>
      {toolbarList}
      {loading ? (
        <LinearProgress variant="query" className={classes.loading} />
      ) : (
        <div className={css.loading} />
      )}

      <div className={css.listBody}>
        {!loading && contentArray.length === 0 && endReached ? (
          <div className={css.notice}>Não foram encontrados resultados</div>
        ) : (
          <List
            onScroll={(e: any) => {
              scrollHandler(e);
            }}
            className={classes.list}
          >
            {listContent}
          </List>
        )}
      </div>
    </div>
  );
}

interface Props<Content, ListComponentProps, OrderFieldNames> {
  endReached?: boolean;
  refresh: () => void;
  currentSearch?: string;
  isFilterActive?: boolean;
  setOrder: (setOrder: SetOrderFunc<OrderFieldNames>) => void;
  order: Order<OrderFieldNames>;
  clearFilters: () => void;
  loading: boolean;
  fetchNextPage: () => void;
  orderFields: OrderField<OrderFieldNames>[];
  contentArray: (Content & { id: number })[];
  ListComponent: React.ComponentType<{ key: number; content: Content } & ListComponentProps>;
  listComponentProps: ListComponentProps;
}
