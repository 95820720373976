import React, { useState, useEffect, useCallback } from "react";
import css from "../Contacts/Contacts.module.css";
import apiCaller from "../apiCaller";
import SearchList from "../../Components/SearchList/SearchList";
import { ProcMovementType, Period } from "../../declarations";
import ProcMovementsListItem from "./ListItem/ProcMovementsListItem";
import ProcMovement from "./ProcMovement/ProcMovement";
import MainList from "../../Components/MainList/MainList";
import ProcMovementsSidebar from "./Sidebar/ProcMovementsSidebar";
import { Action, State, OrderFieldNames } from "./ProcMovementsStore";
import BackDrawer from "../../Components/BackDrawer/BackDrawer";
import { RouteComponentProps } from "react-router";
import { compareWithoutDiacritics } from "../../Utils/compareWithoutDiacritics";
import { normalizeDateIn } from "../../Utils/normalizeDateIn";

export default ProcMovements;

function ProcMovements({
  dispatch,
  fetched,
  query,
  displayMode,
  order,
  orderFields,
  refreshing,
  selectedPeriod,
  fromMyTeams,
  revised,
  procMovementsTypes,
  selectedType,
  ...props
}: { dispatch: (a: Action) => void } & State & RouteComponentProps) {
  const currentMode = props.modes[displayMode];
  const currentQuery = props.queryPaginations[query];
  const idParam = (props.match.params as { id?: string }).id;
  const openProcMovementId = idParam !== undefined && !isNaN(+idParam) ? +idParam : undefined;

  useEffect(
    function() {
      (async function() {
        if (procMovementsTypes !== null) return;
        const { data } = await apiCaller.get<any>("/procMovements/types");
        if (data) {
          dispatch({ type: "fetchTypes", payload: data.data });
        }
      })();
    },
    [dispatch, procMovementsTypes]
  );

  useEffect(
    function() {
      dispatch({ type: "updateQuery" });
    },
    [
      dispatch,
      currentMode.search,
      refreshing,
      order,
      selectedPeriod,
      fromMyTeams,
      revised,
      displayMode,
      selectedType
    ]
  );

  let currentQueryLastPage = -1;
  if (currentQuery) currentQueryLastPage = currentQuery.lastPage;

  const fetchNextPage = useCallback(
    function() {
      dispatch({ type: "loading", payload: { query, loading: true } });
      (async function() {
        const { data, error } = await apiCaller.get<any>(
          query + `&pageLength=20&pageNumber=${currentQueryLastPage}`
        );
        if (!error) dispatch({
          type: "fetchNextPage",
          payload: {
            data: data.data.map((pm: ProcMovementType) => normalizeDateIn<ProcMovementType>(pm)),
            query }
          });
        else dispatch({ type: "error", payload: error });

        dispatch({ type: "loading", payload: { query, loading: false } });
      })();
    },
    [currentQueryLastPage, dispatch, query]
  );

  const refresh = () => {
    dispatch({ type: "refresh" });
  };

  useEffect(() => {
    if (currentQuery && !currentQuery.endReached && currentQuery.owns.size === 0) {
      fetchNextPage();
    }
  }, [currentQuery, fetchNextPage, query]);

  const search = useCallback(
    async (searchString: string) => {
      dispatch({ type: "search", payload: searchString });
    },
    [dispatch]
  );

  const setFromMyTeams = (value: boolean) => dispatch({ type: "fromMyTeams", payload: value });
  const setRevisedFilter = (value: boolean | null) => dispatch({ type: "revised", payload: value });
  const setSelectedType = (value: { name: string; id: number } | null) =>
    dispatch({ type: "selectedType", payload: value });

  const isFilterActive =
    displayMode !== 0 ||
    currentMode.search.length > 0 ||
    selectedPeriod.fromDate !== null ||
    revised !== null ||
    fromMyTeams ||
    selectedType !== null;

  const clearFilters = function() {
    dispatch({ type: "clearFilters" });
  };

  const [showDrawer, setDrawer] = useState<boolean>(false);
  const toggleDrawer = function() {
    setDrawer(s => !s);
  };

  const sidebar = (
    <ProcMovementsSidebar
      setSelectedType={setSelectedType}
      selectedType={selectedType}
      procMovementsTypes={procMovementsTypes || []}
      revised={revised}
      setRevisedFilter={setRevisedFilter}
      fromMyTeams={fromMyTeams}
      setFromMyTeams={setFromMyTeams}
      clearFilters={clearFilters}
      isFilterActive={isFilterActive}
      displayMode={displayMode}
      selectedPeriod={selectedPeriod}
      setDrawer={setDrawer}
      setSelectedPeriod={(func: (period: Period) => Period) => {
        dispatch({ type: "selectedPeriod", payload: func(selectedPeriod) });
      }}
      setDisplayMode={(i: number) => dispatch({ type: "displayMode", payload: i })}
    />
  );
  const drawer = { showDrawer, setDrawer, toggleDrawer, drawerContent: sidebar };

  const displayingProcMovements = Object.values(fetched)
    .filter(c => {
      let show =
        currentMode.filterFunction(c) &&
        currentQuery.owns.has(c.id) &&
        (revised === null || c.revised === revised);

      if (show && currentMode.search.length > 0) {
        show = false;
        for (let searchField in c) {
          let value = c[searchField as keyof ProcMovementType] as null | string | number;
          show = show || compareWithoutDiacritics(currentMode.search, value);
        }
      }
      if (selectedPeriod.fromDate !== null && selectedPeriod.toDate !== null) {
        show =
          show &&
          c.date.getTime() <= selectedPeriod.toDate.getTime() &&
          c.date.getTime() >= selectedPeriod.fromDate.getTime();
      }
      return show;
    })
    .sort((a: ProcMovementType, b: ProcMovementType) => {
      if (order.field.name === "id") {
        const aMs = new Date(a.date).getTime();
        const bMs = new Date(b.date).getTime();

        if (order.mode === "ASC") {
          if (aMs === bMs) return a.id - b.id;
          return aMs - bMs;
        } else {
          if (aMs === bMs) return b.id - a.id;
          return bMs - aMs;
        }
      }
      return 0;
    });

  const updateProcMovement = useCallback(
    (id: number, updateFunction: (a: ProcMovementType) => ProcMovementType) => {
      const contact = updateFunction(fetched[id]);
      dispatch({ type: "updateProcMovement", payload: contact });
    },
    [dispatch, fetched]
  );

  const setOpenProcMovement = (id: number) => props.history.push("/andamentos/" + (id ? id : ""));

  return (
    <>
      <BackDrawer {...drawer} />
      {openProcMovementId ? (
        <ProcMovement
          procMovementId={openProcMovementId}
          procMovement={fetched[openProcMovementId]}
          openProcMovement={setOpenProcMovement}
          updateProcMovement={updateProcMovement}
        />
      ) : null}
      <div className={css.main}>
        <div className={css.contactToolbar} />
        <div className={css.contactMain}>
          <div className={css.contactSidebar}>{sidebar}</div>
          <div className={css.contactList}>
            <SearchList search={search} {...drawer} />
            <MainList<ProcMovementType, { openProcMovement: (id: number) => void }, OrderFieldNames>
              {...{
                endReached: currentQuery && currentQuery.endReached,
                loading: currentQuery && currentQuery.loading,
                refresh,
                currentSearch: currentMode.search,
                setOrder: func => dispatch({ type: "order", payload: func(order) }),
                order,
                orderFields,
                isFilterActive,
                clearFilters,
                contentArray: displayingProcMovements,
                ListComponent: ProcMovementsListItem,
                listComponentProps: {
                  openProcMovement: setOpenProcMovement
                },
                fetchNextPage: () => {
                  if (!currentQuery.loading && !currentQuery.endReached) fetchNextPage();
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
