import React, { useEffect, useCallback, useContext } from "react";
import { RouteComponentProps } from "react-router";
import css from "./Dashboard.module.css";
import apiCaller from "../apiCaller";
import { userContext } from "../Root";
import { State, Action, Chart, ListNames } from "./DashboardStore";
import Expense from "../Expenses/Expense/Expense";
import Timesheet from "../Timesheets/Timesheet/Timesheet";
import ProcMovement from "../ProcMovements/ProcMovement/ProcMovement";
import { normalizeDateIn } from "../../Utils/normalizeDateIn";
import ChartBox from "../../Components/ChartBox/ChartBox";
import InfoBox from "../../Components/InfoBox/InfoBox";
import InfoBoxBar from "../../Components/InfoBoxBar/InfoBoxBar";
import ClockDate from "../../Components/ClockDate/ClockDate";

export default Dashboard;

function Dashboard({
  dispatch,
  charts,
  initialized,
  collection,
  history,
  ...props
}: { dispatch: (a: Action) => void } & State & RouteComponentProps) {
  const idParam = (props.match.params as { id?: string }).id;
  const openDialogId = idParam !== undefined && !isNaN(+idParam) ? +idParam : undefined;
  // seleciona "andamentos" ou "honorarios" ou "despesas" na url
  const urlSubPath = props.match.url.split("/")[props.match.url.split("/").length - 2] as
    | "andamentos"
    | "honorarios"
    | "despesas";

  const { user } = useContext(userContext);
  const loadChart = useCallback(
    async (chart: Chart): Promise<void> => {
      dispatch({ type: "loading", payload: { value: true, name: chart.name } });
      const { error, data } = await apiCaller.get<any>("/dashboard/" + chart.name);
      if (error) {
        dispatch({
          type: "error",
          payload: { name: chart.name, value: error }
        });
      } else {
        dispatch({ type: chart.name, payload: data as any });
      }
      dispatch({ type: "loading", payload: { value: false, name: chart.name } });
    },
    [dispatch]
  );

  const loadList = useCallback(
    async (name: ListNames, listLength = 10): Promise<void> => {
      dispatch({ type: "loading", payload: { value: true, name: name } });

      const apiUrls: { expenses: string; timesheets: string; procMovements: string } = {
        timesheets: `timesheets?orderMode=DESC&executionLawyerPersonId=${
          user!.personId
        }&pageLength=${listLength}`,

        expenses: `/expenses/?orderMode=DESC&ownerPersonId=${
          user!.personId
        }&pageLength=${listLength}`,

        procMovements: `/procMovements?&orderMode=DESC&pageLength=${listLength}`
      };

      const { data }: any = await apiCaller.get<any>(apiUrls[name]);

      data.data.map((obj: typeof data) => normalizeDateIn<typeof data>(obj));
      if (!data) {
        dispatch({
          type: "error",
          payload: { name: name, value: "Não foi possível carregar a lista." }
        });
      } else {
        dispatch({ type: name, payload: data.data });
      }
      dispatch({ type: "loading", payload: { value: false, name: name } });
    },
    [user, dispatch]
  );

  const initDashboard = useCallback(async () => {
    const chartsPromises: Promise<void>[] = Object.values(charts).map(chart => loadChart(chart));
    const collectionPromises: Promise<void>[] = Object.keys(collection).map(c =>
      loadList(c as ListNames)
    );

    await Promise.all(chartsPromises);
    await Promise.all(collectionPromises);
    dispatch({ type: "initialized" });
  }, [dispatch, charts, loadChart, collection, loadList]);

  useEffect(() => {
    if (!initialized) {
      initDashboard();
    }
  }, [initDashboard, initialized]);

  // dashboard/despesas/:id
  const setOpenExpense = (id: number) => history.push("/dashboard/" + (id ? `despesas/${id}` : ""));
  const updateExpense = useCallback((id: number, updateFunction: (a: any) => any) => {}, []);
  const deleteExpense = useCallback((id: number) => {}, []);

  // dashboard/honorarios/:id
  const setOpenTimesheet = (id: number) =>
    history.push("/dashboard/" + (id ? `honorarios/${id}` : ""));
  const updateTimesheet = useCallback((id: number, updateFunction: (a: any) => any) => {}, []);
  const deleteTimesheet = useCallback((id: number) => {}, []);

  // dashboard/andamentos/:id
  const setOpenProcMovement = (id: number) =>
    history.push("/dashboard/" + (id ? `andamentos/${id}` : ""));
  const updateProcMovement = useCallback((id: number, updateFunction: (a: any) => any) => {}, []);
  const dialogComponent = () => {
    if (urlSubPath === "despesas" && openDialogId) {
      return (
        <Expense
          history={history}
          deleteExpense={deleteExpense}
          expenseId={openDialogId}
          expense={collection.expenses.list.filter(e => e.id === openDialogId)[0]}
          openExpense={setOpenExpense}
          updateExpense={updateExpense}
        />
      );
    } else if (urlSubPath === "honorarios" && openDialogId) {
      return (
        <Timesheet
          history={history}
          timesheetId={openDialogId}
          timesheet={collection.timesheets.list.filter(ts => ts.id === openDialogId)[0]}
          openTimesheet={setOpenTimesheet}
          updateTimesheet={updateTimesheet}
          deleteTimesheet={deleteTimesheet}
        />
      );
    } else if (urlSubPath === "andamentos" && openDialogId) {
      return (
        <ProcMovement
          procMovementId={openDialogId}
          procMovement={collection.procMovements.list.filter(pm => pm.id === openDialogId)[0]}
          openProcMovement={setOpenProcMovement}
          updateProcMovement={updateProcMovement}
        />
      );
    }
  };

  const chartError =
    charts.monthBalance.error || charts.revenuePerClient.error || charts.totalReceivedBills.error;

  const layoutWithChart = (
    <>
      <div className={css.dashboardMain}>
        <div className={css.dashboardContentLeft}>
          <div className={css.infoAreaMobile}>
            <ClockDate />
            <InfoBox />
          </div>
          <div className={css.chartsArea}>
            <ChartBox chart={charts.totalReceivedBills} reloadChart={loadChart} />
          </div>
          <div className={`${css.chartsArea} ${css.chartsMultiArea}`}>
            <ChartBox chart={charts.revenuePerClient} reloadChart={loadChart} />
            <ChartBox chart={charts.monthBalance} reloadChart={loadChart} />
          </div>
        </div>
        <div className={css.dashboardContentRight}>
          <div className={css.infoAreaDesktop}>
            <ClockDate />
            <InfoBox />
          </div>
          <div className={css.infoAreaBar}>
            <InfoBoxBar
              expenses={collection.expenses}
              timesheets={collection.timesheets}
              procMovements={collection.procMovements}
              reloadList={loadList}
              openExpense={setOpenExpense}
              openTimesheet={setOpenTimesheet}
              openProcMovement={setOpenProcMovement}
            />
          </div>
        </div>
      </div>
    </>
  );

  const layoutWithOutChart = (
    <>
      <div className={css.dashboardMain}>
        <div className={css.dashboardContentLeft}>
          <div className={css.infoAreaMobile}>
            <ClockDate />
            <InfoBox />
          </div>
          <div className={css.infoAreaBar}>
            <InfoBoxBar
              expenses={collection.expenses}
              timesheets={collection.timesheets}
              procMovements={collection.procMovements}
              reloadList={loadList}
              openExpense={setOpenExpense}
              openTimesheet={setOpenTimesheet}
              openProcMovement={setOpenProcMovement}
            />
          </div>
        </div>
        <div className={css.dashboardContentRight}>
          <div className={css.infoAreaDesktop}>
            <ClockDate />
            <InfoBox />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      {!!chartError ? layoutWithOutChart : layoutWithChart}
      {dialogComponent()}
    </>
  );
}
