import React, { ReactElement, ReactNode } from "react";
import css from "./AuthPage.module.css";
import logo from "../../Image/logo_sisea_azul.png";

export default LoginPage;

function LoginPage(props: Props): ReactElement {
  const { children } = props;
  return (
    <div className={css.authContainer}>
      <div className={css.authMain}>
        <img className={css.loginLogo} src={logo} alt="" />
        {children}
      </div>
      <div className={css.version}>v. 1.1.0</div>
    </div>
  );
}

interface Props {
  children: ReactNode;
}
