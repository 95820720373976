import {
  IDictionary,
  ProcMovementType,
  QueryPagination,
  DisplayMode,
  Period,
  Order,
  OrderField
} from "../../declarations";
import { startOfYesterday, endOfToday } from "date-fns/esm";

export const initialState: State = {
  selectedType: null,
  procMovementsTypes: null,
  revised: null,
  fromMyTeams: false,
  error: null,
  displayMode: 0,
  refreshing: 0,
  order: { mode: "DESC", field: { name: "id", label: "Data de lançamento" } },
  orderFields: [{ name: "id", label: "Data de lançamento" }],
  selectedPeriod: { fromDate: startOfYesterday(), toDate: endOfToday() },
  query: "/procMovements?",
  fetched: {},
  modes: [
    {
      label: "Todos",
      filterFunction: () => true,
      query: "",
      search: ""
    },
    {
      label: "Sites do tribunal",
      filterFunction: p => p.origin === -1,
      query: "originGroup=-1",
      search: ""
    },
    {
      label: "Diário Oficial",
      filterFunction: p => [1, 2, 3, 9].includes(p.origin),
      query: "originGroup=1,2,3,9",
      search: ""
    },
    {
      label: "Usuário",
      filterFunction: p => p.origin === 0,
      query: "originGroup=0",
      search: ""
    },
    {
      label: "Importação",
      filterFunction: p => [-3, -2].includes(p.origin),
      query: "originGroup=-3,-2",
      search: ""
    }
  ],
  queryPaginations: {}
};

export function reducer(state: State, action: Action): State {
  const currentMode = { ...state.modes[state.displayMode] };
  let newState = { ...state };
  if (action.type === "updateQuery") {
    let query = "/procMovements";
    if (currentMode.search) {
      query += "/search/" + currentMode.search;
    }
    query += "?" + currentMode.query;
    query += `&orderMode=${state.order.mode}`;
    query += `&orderField=${state.order.field.name}`;

    if (state.fromMyTeams) {
      query += "&fromUserTeams=true";
    }

    if (state.selectedType) {
      query += "&movementType=" + state.selectedType.name;
    }

    if (state.revised !== null) {
      query += "&revised=" + (state.revised ? "true" : "false");
    }

    if (state.selectedPeriod.fromDate !== null && state.selectedPeriod.toDate !== null)
      query += `&fromDate=${state.selectedPeriod.fromDate.toString()}&toDate=${state.selectedPeriod.toDate.toString()}`;

    newState.query = query;
    if (state.queryPaginations[query] === undefined) {
      newState.queryPaginations[query] = {
        owns: new Set(),
        loading: true,
        lastPage: 1,
        endReached: false
      };
    }
  }
  if (action.type === "fetchNextPage") {
    const { data, query } = action.payload;
    const dataObj: IDictionary<ProcMovementType> = {};
    const fetchedProcMovements = data;
    const fetchedQuery = query;
    const fetchQueryPagination = { ...state.queryPaginations[fetchedQuery] };

    fetchedProcMovements.forEach((c: ProcMovementType) => {
      c.date = new Date(c.date);
      dataObj[c.id] = c;
      fetchQueryPagination.owns.add(c.id);
    });

    fetchQueryPagination.lastPage++;
    if (fetchedProcMovements.length === 0) fetchQueryPagination.endReached = true;

    newState.fetched = { ...state.fetched, ...dataObj };
    newState.queryPaginations = {
      ...state.queryPaginations,
      [fetchedQuery]: fetchQueryPagination
    };
  }
  if (action.type === "loading") {
    const { query, loading } = action.payload;
    newState.queryPaginations[query].loading = loading;
  }
  if (action.type === "search") {
    newState.modes[state.displayMode].search = action.payload;
  }
  if (action.type === "clearFilters") {
    newState.displayMode = 0;
    newState.modes.forEach(mode => (mode.search = ""));
    newState.selectedPeriod = { fromDate: null, toDate: null };
    newState.selectedType = null;
    newState.fromMyTeams = false;
    newState.revised = null;
  }
  if (action.type === "displayMode") {
    newState.displayMode = action.payload;
  }
  if (action.type === "refresh") {
    newState.fetched = {};
    newState.queryPaginations = {};
    newState.refreshing++;
  }
  if (action.type === "order") {
    newState.order = action.payload;
  }
  if (action.type === "selectedPeriod") {
    const newPeriod = action.payload;
    if (newPeriod.fromDate !== null && newPeriod.toDate === null) {
      newPeriod.toDate = newPeriod.fromDate;
    }
    newState.selectedPeriod = action.payload;
  }
  if (action.type === "fromMyTeams") {
    newState.fromMyTeams = action.payload;
  }
  if (action.type === "revised") {
    newState.revised = action.payload;
  }
  if (action.type === "fetchTypes") {
    newState.procMovementsTypes = action.payload;
  }
  if (action.type === "selectedType") {
    newState.selectedType = action.payload;
  }
  if (action.type === "updateProcMovement") {
    newState.fetched[action.payload.id] = action.payload;
  }

  return newState;
}
export type Action =
  | SimpleAction
  | FetchNextPageAction
  | LoadingAction
  | OrderAction
  | SelectedPeriodAction
  | SearchAction
  | ErrorAction
  | DisplayModeAction
  | FromMyTeamsAction
  | RevisedFilterAction
  | FetchTypesAction
  | SelectedTypeAction
  | UpdateProcMovementAction;

type SimpleAction = {
  type: "updateQuery" | "clearFilters" | "refresh";
};

interface FetchNextPageAction {
  type: "fetchNextPage";
  payload: { data: ProcMovementType[]; query: string };
}
interface LoadingAction {
  type: "loading";
  payload: { query: string; loading: boolean };
}
interface SearchAction {
  type: "search";
  payload: string;
}
interface OrderAction {
  type: "order";
  payload: State["order"];
}
interface SelectedPeriodAction {
  type: "selectedPeriod";
  payload: Period;
}
interface ErrorAction {
  type: "error";
  payload: Error;
}
interface DisplayModeAction {
  type: "displayMode";
  payload: number;
}
interface FromMyTeamsAction {
  type: "fromMyTeams";
  payload: boolean;
}
interface RevisedFilterAction {
  type: "revised";
  payload: boolean | null;
}
interface FetchTypesAction {
  type: "fetchTypes";
  payload: { name: string; id: number }[];
}
interface SelectedTypeAction {
  type: "selectedType";
  payload: { name: string; id: number } | null;
}
interface UpdateProcMovementAction {
  type: "updateProcMovement";
  payload: ProcMovementType;
}

export interface State {
  selectedType: { name: string; id: number } | null;
  procMovementsTypes: { name: string; id: number }[] | null;
  revised: boolean | null;
  fromMyTeams: boolean;
  error: Error | null;
  displayMode: number;
  refreshing: number;
  order: Order<OrderFieldNames>;
  orderFields: OrderField<OrderFieldNames>[];
  selectedPeriod: Period;
  query: string;
  fetched: IDictionary<ProcMovementType>;
  modes: DisplayMode<ProcMovementType>[];
  queryPaginations: IDictionary<QueryPagination>;
}

export type OrderFieldNames = "id";
