import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    switchBase: {
      "& .MuiSwitch-colorPrimary.Mui-checked": {
        color: "#125ad3"
      },
      "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#125ad3"
      },
      "& .MuiSwitch-switchBase": {
        color: "#c6c6c6"
      }
    },

    listItemButton: {
      "&.Mui-selected": {
        backgroundColor: "rgba(0, 0, 0, 0.05)"
      }
    },
    listItemIcon: {
      minWidth: "40px"
    },
    itemIcon: {
      fontSize: "1.3rem"
    },
    iconTitle: {
      gridRow: "1",
      gridColumn: "1",
      alignSelf: "center",
      justifySelf: "center",
      marginRight: "10px",
      fontSize: "2rem",
      color: "#1a5dce",
      width: "0.9em",
      height: "0.9em"
    },

    sidebarInfList: {
      height: "calc(var(--vh, 1vh) * 100 - 374px)",
      padding: "0px"
    },

    button: {
      backgroundColor: "#125ad3",
      fontSize: "0.8rem",
      borderRadius: "0px",
      "&:hover": {
        backgroundColor: "#0039c4"
      }
    },
    iconFilter: {
      color: "#fff",
      fontSize: "1.2rem",
      padding: "0 5px"
    },
    subHeaderText: {
      gridRow: "1",
      gridColumn: "2",
      "& .MuiTypography-body1": {
        fontSize: "0.9rem",
        color: "#565656"
      }
    }
  })
);
