import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    tabs: {
      "& .MuiIcon-root": {
        width: "1.3em",
        fontSize: "1.1rem"
      },
      "& .MuiSvgIcon-root": {
        fontSize: "1.1rem",
        width: "1.3em"
      },
      "& .MuiTab-root": {
        fontSize: "0.6rem"
      },
      "& .MuiTab-labelIcon": {
        padding: "6px 12px",
        minHeight: "40px",
        flexGrow: 1
      },
      "& .MuiTab-textColorPrimary.Mui-selected": {
        color: "#125ad3"
      },
      "& .MuiTabs-indicator": {
        backgroundColor: "#125ad3",
        transition: "all 600ms cubic-bezier(0.4, 0, 0.2, 1) 100ms"
      },
      "& .MuiTabs-flexContainer": {
        justifyContent: "space-between"
      },
      "& .MuiTabs-scrollButtons": {
        width: "20px",
        color: "#125ad3",
        "& .MuiSvgIcon-root": {
          fontSize: "1.8rem"
        }
      }
    }
  })
);
