import React, { useState, ChangeEvent, SyntheticEvent, useContext } from "react";
import Title from "../../../Components/Title/Title";
import css from "./Login.module.css";
import { TextField, Checkbox, Button, InputAdornment, IconButton } from "@material-ui/core";
import { Link, RouteComponentProps } from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import apiCaller from "../../apiCaller";
import { userContext } from "../../Root";
import { ErrorOutlined } from "@material-ui/icons";
import makeStyles from "./Styles";

export default Login;

function Login(
  props: RouteComponentProps & { loading: boolean; setLoading: (newState: boolean) => void }
) {
  const [error, setError] = useState();
  const { setLoading } = props;
  const [state, setState] = useState<State>({
    username: "",
    password: "",
    remember: true,
    showPassword: false
  });

  const canBeSubmitted = () => {
    const { username, password } = state;
    return username.length > 0 && password.length > 0;
  };

  const { fetchUser } = useContext(userContext);

  const handleSubmit = async (e: SyntheticEvent<EventTarget>) => {
    e.preventDefault();
    setLoading(true);
    const { data, error } = await apiCaller.post("/auth", {
      username: state.username,
      password: state.password
    });
    if (error) {
      setError(error);
    } else {
      const { token } = data;
      apiCaller.setAuthToken(token, state.remember);
      await fetchUser();
      props.history.push("/dashboard");
      const popupEvt = (window as any).deferredPrompt;
      if (popupEvt !== undefined) popupEvt.prompt();
    }
    setLoading(false);
  };

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleChange = (field: keyof State) => (event: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [field]: event.target.value || event.target.checked });
  };

  const classes = makeStyles();

  return (
    <form className={css.loginForm} onSubmit={handleSubmit}>
      <Title className={css.titleLogin}>ACESSE A SUA CONTA</Title>
      <TextField
        autoComplete="off"
        autoFocus
        required
        className={`${css.formControl} ${classes.textField}`}
        type="username"
        label="Usuário"
        onChange={handleChange("username")}
      />
      <TextField
        autoComplete="off"
        required
        className={`${css.formControl} ${classes.textField}`}
        type={state.showPassword ? "text" : "Password"}
        label="Senha"
        onChange={handleChange("password")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                aria-label="Toggle password visibility"
                onClick={handleClickShowPassword}
              >
                {state.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      {error === "Invalid Credentials" ? (
        <div className={css.errorLogin}>
          <ErrorOutlined className={css.iconError} color="error" fontSize="small" />
          <p className={css.errorText}>usuário ou senha incorretos</p>
        </div>
      ) : (
        undefined
      )}
      {error === "Too Many Requests" ? (
        <div className={css.errorLogin}>
          <ErrorOutlined className={css.iconError} color="error" fontSize="small" />
          <p className={css.errorText}>
            limite de tentativas excedido, tente novamente em 30 segundos.
          </p>
        </div>
      ) : (
        undefined
      )}
      <div className={css.remember}>
        <div className={css.rememberLogin}>
          <Checkbox
            color="default"
            className={classes.checkBox}
            name="remember"
            checked={state.remember}
            onChange={handleChange("remember")}
          />
          <span className={css.rememberTitle}>Lembrar de mim</span>
        </div>

        <Link className={css.forgotPassword} to="/auth/recuperacao">
          Esqueceu a senha?
        </Link>
      </div>
      <Button
        type="submit"
        className={`${classes.button} ${css.loginBtn}`}
        variant="contained"
        color="primary"
        disabled={!canBeSubmitted()}
      >
        Entrar
      </Button>
    </form>
  );
}

interface State {
  username: string;
  password: string;
  remember: boolean;
  showPassword: boolean;
}
