import { makeStyles, createStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    item: {
      borderColor: "rgba(0, 0, 0, 0.12)",
      borderStyle: "solid",
      borderBottomWidth: "1px",
      display: "grid",
      paddingLeft: "0px",
      paddingRight: "8px",
      height: "90px"
    },

    icon: {
      color: "#125ad3"
    },

    iconDate: {
      color: "#737373",
      fontSize: "1.1rem"
    },
    checkBox: {
      "&.MuiCheckbox-colorPrimary.Mui-checked": {
        color: "#f2000a"
      }
    }
  })
);
