import React, { useState, useEffect, useCallback } from "react";
import { RouteComponentProps } from "react-router";
import css from "../Expenses/Expenses.module.css";
import apiCaller from "../apiCaller";
import { Period, ExpenseType, PersonBasic, Account } from "../../declarations";
import ExpensesListItem from "./ListItem/ExpensesListItem";
import { Action, State, OrderFieldNames } from "./ExpensesStore";
import Expense from "./Expense/Expense";
import ExpenseAdd from "./Add/ExpenseAdd";
import ExpensesSidebar from "./Sidebar/ExpensesSidebar";
import { compareWithoutDiacritics } from "../../Utils/compareWithoutDiacritics";
import { normalizeDateIn } from "../../Utils/normalizeDateIn";
import SearchList from "../../Components/SearchList/SearchList";
import MainList from "../../Components/MainList/MainList";
import BackDrawer from "../../Components/BackDrawer/BackDrawer";
import SpeedDialsAdd from "../../Components/SpeedDialsAdd/SpeedDialsAdd";

export default Expenses;

function Expenses({
  dispatch,
  fetched,
  query,
  displayMode,
  order,
  orderFields,
  refreshing,
  selectedPeriod,
  chargeable,
  history,
  expenseTypes,
  costCenters,
  selectedExpenseType,
  selectedCostCenter,
  selectedOwner,
  defaultSelectedOwner,
  charged,
  people,
  ...props
}: { dispatch: (a: Action) => void } & State & RouteComponentProps & { people: PersonBasic[] }) {
  const currentMode = props.modes[displayMode];
  const currentQuery = props.queryPaginations[query];
  const idParam = (props.match.params as { id?: string }).id;
  const openExpenseId = idParam !== undefined && !isNaN(+idParam) ? +idParam : undefined;

  useEffect(
    function() {
      (async function() {
        const { data: dataTypes } = await apiCaller.get<{ data: Account[] }>("/expenses/types");
        if (dataTypes) {
          dispatch({ type: "expenseTypes", payload: dataTypes.data });
        }
        const { data: dataCC } = await apiCaller.get<{ data: Account[] }>("/expenses/costCenters");
        if (dataCC) {
          dispatch({ type: "costCenters", payload: dataCC.data });
        }
      })();
    },
    [dispatch]
  );

  useEffect(
    function () {
      (async function () {
        const { data } = await apiCaller.get<any>(`/expenses`);
        setCanAdd(data.canAdd);


      })();
    },
    []
  );

  useEffect(
    function() {
      dispatch({ type: "updateQuery" });
    },
    [
      dispatch,
      currentMode.search,
      refreshing,
      order,
      selectedPeriod,
      displayMode,
      chargeable,
      charged,
      selectedExpenseType,
      selectedCostCenter,
      selectedOwner
    ]
  );

  let currentQueryLastPage = -1;
  if (currentQuery) currentQueryLastPage = currentQuery.lastPage;

  const fetchNextPage = useCallback(
    function() {
      dispatch({ type: "loading", payload: { query, loading: true } });
      (async function() {
        const { data, error } = await apiCaller.get<any>(
          query + `&pageLength=20&pageNumber=${currentQueryLastPage}`
        );
        if (!error)
          dispatch({
            type: "fetchNextPage",
            payload: {
              data: data.data.map((ex: ExpenseType) => normalizeDateIn<ExpenseType>(ex)),
              query
            }
          });
        else dispatch({ type: "error", payload: error });
        dispatch({ type: "loading", payload: { query, loading: false } });
      })();
    },
    [currentQueryLastPage, dispatch, query]
  );

  const refresh = () => {
    dispatch({ type: "refresh" });
  };

  useEffect(() => {
    if (currentQuery && !currentQuery.endReached && currentQuery.owns.size === 0) {
      fetchNextPage();
    }
  }, [currentQuery, fetchNextPage, query]);

  const search = useCallback(
    async (searchString: string) => {
      dispatch({ type: "search", payload: searchString });
    },
    [dispatch]
  );

  const setChargeableFilter = (value: boolean | null) =>
    dispatch({ type: "chargeable", payload: value });
  const setChargedFilter = (value: boolean | null) => dispatch({ type: "charged", payload: value });
  const setSelectedExpenseType = (value: Account | null) =>
    dispatch({ type: "expenseType", payload: value });
  const setSelectedCostCenter = (value: { title: string; id: number } | null) =>
    dispatch({ type: "costCenter", payload: value });
  const setSelectedOwner = (value: PersonBasic | null) =>
    dispatch({ type: "owner", payload: value });

  const isFilterActive =
    displayMode !== 0 ||
    currentMode.search.length > 0 ||
    selectedPeriod.fromDate !== null ||
    chargeable !== null ||
    selectedCostCenter !== null ||
    selectedExpenseType !== null ||
    selectedOwner !== defaultSelectedOwner;
  const clearFilters = function() {
    dispatch({ type: "clearFilters" });
  };

  const [showDrawer, setDrawer] = useState<boolean>(false);
  const toggleDrawer = function() {
    setDrawer(s => !s);
  };

  const sidebar = (
    <ExpensesSidebar
      people={people}
      setSelectedOwner={setSelectedOwner}
      selectedOwner={selectedOwner}
      selectedCostCenter={selectedCostCenter}
      setSelectedCostCenter={setSelectedCostCenter}
      costCenters={costCenters}
      setSelectedExpenseType={setSelectedExpenseType}
      selectedExpenseType={selectedExpenseType}
      expenseTypes={expenseTypes}
      chargeable={chargeable}
      setChargeable={setChargeableFilter}
      charged={charged}
      setCharged={setChargedFilter}
      clearFilters={clearFilters}
      isFilterActive={isFilterActive}
      displayMode={displayMode}
      selectedPeriod={selectedPeriod}
      setDrawer={setDrawer}
      setSelectedPeriod={(func: (period: Period) => Period) => {
        dispatch({ type: "selectedPeriod", payload: func(selectedPeriod) });
      }}
      setDisplayMode={(i: number) => dispatch({ type: "displayMode", payload: i })}
    />
  );
  const drawer = { showDrawer, setDrawer, toggleDrawer, drawerContent: sidebar };

  const searchFields: string[] = [
    "history",
    "clientName",
    "costCenterName",
    "operatedAccount.title",
    "operatorAccount.title",
    "process.title",
    "process.folderNumber"
  ];

  const displayingExpenses = Object.values(fetched)
    .filter(c => {
      let show =
        currentMode.filterFunction(c) &&
        currentQuery.owns.has(c.id) &&
        (selectedExpenseType === null || c.expenseAccount.id === selectedExpenseType.id) &&
        (selectedCostCenter === null || c.costCenter.id === selectedCostCenter.id) &&
        (chargeable === null || chargeable === c.chargeable);

      if (show && currentMode.search.length > 0) {
        show = false;
        for (let searchField of searchFields) {
          const propertyPath = searchField.split(".");

          const value: null | string | number = propertyPath.reduce(
            (current: any, key) => (current = current[key]),
            c
          );
          show = show || compareWithoutDiacritics(currentMode.search, value);
        }
      }
      if (selectedPeriod.fromDate !== null && selectedPeriod.toDate !== null) {
        show =
          show &&
          c.date.getTime() <= selectedPeriod.toDate.getTime() &&
          c.date.getTime() >= selectedPeriod.fromDate.getTime();
      }
      return show;
    })
    .sort((a: ExpenseType, b: ExpenseType) => {
      if (order.field.name === "id") {
        if (order.mode === "ASC") return a.id - b.id;
        else return b.id - a.id;
      } else if (order.field.name === "date") {
        const aMs = new Date(a.date).getTime();
        const bMs = new Date(b.date).getTime();

        if (order.mode === "ASC") {
          if (aMs === bMs) return a.id - b.id;
          return aMs - bMs;
        } else {
          if (aMs === bMs) return b.id - a.id;
          return bMs - aMs;
        }
      }
      return 0;
    });

  const updateExpense = useCallback(
    (id: number, updateFunction: (a: ExpenseType) => ExpenseType) => {
      const expense = updateFunction(fetched[id]);
      dispatch({ type: "updateExpense", payload: expense });
    },
    [dispatch, fetched]
  );
  const deleteExpense = useCallback(
    (id: number) => {
      dispatch({ type: "deleteExpense", payload: id });
    },
    [dispatch]
  );

  const [openAdd, setOpenAdd] = useState(false);
  const [canAdd, setCanAdd] = useState(false)

  const setOpenExpense = (id: number) => history.push("/despesas/" + (id ? id : ""));

  return (
    <>
      <BackDrawer {...drawer} />
      {openExpenseId ? (
        <Expense
          expenseTypes={expenseTypes}
          costCenters={costCenters}
          history={history}
          deleteExpense={deleteExpense}
          expenseId={openExpenseId}
          expense={fetched[openExpenseId]}
          openExpense={setOpenExpense}
          updateExpense={updateExpense}
        />
      ) : null}
      {openAdd ? (
        <ExpenseAdd
          costCenters={costCenters}
          expenseTypes={expenseTypes}
          history={history}
          openAdd={openAdd}
          setOpenAdd={setOpenAdd}
        />
      ) : null}
      <div className={css.main}>
        <div className={css.expenseToolbar} />
        <div className={css.expenseMain}>
          <div className={css.expenseSidebar}>{sidebar}</div>
          <div className={css.expenseList}>
            <SearchList search={search} {...drawer} />
            <MainList<ExpenseType, { openExpense: (id: number) => void }, OrderFieldNames>
              {...{
                endReached: currentQuery && currentQuery.endReached,
                loading: currentQuery && currentQuery.loading,
                refresh,
                currentSearch: currentMode.search,
                setOrder: func => dispatch({ type: "order", payload: func(order) }),
                order,
                orderFields,
                isFilterActive,
                clearFilters,
                contentArray: displayingExpenses,
                ListComponent: ExpensesListItem,
                listComponentProps: {
                  openExpense: setOpenExpense
                },
                fetchNextPage: () => {
                  if (!currentQuery.loading && !currentQuery.endReached) fetchNextPage();
                }
              }}
            />
            {costCenters && <SpeedDialsAdd canAdd={canAdd} openAdd={openAdd} setOpenAdd={setOpenAdd} />}
          </div>
        </div>
      </div>
    </>
  );
}
