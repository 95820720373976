import {
  IDictionary,
  QueryPagination,
  DisplayMode,
  ContactType,
  Order,
  OrderField
} from "../../declarations";

export const initialState: State = {
  includeInactives: false,
  error: null,
  displayMode: 0,
  refreshing: 0,
  order: { mode: "ASC", field: { name: "name", label: "Nome" } },
  orderFields: [{ name: "name", label: "Nome" }],
  startsWith: null,
  withContactInfo: true,
  query: "/contacts?",
  fetched: {},
  modes: [
    {
      label: "Todos",
      filterFunction: () => true,
      query: "",
      search: ""
    },
    {
      label: "Clientes",
      filterFunction: c => !!c.contactClass && c.contactClass.includes("C"),
      query: "contactClass=C",
      search: ""
    },
    {
      label: "Possíveis Clientes",
      filterFunction: c => !!c.contactClass && c.contactClass.includes("S"),
      query: "contactClass=S",
      search: ""
    },
    {
      label: "Advogados",
      filterFunction: c => c.contactClass.includes("A"),
      query: "contactClass=A",
      search: ""
    },
    {
      label: "Estagiários",
      filterFunction: c => c.contactClass.includes("E"),
      query: "contactClass=E",
      search: ""
    },
    {
      label: "Administrativos",
      filterFunction: c => c.contactClass.includes("U"),
      query: "contactClass=U",
      search: ""
    },
    {
      label: "Partes",
      filterFunction: c => c.contactClass.includes("P"),
      query: "contactClass=P",
      search: ""
    },
    {
      label: "Fornecedores",
      filterFunction: c => c.contactClass.includes("F"),
      query: "contactClass=F",
      search: ""
    },
    {
      label: "Correspondentes",
      filterFunction: c => c.contactClass.includes("D"),
      query: "contactClass=D",
      search: ""
    },
    {
      label: "Captadores",
      filterFunction: c => c.contactClass.includes("R"),
      query: "contactClass=R",
      search: ""
    },
    {
      label: "Outros",
      filterFunction: c => c.contactClass.includes("O"),
      query: "contactClass=O",
      search: ""
    }
  ] as DisplayMode<ContactType>[],
  queryPaginations: {}
};

export function reducer(state: State, action: Action): State {
  const currentMode = { ...state.modes[state.displayMode] };
  let newState = { ...state };
  if (action.type === "updateQuery") {
    let query = "/contacts";

    if (currentMode.search) {
      query += "/search/" + currentMode.search;
    }
    query += "?" + currentMode.query;

    query += `&orderMode=${state.order.mode}`;
    query += `&orderField=${state.order.field.name}`;

    if (state.startsWith !== null) {
      query += `&startsWith=${state.startsWith}`;
    }

    if (state.withContactInfo) {
      query += `&withContactInfo=true`;
    }

    if (!state.includeInactives) {
      query += "&status=false";
    }

    newState.query = query;
    if (state.queryPaginations[query] === undefined) {
      newState.queryPaginations[query] = {
        owns: new Set(),
        loading: true,
        lastPage: 1,
        endReached: false
      };
    }
  }
  if (action.type === "fetchNextPage") {
    const { data, query } = action.payload;
    const dataObj: IDictionary<ContactType> = {};
    const fetchedProcMovements = data;
    const fetchedQuery = query;
    const fetchQueryPagination = { ...state.queryPaginations[fetchedQuery] };

    fetchedProcMovements.forEach((c: ContactType) => {
      dataObj[c.id] = c;
      fetchQueryPagination.owns.add(c.id);
    });

    fetchQueryPagination.lastPage++;
    if (fetchedProcMovements.length === 0) fetchQueryPagination.endReached = true;

    newState.fetched = { ...state.fetched, ...dataObj };
    newState.queryPaginations = {
      ...state.queryPaginations,
      [fetchedQuery]: fetchQueryPagination
    };
  }
  if (action.type === "loading") {
    const { query, loading } = action.payload;
    newState.queryPaginations[query].loading = loading;
  }
  if (action.type === "search") {
    newState.modes[state.displayMode].search = action.payload;
  }
  if (action.type === "clearFilters") {
    newState.displayMode = 0;
    newState.modes.forEach(mode => (mode.search = ""));
    newState.startsWith = null;
    newState.withContactInfo = false;
    newState.includeInactives = true;
  }
  if (action.type === "displayMode") {
    newState.displayMode = action.payload;
  }
  if (action.type === "refresh") {
    newState.fetched = {};
    newState.queryPaginations = {};
    newState.refreshing++;
  }
  if (action.type === "order") {
    newState.order = action.payload;
  }
  if (action.type === "withContactInfo") {
    newState.withContactInfo = action.payload;
  }
  if (action.type === "startsWith") {
    newState.startsWith = action.payload;
  }
  if (action.type === "updateContact") {
    const contact = action.payload;
    newState.fetched[contact.id] = contact;
  }
  if (action.type === "includeInactives") {
    newState.includeInactives = action.payload;
  }

  return newState;
}
export type Action =
  | SimpleAction
  | FetchNextPageAction
  | LoadingAction
  | OrderAction
  | SearchAction
  | ErrorAction
  | DisplayModeAction
  | UpdateContactAction
  | StartsWithAction
  | WithContactInfoAction
  | IncludeInactivesAction;

type SimpleAction = {
  type: "updateQuery" | "clearFilters" | "refresh";
};

interface FetchNextPageAction {
  type: "fetchNextPage";
  payload: { data: ContactType[]; query: string };
}
interface LoadingAction {
  type: "loading";
  payload: { query: string; loading: boolean };
}
interface SearchAction {
  type: "search";
  payload: string;
}
interface OrderAction {
  type: "order";
  payload: State["order"];
}
interface ErrorAction {
  type: "error";
  payload: Error;
}
interface DisplayModeAction {
  type: "displayMode";
  payload: number;
}
interface UpdateContactAction {
  type: "updateContact";
  payload: ContactType;
}
interface StartsWithAction {
  type: "startsWith";
  payload: string | null;
}
interface WithContactInfoAction {
  type: "withContactInfo";
  payload: boolean;
}
interface IncludeInactivesAction {
  type: "includeInactives";
  payload: boolean;
}

export interface State {
  includeInactives: boolean;
  error: Error | null;
  displayMode: number;
  refreshing: number;
  order: Order<OrderFieldNames>;
  orderFields: OrderField<OrderFieldNames>[];
  withContactInfo: boolean;
  startsWith: string | null;
  query: string;
  fetched: IDictionary<ContactType>;
  modes: DisplayMode<ContactType>[];
  queryPaginations: IDictionary<QueryPagination>;
}

export type OrderFieldNames = "name";
