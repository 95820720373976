import React from "react";
import css from "./InfoBoxBar.module.css";
import { ExpenseType, TimesheetType, ProcMovementType } from "../../declarations";
import { List, ListItem, Icon, IconButton, CircularProgress } from "@material-ui/core";
import { FolderOutlined, TimerOutlined, RefreshOutlined } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import makeStyles from "./Styles";
import moment from "moment";
import "moment/locale/pt-br";
import { ListNames, ListData } from "../../Root/Dashboard/DashboardStore";
import { sortObjectDate } from "../../Utils/sortObjectDate";

export default InfoBoxBar;

moment.locale("pt-br");

function InfoBoxBar({ expenses, timesheets, procMovements, reloadList, ...props }: Props) {
  const classes = makeStyles();

  const formatDate = {
    dayMonth: (date: Date) : string => moment(date).format("D MMM")
    ,

    year: (date: Date): JSX.Element | null => {
      const currentYear = new Date().getFullYear();
      const otherYears = new Date(date).getFullYear();
      const year = moment(date).format("YYYY");

      return currentYear !== otherYears ? (
        <div className={css.itemDateYear}>
          <div className={css.itemYear}>{year}</div>
        </div>
      ) : null;
    }
  };

  const skeletonList = (
    <div className={css.boxSkeleton}>
      <Skeleton className={css.sklDate} variant="rect" width={50} height={55} />
      <div className={css.sklInfo}>
        <Skeleton className={`${classes.sklText}`} variant="text" height={12} width={240} />
        <Skeleton className={`${classes.sklText}`} variant="text" height={12} width={180} />
      </div>
    </div>
  );

  const timesheetList = sortObjectDate<TimesheetType>(timesheets.list).map(timesheet => {
    return (
      <ListItem
        key={timesheet.id}
        onClick={() => props.openTimesheet(timesheet.id)}
        className={`${classes.item} ${css.listItemTimesheet}`}
        button
      >
        <div className={css.itemDate}>
          <div className={css.itemDayMonth}>{formatDate.dayMonth(timesheet.date)}</div>
          {formatDate.year(timesheet.date)}
        </div>
        <div className={css.itemTime}>
          <div className={css.itemTimeSpent}>
            <TimerOutlined className={classes.iconTime} />
          </div>
          <div className={css.itemTimeTitle}>
            <div className={css.timeTitle}>{timesheet.timeSpent}</div>
          </div>
        </div>
        <div className={`${css.itemInfo} ${css.itemInfoTimesheet}`}>
          <div className={css.itemDesc}>{timesheet.discrimination}</div>
          <div title="Pasta/Caso" className={css.itemFolderIcon}>
            <FolderOutlined className={classes.iconInfo} />
          </div>
          <div className={css.itemFolderNumber}>
            {timesheet.process.folderNumber}
            {timesheet.process.title ? `- ${timesheet.process.title}` : null}
          </div>
        </div>
        {timesheet.status === "F" ? (
          <div title="Faturado" className={css.itemStatus}>
            <div className={css.itemIconStatus}>
              <Icon className={`fas fa-file-invoice-dollar ${classes.iconStatus}`} />
            </div>
          </div>
        ) : null}
      </ListItem>
    );
  });

  const expenseList = sortObjectDate<ExpenseType>(expenses.list).map(expense => {
    return (
      <ListItem
        key={expense.id}
        onClick={() => props.openExpense(expense.id)}
        className={`${classes.item}`}
        button
      >
        <div className={`${css.itemDate} ${css.expenseColor} `}>
          <div className={css.itemDayMonth}>{formatDate.dayMonth(expense.date)}</div>
          {formatDate.year(expense.date)}
        </div>
        <div className={css.itemInfo}>
          <div title="Tipo de despesa" className={css.itemExpenseAccount}>
            <Icon className={`fas fa-receipt ${classes.iconInfo}`} />
          </div>
          <div className={css.expenseAccountTitle}>{expense.expenseAccount.title}</div>
          <div title="Pasta/Caso" className={css.itemFolder}>
            <FolderOutlined className={classes.iconInfo} />
          </div>
          <div className={css.itemFolderNumber}>
            {expense.process.folderNumber}
            {expense.process.title ? `- ${expense.process.title}` : null}
          </div>
        </div>
        {expense.status === "F" ? (
          <div title="Faturado" className={css.itemStatus}>
            <div className={css.itemIconStatus}>
              <Icon className={`fas fa-file-invoice-dollar ${classes.iconStatus}`} />
            </div>
          </div>
        ) : null}
      </ListItem>
    );
  });

  const procMovementsList = sortObjectDate<ProcMovementType>(procMovements.list).map(
    (procMovement, i) => {
      return (
        <ListItem
          key={procMovement.id}
          onClick={() => props.openProcMovement(procMovement.id)}
          className={`${classes.item}`}
          button
        >
          <div className={`${css.itemDate} ${css.procMovementColor} `}>
            <div className={css.itemDayMonth}>{formatDate.dayMonth(procMovement.date)}</div>
            {formatDate.year(procMovement.date)}
          </div>

          <div className={css.itemInfo}>
            <div className={css.itemProcess}>{procMovement.processNumber}</div>
            <div className={`${css.itemDesc} ${css.movementDesc}`}>{procMovement.description}</div>
            {/* <div className={css.itemClientIcon}>
            <ContactsOutlined className={classes.iconInfo} />
          </div>
          <div className={css.itemClientName}>{procMovement.client}</div> */}
          </div>
          <div className={css.listItemTools}>
            {procMovement.origin > 0 ? (
              <>
                <Icon
                  title="Publicação Diario Oficial"
                  className={`far fa-newspaper ${classes.icon}`}
                />
                <span className={css.originTitle}>Diário</span>
              </>
            ) : null}
          </div>
        </ListItem>
      );
    }
  );

  return (
    <>
      <div id={"boxTimesheets"} className={css.boxTimesheets}>
        <div className={css.boxHeader}>
          <div className={css.boxTitle}>
            Honorários <span style={{ fontSize: "0.8rem", fontWeight: 400 }}> (últimos 10)</span>
          </div>
          <div title="Atualizar" className={css.refresh}>
            <IconButton className={classes.iconButton} onClick={() => reloadList("timesheets")}>
              <RefreshOutlined />
            </IconButton>
          </div>
        </div>
        {timesheets.loading ? (
          skeletonList
        ) : timesheets.list.length === 0 ? (
          <div className={css.boxNotice}>Não foram encontrados resultados</div>
        ) : (
          <List className={classes.list}>{timesheetList}</List>
        )}
      </div>
      <div id={"boxExpenses"} className={css.boxExpenses}>
        <div className={css.boxHeader}>
          <div className={css.boxTitle}>
            Despesas <span style={{ fontSize: "0.8rem", fontWeight: 400 }}> (últimos 10)</span>
          </div>
          <div title="Atualizar" className={css.refresh}>
            <IconButton className={classes.iconButton} onClick={() => reloadList("expenses")}>
              <RefreshOutlined />
            </IconButton>
          </div>
        </div>
        {expenses.loading ? (
          skeletonList
        ) : expenses.list.length === 0 ? (
          <div className={css.boxNotice}>Não foram encontrados resultados</div>
        ) : (
          <List className={classes.list}>{expenseList}</List>
        )}
      </div>
      <div id={"boxProcMovements"} className={css.boxProcMovements}>
        <div className={css.boxHeader}>
          <div className={css.boxTitle}>
            Andamentos <span style={{ fontSize: "0.8rem", fontWeight: 400 }}> (últimos 10)</span>
          </div>
          <div title="Atualizar" className={css.refresh}>
            <IconButton className={classes.iconButton} onClick={() => reloadList("procMovements")}>
              <RefreshOutlined />
            </IconButton>
          </div>
        </div>
        {procMovements.loading ? (
          skeletonList
        ) : procMovements.list.length === 0 ? (
          <div className={css.boxNotice}>Não foram encontrados resultados</div>
        ) : (
          <List className={classes.list}>{procMovementsList}</List>
        )}
      </div>
    </>
  );
}

interface Props {
  openProcMovement: (id: number) => void;
  openTimesheet: (id: number) => void;
  openExpense: (id: number) => void;
  timesheets: ListData<TimesheetType>;
  expenses: ListData<ExpenseType>;
  procMovements: ListData<ProcMovementType>;
  reloadList: (actionName: ListNames) => Promise<void>;
}
