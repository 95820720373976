import React, { ReactElement } from "react";
import css from "./NotFound.module.css";
import { Link } from "react-router-dom";

export default NotFound;

function NotFound(): ReactElement {
  return (
    <div className={css.content}>
      <div className={css.error}>
        <div className={css.errorTitle}>Ops... erro 404</div>
        <div className={css.errorMsg}>A página que está tentando acessar não existe.</div>
        <Link className={css.goLogin} to="/auth/Login">
          Ir para Login
        </Link>
      </div>
    </div>
  );
}
