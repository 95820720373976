import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Divider,
  LinearProgress,
  TextField,
  Switch,
  useMediaQuery,
  useTheme,
  IconButton,
  Icon,
  DialogActions,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import {
  CloseOutlined,
  EventOutlined,
  FolderOutlined,
  ContactsOutlined,
  NotesOutlined,
  AttachMoneyOutlined,
  SearchOutlined,
  TimerOutlined,
  ErrorOutlineOutlined,
  ExpandMoreOutlined
} from "@material-ui/icons/";
import css from "./TimesheetEdit.module.css";
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import apiCaller from "../../apiCaller";
import { RouteComponentProps } from "react-router";
import ClientSearch from "../../../Components/ClientSearch/ClientSearch";
import { CaseType, TimesheetType } from "../../../declarations";
import makeStyles from "./Styles";
import { formatCurrency } from "../../../Utils/fieldFormat";
import moment from "moment";
import { RecentCases } from "../../../Components/RecentCases/RecentCases";

export default TimesheetEdit;

function TimesheetEdit(props: Props) {
  const { openEdit, setOpenEdit, timesheet, updateTimesheet } = props;
  const [caseNumber, setCaseNumber] = useState<null | string>();
  const [selectedCase, setSelectedCase] = useState<null | CaseType>(null);
  const [error, setError] = useState<{ open: boolean; message: string }>({
    open: false,
    message: ""
  });
  const [loading, setLoading] = useState(false);

  const formatedStartTime = moment(timesheet.startTime, "hhmm").format("HH:mm");
  const newStartTime = new Date(`01/01/2000 ${formatedStartTime}`);

  const formatedEndTime = moment(timesheet.endTime, "hhmm").format("HH:mm");
  const newEndTime = new Date(`01/01/2000 ${formatedEndTime}`);

  const [state, setState] = useState<State>({
    date: timesheet.date,
    startTime: newStartTime,
    endTime: newEndTime,
    timeSpent: timesheet.timeSpent,
    chargeable: timesheet.chargeable,
    discrimination: timesheet.discrimination,
    value: timesheet.value.toString(),
    contract: false,
    tableValue: false
  });

  const [questionDialog, setQuestionDialog] = useState({
    open: false,
    loading: false,
    message: ""
  });

  type FieldName = keyof State;
  const [modified, setModified] = useState<Set<FieldName>>(new Set());

  const handleTimeSpentChange = (value: number) => {
    if (Number.isNaN(+value)) return;
    value = Math.trunc(value);
    setState(s => {
      return {
        ...s,
        timeSpent: value,
        endTime:
          s.startTime !== null ? new Date(s.startTime.getTime() + value * 60 * 1000) : s.endTime
      };
    });
  };

  useEffect(() => {
    setChargeInfo(null);
    setLoading(true);
    (async () => {
      const { data, error } = await apiCaller.get<{ case: CaseType }>(
        "/cases/caseByNumber?number=" + timesheet.process.folderNumber
      );
      if (data) {
        setSelectedCase(data.case);
      } else {
        if (error === "Not Found") setError({ message: "Caso não encontrado", open: true });
        if (error === "Unauthorized")
          setError({ message: "Você não tem permissão neste caso.", open: true });
      }
    })();
    setLoading(false);
  }, []);

  const [chargeInfo, setChargeInfo] = useState<{
    tabledService: boolean;
    valueType: 0 | 1 | 2 | 3;
    contractId: number;
    tableId: number;
    feeId: number;
    hourValue: number;
  } | null>(null);

  useEffect(() => {
    if (selectedCase !== null)
      (async function getChargeInfo() {
        const { data, error } = await apiCaller.get<any>("/cases/chargeInfo/" + selectedCase!.id);
        if (data) {
          setState(s => ({ ...s, chargeable: true, contract: false, tableValue: false }));
          if (data.contractId === 0) setState(s => ({ ...s, chargeable: false, contract: true }));
          if (data.valueType === 0) setState(s => ({ ...s, chargeable: false, contract: false }));
          if (
            data.valueType !== 0 &&
            data.contractId > 0 &&
            (data.hourValue === null || data.hourValue === 0)
          )
            setError({
              message: "Tarifa de cobrança para o seu usuário está zerada",
              open: true
            });
          if (data.valueType === 2) {
            setError({
              message:
                "O lançamento de honorários para casos com contratos de tabela variável ainda não está disponível",
              open: true
            });
            setSelectedCase(null);
            setChargeInfo(null);
          }
        }
        if (!data) setState(s => ({ ...s, chargeable: false, contract: false }));
        setChargeInfo(data);
      })();
  }, [selectedCase]);

  const isValueValid = !Number.isNaN(+state.value);

  async function saveUpdate() {
    setLoading(true);
    const { error } = await apiCaller.patch<any>(`/timesheets/${timesheet.id}`, {
      ...state,
      caseId: selectedCase!.id,
      date: state.date,
      startTime: state.startTime !== null ? "" + moment(state.startTime).format("HHmm") : null,
      endTime: state.endTime !== null ? "" + moment(state.endTime).format("HHmm") : null,
      chargeable: state.chargeable,
      timeSpent: state.timeSpent,
      timeCharged: state.timeSpent,
      value: state.value,
      discrimination: state.discrimination
    });

    if (error) {
      setError({ message: "Erro ao criar o honorário", open: true });
    } else {
      updateTimesheet(timesheet.id, () => ({
        ...timesheet,
        ...state,
        startTime: state.startTime !== null ? state.startTime!.toDateString() : "",
        endTime: state.endTime !== null ? state.endTime!.toDateString() : ""
      }));
    }
    setLoading(false);
    setOpenEdit(false);
  }

  const classes = makeStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  function handleDateChange(date: Date, field: "date" | "startTime" | "endTime") {
    setState(s => {
      if (field === "startTime") {
        if (state.endTime === null) s.endTime = date;
        else if (date.getTime() > (state.endTime as Date).getTime()) {
          s.endTime = date;
        }
      } else if (field === "endTime" && date.getTime() < (state.startTime as Date).getTime()) {
        s.startTime = date;
      }
      s = { ...s, [field]: date };

      s.timeSpent =
        s.endTime !== null && s.startTime !== null
          ? Math.trunc(
              ((s.endTime as Date).getTime() - (s.startTime as Date).getTime()) / (1000 * 60)
            )
          : 0;

      return s;
    });
  }
  useEffect(() => {
    if (chargeInfo !== null && chargeInfo.valueType === 1) {
      setState(s => ({ ...s, value: (chargeInfo.hourValue * (state.timeSpent / 60)).toFixed(2) }));
    }
  }, [chargeInfo, state.timeSpent]);

  let dialogContent;

  if ((loading && selectedCase === null) || chargeInfo === null) {
    dialogContent = <LinearProgress variant="query" className={classes.loading} />;
  } else {
    dialogContent = (
      <DialogContent className={classes.dialogContent}>
        <div className={css.panelSup}>
          <div title="Cliente" className={css.client}>
            <ContactsOutlined className={classes.icon} />
            <div className={css.clientName}>{selectedCase!.client.contactName}</div>
          </div>
          <div title="Pasta/Caso" className={css.folder}>
            <FolderOutlined className={classes.icon} />
            <div className={css.folderNumber}>
              {selectedCase!.title}
              {state.contract === true ? (
                <span style={{ color: "#e20137" }}> (sem contrato vinculado)</span>
              ) : null}
            </div>
          </div>
        </div>
        <div className={css.panelInf}>
          <div title="Data do honorário" className={css.itemDate}>
            <EventOutlined className={classes.icon} />
            <div className={css.content}>
              <div className={css.label}>Data do honorário</div>
              <div className={css.date}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                  <DatePicker
                    DialogProps={{ className: classes.datePicker }}
                    showTodayButton={true}
                    todayLabel="Hoje"
                    cancelLabel="Cancelar"
                    invalidLabel="Data inválida"
                    invalidDateMessage="Data inválida"
                    className={`${classes.pickerStart} ${css.startDate}}`}
                    format="EEE', 'd 'de' MMMM 'de' yyyy"
                    margin="normal"
                    value={state.date}
                    onChange={date => handleDateChange(date as Date, "date")}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
          <Divider />
          <div title="Tempo" className={css.itemDate}>
            <TimerOutlined className={classes.icon} />
            <div className={css.contentTime}>
              <div className={css.labelTime}>Tempo</div>
              <div title="Horário" className={css.time}>
                <div className={css.label}>Horário</div>
                <div className={css.timeHour}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                    <TimePicker
                      DialogProps={{ className: classes.datePicker }}
                      ampm={false}
                      cancelLabel="Cancelar"
                      label=""
                      value={state.startTime}
                      className={`${classes.timeStart} ${css.startDate}}`}
                      onChange={date => handleDateChange(date as Date, "startTime")}
                    />
                    <TimePicker
                      disabled={state.startTime === null}
                      DialogProps={{ className: classes.datePicker }}
                      ampm={false}
                      cancelLabel="Cancelar"
                      label=""
                      value={state.endTime}
                      className={`${classes.timeEnd} ${css.endDate}}`}
                      onChange={date => handleDateChange(date as Date, "endTime")}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div title="Tempo total do honorário" className={css.contentTotalTime}>
                <div className={css.label}>Tempo total</div>
                <div className={css.totalTime}>
                  <TextField
                    inputProps={{ maxLength: 6 }}
                    placeholder="Total em minutos"
                    className={`${classes.textField} ${classes.totalTime}`}
                    value={state.timeSpent}
                    onChange={e => handleTimeSpentChange(+e.target.value)}
                  />
                </div>
              </div>
              <div title="Adicionar minutos ao tempo" className={css.timeSelect}>
                <div className={css.label}>Mais minutos</div>
                <IconButton
                  className={classes.addTimeIcon}
                  title="5 minutos"
                  onClick={() => handleTimeSpentChange(state.timeSpent + 5)}
                >
                  <div className={css.titleIconTime}>5</div>
                </IconButton>
                <IconButton
                  className={classes.addTimeIcon}
                  title="10 minutos"
                  onClick={() => handleTimeSpentChange(state.timeSpent + 10)}
                >
                  <div className={css.titleIconTime}>10</div>
                </IconButton>
                <IconButton
                  className={classes.addTimeIcon}
                  title="30 minutos"
                  onClick={() => handleTimeSpentChange(state.timeSpent + 30)}
                >
                  <div className={css.titleIconTime}>30</div>
                </IconButton>
                <IconButton
                  className={classes.addTimeIcon}
                  title="60 minutos"
                  onClick={() => handleTimeSpentChange(state.timeSpent + 60)}
                >
                  <div className={css.titleIconTime}>60</div>
                </IconButton>
              </div>
              <div
                title="Restaurar"
                onClick={() =>
                  setState(s => {
                    return { ...s, startTime: null, endTime: null, timeSpent: 0 };
                  })
                }
                className={css.timeReset}
              >
                <Icon className={`fas fa-redo ${classes.iconFont}`} />
                <div className={css.titleIconReset}>Limpar tempo</div>
              </div>
            </div>
          </div>
          <Divider />
          <div title="Discriminação" className={css.item}>
            <NotesOutlined className={classes.icon} />
            <div className={css.content}>
              <div className={css.label}>Discriminação</div>
              <div className={css.discrimination}>
                <TextField
                  fullWidth
                  placeholder="Detalhe sobre este honorário"
                  required
                  multiline
                  className={classes.textFieldMultiLine}
                  onChange={e => {
                    e.persist();
                    setState(s => ({ ...s, discrimination: e.target.value }));
                  }}
                  value={state.discrimination}
                />
              </div>
            </div>
          </div>
          <Divider />
          <div title="Valor total do honorário" className={css.itemValue}>
            <AttachMoneyOutlined className={classes.icon} />
            <div className={css.content}>
              <div className={css.label}>Valor Total</div>
              <div className={css.value}>
                <TextField
                  disabled={chargeInfo.valueType !== 3}
                  inputProps={{ maxLength: 18 }}
                  placeholder="Digite o valor"
                  className={`${classes.textField} ${classes.totalValue}`}
                  onChange={e => {
                    e.persist();
                    setState(s => ({
                      ...s,
                      value: formatCurrency(e.target.value)!.toFixed(2)
                    }));
                  }}
                  value={state.value}
                />
              </div>
            </div>
            <div className={css.itemChargeable}>
              <div className={css.labelChargeable}>Faturável</div>
              <div className={css.chargeable}>
                <Switch
                  checked={state.chargeable}
                  onClick={() => setState(s => ({ ...s, chargeable: !state.chargeable }))}
                  value={state.chargeable}
                  color="primary"
                  className={classes.switchBase}
                />
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    );
  }

  return (
    <Dialog
      className={classes.dialog}
      open={openEdit}
      onClose={() => setOpenEdit(false)}
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle className={classes.dialogTitle} id="responsive-dialog-title">
        <div className={css.header}>
          <IconButton
            className={classes.closeIcon}
            title="Fechar janela"
            onClick={() => setOpenEdit(false)}
          >
            <CloseOutlined />
          </IconButton>
          <Button
            disabled={!isValueValid || !selectedCase || !chargeInfo || chargeInfo.valueType === 2}
            className={classes.saveButton}
            onClick={
              state.value === "0.00"
                ? () => {
                    setQuestionDialog(s => ({ ...s, open: true }));
                  }
                : saveUpdate
            }
            variant="contained"
            color="primary"
            autoFocus
            size="small"
          >
            Salvar
          </Button>
        </div>
        <div className={css.searchHeader}></div>
      </DialogTitle>
      {dialogContent}
      <Dialog open={error.open} onClose={() => setError(e => ({ ...e, open: false }))}>
        <ErrorOutlineOutlined className={classes.iconAlert} />
        <DialogContent className={classes.dialogContentAlert}>
          <div className={css.dialogTitle}>{error.message}</div>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.dialogButton}
            onClick={() => setError(e => ({ ...e, open: false }))}
            color="primary"
            autoFocus
            size="small"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        className={classes.dialog}
        open={questionDialog.open}
        onClose={() => setQuestionDialog(s => ({ ...s, open: false }))}
      >
        {questionDialog.loading ? <LinearProgress className={classes.loading} /> : null}
        <ErrorOutlineOutlined className={classes.iconAlert} />
        <DialogContent>
          <div className={css.dialogTitle}>
            Valor do serviço <span style={{ fontWeight: 600 }}>ZERADO</span>. Deseja salvar mesmo
            assim?
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.dialogButton}
            onClick={saveUpdate}
            color="primary"
            autoFocus
            size="small"
          >
            Salvar
          </Button>
          <Button
            className={classes.dialogButton}
            onClick={() => {
              setQuestionDialog(s => ({ ...s, open: false }));
            }}
            color="primary"
            autoFocus
            size="small"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
}

interface Props {
  openEdit: boolean;
  setOpenEdit: (s: boolean) => void;
  timesheet: TimesheetType;
  updateTimesheet: (id: number, updateFunction: (a: TimesheetType) => TimesheetType) => void;
  focusNotes: boolean;
}

interface State {
  date: Date;
  startTime: Date | null;
  endTime: Date | null;
  timeSpent: number;
  chargeable: boolean;
  discrimination: string;
  value: string | number;
  contract: boolean;
  tableValue: boolean;
}
