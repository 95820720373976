import React, { useState, Fragment, useEffect } from "react";
import {
  Dialog,
  LinearProgress,
  DialogTitle,
  IconButton,
  DialogContent,
  Divider,
  Button,
  Icon,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import { ProcMovementType } from "../../../declarations";
import {
  CloseOutlined,
  FolderOutlined,
  ContactsOutlined,
  NotesOutlined,
  AssignmentOutlined
} from "@material-ui/icons";
import css from "./ProcMovement.module.css";
import moment from "moment";
import "moment/locale/pt-br";
import apiCaller from "../../apiCaller";
import makeStyles from "./Styles";

export default ProcMovement;

moment.locale("pt-br");

function ProcMovement({
  procMovement,
  procMovementId,
  openProcMovement,
  updateProcMovement
}: {
  procMovementId: number;
  procMovement?: ProcMovementType;
  openProcMovement: (state: number) => void;
  updateProcMovement: (
    id: number,
    updateFunction: (a: ProcMovementType) => ProcMovementType
  ) => void;
}) {
  const [loading, setLoading] = useState(false);
  const [expandNotes, setExpandNotes] = useState(false);
  const [expandDescription, setExpandDescription] = useState(false);

  useEffect(
    function() {
      (async function() {
        setLoading(true);
        const { data } = await apiCaller.get<any>(`/procMovements/${procMovementId}`);
        if (data) {
          //data.procMovement.date = new Date(data.procMovement.date);
          updateProcMovement(data.procMovement.id, () => data.procMovement);
        }
        setLoading(false);
      })();
    },
    [procMovementId, updateProcMovement]
  );

  const classes = makeStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  let dialogContent: JSX.Element | null = null;

  if (procMovement) {


    const newDate = procMovement.date

    const currentYear = moment().format("YYYY")

    const movementDayMonth = moment(newDate).format("D MMM")
    const year = moment(newDate).format("YYYY")

    const movementYear =
      currentYear !== year ? (
        <div className={css.itemDateYear}>
          <div className={css.itemYear}>{year}</div>
        </div>
      ) : null;

    const revisionDate =
      !procMovement.revised || procMovement.revisionDate === null
        ? "Não revisado"
        : moment(procMovement.revisionDate).format("D [de] MMMM [de] YYYY")

    const originNumber = procMovement.origin;

    const movementOrigin = () => {
      if (originNumber > 0) {
        return "Diário Oficial";
      }

      if (originNumber === -1 || originNumber === null) {
        return "Site do Tribunal";
      }

      if (originNumber === 0) {
        return "Usuário";
      }

      if (originNumber > -1) {
        return "Importação";
      }
    };

    const procMovementNotes = (
      <Fragment>
        {procMovement.notes && procMovement.notes.length > 300 && !expandNotes
          ? procMovement.notes.slice(0, 300)
          : procMovement.notes}
        {procMovement.notes && procMovement.notes.length > 300 ? (
          <Button onClick={() => setExpandNotes(s => !s)} className={classes.button}>
            Ler {expandNotes ? "menos" : "mais"}...
          </Button>
        ) : null}
      </Fragment>
    );

    const procMovementDescription = (
      <Fragment>
        {procMovement.description && procMovement.description.length > 300 && !expandDescription
          ? procMovement.description.slice(0, 300)
          : procMovement.description}
        {procMovement.description && procMovement.description.length > 300 ? (
          <Button onClick={() => setExpandDescription(s => !s)} className={classes.button}>
            Ler {expandDescription ? "menos" : "mais"}...
          </Button>
        ) : null}
      </Fragment>
    );

    dialogContent = (
      <Fragment>
        <DialogContent className={classes.dialogContent}>
          <div className={css.panelSup}>
            <div className={css.movementDate}>
              <div className={css.itemDayMonth}>{movementDayMonth}</div>
              {movementYear}
            </div>
            <div className={css.infoProcess}>
              <div title="Processo" className={css.process}>
                {procMovement.processNumber}
              </div>
              <div title="Cliente" className={css.client}>
                <ContactsOutlined className={classes.icon} />
                <div className={css.clientName}>{procMovement.client}</div>
              </div>
              <div title="Pasta/Caso" className={css.folder}>
                <FolderOutlined className={classes.icon} />
                <div className={css.folderNumber}>
                  {procMovement.folderNumber}{" "}
                  {procMovement.processTitle ? `- ${procMovement.processTitle}` : null}
                </div>
              </div>
            </div>
          </div>
          <div className={css.panelInf}>
            <div title="Tipo de Andamento" className={css.item}>
              <AssignmentOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Tipo de andamento</div>
                <div className={css.textContent}>{procMovement.movementType}</div>
              </div>
            </div>
            <Divider />
            <div title="Histórico" className={css.item}>
              <NotesOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Histórico</div>
                <div className={css.textContent}>{procMovementDescription}</div>
              </div>
            </div>
            <Divider />
            <div title="Observação Interna" className={css.item}>
              <NotesOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Observação interna</div>
                <div className={css.textContent}>{procMovementNotes}</div>
              </div>
            </div>
            <Divider />
            <div title="Revisado" className={css.item}>
              <Icon className={`far fa-calendar-check ${classes.iconFont}`} />
              <div className={css.content}>
                <div className={css.label}>Revisado em</div>
                <div className={css.textContent}>{revisionDate}</div>
              </div>
            </div>
            <Divider />
            <div title="Origem" className={css.item}>
              <Icon className={`far fa-edit ${classes.iconFont}`} />
              <div className={css.content}>
                <div className={css.label}>Origem</div>
                <div className={css.textContent}>{movementOrigin()}</div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Dialog
        className={classes.dialog}
        open={true}
        onClose={() => openProcMovement(0)}
        maxWidth="md"
        fullWidth
        fullScreen={fullScreen}
      >
        <DialogTitle className={classes.dialogTitle}>
          <div className={css.header}>
            <IconButton
              className={classes.closeIcon}
              title="Fechar janela"
              onClick={() => openProcMovement(0)}
            >
              <CloseOutlined />
            </IconButton>
          </div>
        </DialogTitle>
        {loading ? (
          <LinearProgress variant="query" className={classes.loading} />
        ) : (
          <div className={css.loading} />
        )}
        {dialogContent}
      </Dialog>
    </Fragment>
  );
}
