import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    drawer: {
      height: "calc(100vh - 55px)",
      "& .MuiDrawer-paper": {
        maxHeight: "calc(var(--vh, 1vh) * 100 - 90px)",
        top: "35px"
      }
    },
    swipe: {
      zIndex: 3
    }
  })
);
