import React, { ReactElement } from "react";
import css from "./Layout.module.css";
import Topbar from "../Components/Topbar/Topbar";
import Footer from "../Components/Footer/Footer";
import Main from "./Main";
import { RouteComponentProps } from "react-router";

export default Layout;

function Layout(props: RouteComponentProps): ReactElement {
  return (
    <div className={css.container}>
      <Topbar />
      <Main />
      <Footer path={props.location.pathname} />
    </div>
  );
}
