/**
 *
 * @example
 * arrayOfObjects: listType = [
 *  { id: 97, date: Tue Dec 24 2019 00:00:00 GMT-0300 (Brasilia Standard Time) }
 *  { id: 96, date: Fri Dec 27 2019 00:00:00 GMT-0300 (Brasilia Standard Time) }
 *  { id: 89, date: Thu Oct 31 2019 00:00:00 GMT-0300 (Brasilia Standard Time) }
 * ];
 * sortObjectDate<listType>(arrayOfObjects);
 * arrayOfObjects [
 *  { id: 96, date: Fri Dec 27 2019 00:00:00 GMT-0300 (Brasilia Standard Time) }
 *  { id: 97, date: Tue Dec 24 2019 00:00:00 GMT-0300 (Brasilia Standard Time) }
 *  { id: 89, date: Thu Oct 31 2019 00:00:00 GMT-0300 (Brasilia Standard Time) }
 * ];
 */

export function sortObjectDate<Type extends { date: number | Date; id: number }>(
  arrayOfObjects: Type[],
  orderMode: "ASC" | "DESC" = "DESC"
): Type[] {
  return Object.values(arrayOfObjects).sort((a: Type, b: Type) => {
    const aMs = new Date(a.date).getTime();
    const bMs = new Date(b.date).getTime();
    if (orderMode === "DESC") {
      if (aMs === bMs) return b.id - a.id;
      return bMs - aMs;
    } else if (orderMode === "ASC") {
      if (aMs === bMs) return a.id - b.id;
      return aMs - bMs;
    } else {
      return 0;
    }
  });
}
