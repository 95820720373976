import React, { Fragment, useState } from "react";
import {
  AccessTimeOutlined,
  CheckOutlined,
  CloseOutlined,
  ArrowBackOutlined
} from "@material-ui/icons";
import {
  Button,
  Dialog,
  DialogActions,
  TextField,
  DialogContent,
  LinearProgress,
  IconButton
} from "@material-ui/core";
import css from "./AssignmentsToolbar.module.css";
import apiCaller from "../../apiCaller";
import { AssignmentType } from "../../../declarations";
import makeStyles from "./Styles";

export default AssignmentToolbar;

function AssignmentToolbar({
  className,
  clearSelected,
  selectedSet,
  selectAll,
  updateAssignment
}: {
  className?: any;
  clearSelected: () => void;
  selectedSet: Set<number>;
  selectAll: () => void;
  updateAssignment: (id: number, updateFunction: (a: AssignmentType) => AssignmentType) => void;
}) {
  const [questionDialog, setQuestionDialog] = useState({
    open: false,
    loading: false,
    newStatus: -1,
    message: ""
  });

  function submitStatus(newStatus: 0 | 1 | 2) {
    return () => setQuestionDialog(s => ({ ...s, open: true, newStatus }));
  }

  async function setSelectedStatus() {
    setQuestionDialog(s => ({ ...s, loading: true }));

    const assignmentsIds = Array.from(selectedSet.values());

    const { error } = await apiCaller.patch("/assignments/bulk-change-status", {
      newStatus: questionDialog.newStatus,
      assignmentsIds,
      aditionalNotes: questionDialog.message
    });
    setQuestionDialog(s => ({ ...s, loading: false }));
    const aditionalNotes = questionDialog.message + "\r\n\r\n";
    if (!error) {
      assignmentsIds.forEach(id =>
        updateAssignment(id, s => {
          return {
            ...s,
            notes: aditionalNotes + s.notes,
            status: questionDialog.newStatus as 0 | 1 | 2
          };
        })
      );
      setQuestionDialog(s => ({ ...s, open: false }));
      clearSelected();
    }
  }

  let newStatusName = "";
  if (questionDialog.newStatus === 0) {
    newStatusName = "Pendente";
  }
  if (questionDialog.newStatus === 1) {
    newStatusName = "Concluído";
  }
  if (questionDialog.newStatus === 2) {
    newStatusName = "Cancelado";
  }

  const classes = makeStyles();

  return (
    <Fragment>
      <div className={`${className} ${css.selectToolbar}`}>
        <div className={css.status}>
          <div title="Voltar" className={css.selectedBack}>
            <IconButton className={classes.backIcon} onClick={clearSelected}>
              <ArrowBackOutlined />
            </IconButton>
          </div>
          <div className={css.selectedsTotal}>
            <span className={css.selectedNumber}>{selectedSet.size}</span>
          </div>
          <div className={css.buttonGroup}>
            <Button onClick={submitStatus(0)} className={classes.button}>
              <AccessTimeOutlined
                className={classes.statusIcon}
                fontSize="small"
                style={{ color: "#125ad3" }}
              />
              <span className={css.statusBtnTitle}>Pendente</span>
            </Button>
            <Button onClick={submitStatus(1)} className={classes.button} title="Concluído">
              <CheckOutlined
                className={classes.statusIcon}
                fontSize="small"
                style={{ color: "#125ad3" }}
              />
              <span className={css.statusBtnTitle}>Concluído</span>
            </Button>
            <Button onClick={submitStatus(2)} className={classes.button} title="Cancelado">
              <CloseOutlined
                className={classes.statusIcon}
                fontSize="small"
                style={{ color: "#125ad3" }}
              />
              <span className={css.statusBtnTitle}>Cancelado</span>
            </Button>
          </div>
        </div>
      </div>
      <Dialog
        className={classes.dialog}
        open={questionDialog.open}
        onClose={() => setQuestionDialog(s => ({ ...s, open: false }))}
      >
        {questionDialog.loading ? <LinearProgress className={classes.loading} /> : null}
        <DialogContent>
          <div className={css.dialogTitle}>
            Você selecionou <span style={{ fontWeight: 600 }}>{selectedSet.size}</span> prazo(s).
            <br /> Deseja alterar para <span style={{ fontWeight: 600 }}>{newStatusName}</span>?
          </div>
          <div className={css.notes}>
            <TextField
              fullWidth
              onChange={e => {
                e.persist();
                setQuestionDialog(s => ({ ...s, message: e.target.value }));
              }}
              autoFocus
              placeholder="Adicionar nota"
              multiline
              className={classes.textFieldMultiLine}
              value={questionDialog.message}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.dialogButton}
            onClick={() => {
              setSelectedStatus();
            }}
            color="primary"
            autoFocus
            size="small"
          >
            Sim
          </Button>
          <Button
            className={classes.dialogButton}
            onClick={() => {
              setQuestionDialog(s => ({ ...s, open: false }));
            }}
            color="primary"
            autoFocus
            size="small"
          >
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
