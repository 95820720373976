import React, { HTMLAttributes } from "react";
import Select from "react-select";
import TextField, { BaseTextFieldProps } from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { ControlProps } from "react-select/src/components/Control";
import { MenuProps, NoticeProps } from "react-select/src/components/Menu";
import { OptionProps } from "react-select/src/components/Option";
import makeStyles from "./Styles";

export default AutoComplete;

type InputComponentProps = Pick<BaseTextFieldProps, "inputRef"> & HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />;
}

function AutoComplete<Data, Option = { key: string | number; label: string; value: Data | null }>({
  options,
  setSelected,
  selected,
  label
}: {
  options: Option[];
  setSelected: (newSelected: Option) => void;
  selected: Option;
  label: string;
}) {
  const classes = makeStyles();

  function Control(props: ControlProps<Option>) {
    const {
      children,
      innerProps,
      innerRef,
      selectProps: { classes, TextFieldProps }
    } = props;

    return (
      <TextField
        className={classes.textField}
        fullWidth
        InputProps={{
          inputComponent,
          inputProps: {
            className: classes.input,
            ref: innerRef,
            children,
            ...innerProps
          }
        }}
        {...TextFieldProps}
      />
    );
  }

  function Option(props: OptionProps<Option>) {
    return (
      <MenuItem
        ref={props.innerRef}
        selected={props.isFocused}
        component="div"
        style={{
          fontWeight: props.isSelected ? 500 : 400
        }}
        {...props.innerProps}
      >
        {props.children}
      </MenuItem>
    );
  }

  function Menu(props: MenuProps<Option>) {
    return (
      <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
        {props.children}
      </Paper>
    );
  }

  function NoOptionsMessage(props: NoticeProps<Option>) {
    return (
      <div className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
        Sem resultados
      </div>
    );
  }

  const components = {
    Control,
    Menu,
    Option,
    NoOptionsMessage
  };

  return (
    <Select
      classes={classes}
      inputId="users-select"
      TextFieldProps={{
        label,
        InputLabelProps: {
          htmlFor: "users-select",
          shrink: true
        }
      }}
      options={options}
      components={components}
      value={selected}
      onChange={(value: any) => setSelected(value)}
    />
  );
}
