import React, { ReactElement, useState, useEffect, useReducer, useContext } from "react";
import { Route, Switch, Redirect } from "react-router";
import Assignments from "./Assignments";
import Contacts from "./Contacts/Contacts";
import ProcMovements from "./ProcMovements/ProcMovements";
import Timesheets from "./Timesheets/Timesheets";
import Expenses from "./Expenses/Expenses";
import { PersonBasic } from "../declarations";
import apiCaller from "./apiCaller";
import {
  reducer as procMovementsReducer,
  initialState as procMovementsInitialState
} from "./ProcMovements/ProcMovementsStore";
import {
  reducer as assignmentsReducer,
  initialState as assignmentsInitialState
} from "./Assignments/AssignmentsStore";
import {
  reducer as contactsReducer,
  initialState as contactsInitialState
} from "./Contacts/ContactsStore";
import {
  reducer as timesheetsReducer,
  initialState as timesheetsInitialState
} from "./Timesheets/TimesheetsStore";
import {
  reducer as expensesReducer,
  initialState as expensesInitialState
} from "./Expenses/ExpensesStore";
import {
  reducer as dashboardReducer,
  initialState as dashboardInitialState
} from "./Dashboard/DashboardStore";
import { userContext } from "./Root";
import Dashboard from "./Dashboard/Dashboard";

export default Main;

function Main(): ReactElement {
  const [peopleBasic, setPeopleBasic] = useState<PersonBasic[]>([]);

  const [, setError] = useState<string | null>(null);

  useEffect(function() {
    (async function() {
      const { data, error } = await apiCaller.get("/users/people");
      if (error) {
        setError(error);
      } else {
        setPeopleBasic(s => [
          ...s,
          ...data.data.sort(function(a: PersonBasic, b: PersonBasic) {
            return a.contactName.localeCompare(b.contactName);
          })
        ]);
      }
    })();
  }, []);

  const { user } = useContext(userContext);

  const loggedPerson: PersonBasic | null =
    user === null || user.personId === null || user.contactId === null
      ? null
      : {
          userId: user.id,
          personId: user.personId,
          contactId: user.contactId,
          contactName: user.contactName || "",
          class: user.class || ""
        };

  const [procMovementsState, procMovementsDispatch] = useReducer(
    procMovementsReducer,
    procMovementsInitialState
  );

  const [assignmentsState, assignmentsDispatch] = useReducer(assignmentsReducer, {
    ...assignmentsInitialState,
    defaultSelectedAssigned: { userId: user!.id, contactName: user!.contactName || "" }
  });

  const [contactsState, contactsDispatch] = useReducer(contactsReducer, contactsInitialState);

  const [timesheetsState, timesheetsDispatch] = useReducer(timesheetsReducer, {
    ...timesheetsInitialState,
    defaultExecutionLawyer: loggedPerson
  });
  const [expensesState, expensesDispatch] = useReducer(expensesReducer, {
    ...expensesInitialState,
    defaultSelectedOwner: loggedPerson
  });

  const [dashboardState, dashboardDispatch] = useReducer(dashboardReducer, {
    ...dashboardInitialState
  });

  return (
    <Switch>
      <Route
        exact
        path={[
          "/dashboard/despesas/:id",
          "/dashboard/honorarios/:id",
          "/dashboard/andamentos/:id",
          "/dashboard"
        ]}
        render={props => <Dashboard {...props} {...dashboardState} dispatch={dashboardDispatch} />}
      />
      <Route
        render={props => (
          <Assignments
            {...props}
            {...assignmentsState}
            dispatch={assignmentsDispatch}
            userGroup={
              peopleBasic.filter(
                p =>
                  p.userId !== null &&
                  (p.class.includes("A") || p.class.includes("E") || p.class.includes("U"))
              ) as (PersonBasic & { userId: number })[]
            }
          />
        )}
        path={["/prazos/:id", "/prazos"]}
      ></Route>
      <Route
        path={["/contatos/:id", "/contatos"]}
        render={props => <Contacts {...props} {...contactsState} dispatch={contactsDispatch} />}
      />
      <Route
        render={props => (
          <ProcMovements {...props} {...procMovementsState} dispatch={procMovementsDispatch} />
        )}
        path={["/andamentos/:id", "/andamentos"]}
      ></Route>
      <Route
        render={props => (
          <Timesheets
            people={peopleBasic.filter(
              p => p.class.includes("A") || p.class.includes("E") || p.class.includes("U")
            )}
            {...props}
            {...timesheetsState}
            dispatch={timesheetsDispatch}
          />
        )}
        path={["/honorarios/:id", "/honorarios"]}
      ></Route>
      <Route
        render={props => (
          <Expenses
            people={peopleBasic.filter(
              p => p.class.includes("A") || p.class.includes("E") || p.class.includes("U")
            )}
            {...props}
            {...expensesState}
            dispatch={expensesDispatch}
          />
        )}
        path={["/despesas/:id", "/despesas"]}
      ></Route>
      <Redirect to="/not-found" />
    </Switch>
  );
}
