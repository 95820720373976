import React, { useEffect, useState } from "react";
import css from "./ClockDate.module.css";
import moment from "moment";
import "moment/locale/pt-br";

export default ClockDate;

moment.locale("pt-br");

function ClockDate() {
  const [updateDate, setUpdateDate] = useState(new Date());

  useEffect(() => {
    let date = setInterval(() => setUpdateDate(new Date()), 1000);

    return function cleanDate() {
      clearInterval(date);
    };
  }, []);

  const toolbarPicker = (date: Date) => {
    const daySelected = moment(date).format("ddd, D [de] MMMM");
    const hourSelected = moment(date).format("H:mm:ss");

    return (
      <>
        <div className={css.hourDisplay}>{hourSelected}</div>
        <div className={css.dateDisplay}>{daySelected}</div>
      </>
    );
  };

  return (
    <div className={css.boxDate}>
      <div className={css.date}>{toolbarPicker(updateDate)}</div>
    </div>
  );
}
