import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    dialog: {
      "& .MuiDialog-paperWidthMd": {
        minHeight: "90vh",
        "@media only screen and (max-width: 820px)": {
          width: "100%",
          height: "100%",
          margin: "0",
          maxWidth: "100%",
          maxHeight: "none",
          borderRadius: "0"
        }
      }
    },
    dialogContent: {
      padding: "0"
    },
    dialogTitle: {
      zIndex: 1,
      backgroundColor: "#125ad3",
      color: "#fff",
      gridTemplateColumns: "1fr 1fr",
      padding: "4px",
      boxShadow:
        "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)"
    },
    dialogButton: {
      gridColumn: "2",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "end",
      color: "#125ad3"
    },
    closeIcon: {
      gridColumn: "1",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "start",
      color: "#fff",
      padding: "4px"
    },

    editIcon: {
      gridColumn: "2",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "center",
      color: "#fff",
      padding: "4px"
    },
    deleteIcon: {
      gridColumn: "3",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "end",
      color: "#fff",
      padding: "8px",
      marginRight: "10px"
    },
    loading: {
      height: "5px",
      position: "relative",
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#125ad3"
      }
    },
    icon: {
      color: "#737373",
      fontSize: "1.2rem",
      paddingTop: "1px",
      gridRow: "1",
      gridColumn: "1"
    },
    iconDelete: {
      fontSize: "1.1rem",
      gridRow: "1",
      gridColumn: "1"
    },
    iconAlertDelete: {
      color: "#e20137",
      fontSize: "1.2rem",
      padding: "12px"
    },
    iconFont: {
      color: "#737373",
      fontSize: "1em",
      width: "1em",
      height: "1em",
      gridRow: "1",
      gridColumn: "1"
    },
    button: {
      color: "#125ad3",
      lineHeight: "0px"
    },
    textField: {
      "& label.Mui-focused": {
        color: "#125ad3"
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#125ad3"
      },
      "& .MuiInput-underline": {
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: "2px solid #125ad3"
        },
        "&:after": {
          borderBottom: "2px solid #125ad3"
        }
      }
    }
  })
);
