import React, {
  useState,
  Fragment,
  ChangeEvent,
  useEffect,
  useContext,
  SyntheticEvent
} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Divider,
  LinearProgress,
  TextField,
  Switch,
  Select,
  Input,
  MenuItem,
  IconButton,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import {
  LocationOnOutlined,
  NotesOutlined,
  CloseOutlined,
  EventOutlined,
  HowToRegOutlined,
  ContactsOutlined,
  FolderOutlined,
  GavelOutlined,
  LabelOutlined,
  PeopleOutlined
} from "@material-ui/icons";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import css from "./AssignmentAdd.module.css";
import apiCaller from "../../apiCaller";
import { UserBasic, Assignment, CaseType, User, AssignmentType } from "../../../declarations";
import { userContext } from "../../Root";
import makeStyles from "./Styles";
import moment from "moment";
import { RouteComponentProps } from "react-router-dom";
import AutocompleteAsync from "../../../Components/AutoCompleteAsync/AutoCompleteAsync";
import TransferListAsync from "../../../Components/TransferListAsync/TransferListAsync";
import ColorPicker from "../../../Components/ColorPicker/ColorPicker";

export default AssignmentAdd;

function AssignmentAdd({ ...props }: Props & Pick<RouteComponentProps, "history">) {
  const { user } = useContext(userContext);
  const { id, contactName } = user as User;

  const [openFieldClient, setOpenFieldClient] = useState<boolean>(false);
  const [openFieldCase, setOpenFieldCase] = useState<boolean>(false);
  const [openFieldProcess, setOpenFieldProcess] = useState<boolean>(false);
  const [openFieldUsers, setOpenFieldUsers] = useState<boolean>(false);

  const [optionsClient, setOptionsClient] = useState<Assignment[]>([]);
  const [optionsCase, setOptionsCase] = useState<CaseType[]>([]);
  const [optionsProcess, setOptionsProcess] = useState<CaseType[]>([]);
  const [optionsUsers, setOptionsUsers] = useState<UserBasic[]>([]);

  const [valueSelectedClient, setValueSelectedClient] = useState<Assignment>();
  const [valueSelectedCase, setValueSelectedCase] = useState<Assignment>();
  const [valueSelectedProcess, setValueSelectedProcess] = useState<Assignment>();
  const [valueSelectedUsers, setValueSelectedUsers] = useState<Assignment>();

  const [inputValueClient, setInputValueClient] = useState<string>("");
  const [inputValueCase, setInputValueCase] = useState<string>("");
  const [inputValueProcess, setInputValueProcess] = useState<string>("");
  const [inputValueUsers, setInputValueUsers] = useState(contactName);

  const labelField = ["Cliente", "Caso", "Processo", "Atribuir à"];

  const [loadingFieldClient, setLoadingFieldClient] = useState(false);
  const [loadingFieldCase, setLoadingFieldCase] = useState(false);
  const [loadingFieldProcess, setLoadingFieldProcess] = useState(false);

  const { openAdd, setOpenAdd, history } = props;
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<{ open: boolean; message: string }>({
    open: false,
    message: ""
  });

  const [state, setState] = useState<Partial<Assignment>>({
    subject: "",
    location: "",
    notes: "",
    startDate: new Date(),
    endDate: new Date(),
    startTime: new Date(),
    endTime: new Date(),
    allDay: false,
    assignedUserId: id,
    assignedUserName: "",
    assignedUser: 1,
    status: 0,
    permissionLevel: 2,
    mode: 0,
    color: "#ffffff",
    clientNameId: null,
    caseProcessId: null,
    processNumberId: null,
    linkedUsersId: []
  });

  useEffect(() => {

    if (valueSelectedClient !== undefined && valueSelectedClient !== null) {

      setState({ ...state, clientNameId: valueSelectedClient.personId });
    } else {
      setState({ ...state, clientNameId: null });
    }
  }, [valueSelectedClient]);

  useEffect(() => {

    if (valueSelectedCase !== undefined && valueSelectedCase !== null) {
      setState({ ...state, caseProcessId: valueSelectedCase.id });
    } else {
      setState({ ...state, caseProcessId: null });
    }
  }, [valueSelectedCase]);

  useEffect(() => {

      if (valueSelectedProcess !== undefined && valueSelectedProcess !== null) {
      setState({ ...state, processNumberId: valueSelectedProcess.processId });
    } else {
      setState({ ...state, processNumberId: null });
    }

  }, [valueSelectedProcess]);

  useEffect(() => {

  if (valueSelectedUsers !== undefined && valueSelectedUsers !== null) {
    setState({ ...state, assignedUserId: valueSelectedUsers!.id });
  }

  }, [valueSelectedUsers]);


  useEffect(() => {
    const findUser = optionsUsers.find(u => u.id === user!.id!);
    if (findUser === undefined) optionsUsers.unshift(user as UserBasic);

    if (optionsUsers !== undefined) {
      const usersId = optionsUsers.map(a => a.id);
      setState({ ...state, linkedUsersId: usersId });
    }
  }, [optionsUsers]);

  //autoCompleteAsync ----------------------------------------

  useEffect(() => {
    if (inputValueCase === "") {
      setInputValueProcess("");
      setValueSelectedCase(undefined);
    }

    if (openFieldClient) {
      setLoadingFieldClient(true);
      (async () => {
        const { error, data } = await apiCaller.get<{ data: Assignment[] }>("/searchClients");

        if (data) {
          setOptionsClient(data.data);
          setLoadingFieldClient(false);
        } else {
          setError(error);
        }
      })();
    }

    if (openFieldCase && valueSelectedClient !== undefined && valueSelectedClient !== null) {
      setLoadingFieldCase(true);
      (async () => {
        const { data, error } = await apiCaller.get<{ data: CaseType[] }>(
          "/cases/searchByClient/" + valueSelectedClient.personId
        );

        if (data) {
          setOptionsCase(data.data);
          setLoadingFieldCase(false);
        } else {
          setError(error);
        }
      })();
    }

    if (openFieldProcess && valueSelectedCase !== undefined && valueSelectedCase !== null) {
      setLoadingFieldProcess(true);
      (async () => {
        const { data, error } = await apiCaller.get<{ data: CaseType[] }>(
          "/process/searchByCase/" + valueSelectedCase.id
        );

        if (data) {
          setOptionsProcess(data.data);
          setLoadingFieldProcess(false);
        } else {
          setError(error);
        }
      })();
    }
  }, [
    openFieldClient,
    inputValueCase,
    valueSelectedClient,
    openFieldCase,
    valueSelectedCase,
    openFieldProcess
  ]);

  useEffect(() => {
    if (!openFieldClient || !openFieldCase || !openFieldProcess || !openFieldUsers) {
      setOptionsClient([]);
      setOptionsCase([]);
      setOptionsProcess([]);
      setOptionsUsers([]);
    }
  }, [openFieldClient, openFieldCase, openFieldProcess, openFieldUsers]);

  //---------------------------------------------------------

  //TransferListAsync----------------------------------------

  const [left, setLeft] = useState<UserBasic[]>([]);
  const [right, setRight] = useState<Array<Object>>([]);
  const [loadingTransfer, setLoadingTransfer] = useState<boolean>(true);

  useEffect(() => {
    setLoadingTransfer(true);
    (async () => {
      const { error, data } = await apiCaller.get<{ data: UserBasic[] }>("/Users");

      if (data) {
        //remove usuário logado da lista
        const userIdIndex = data.data.map(user => user.id).indexOf(id);
        data.data.splice(userIdIndex, 1);
        setLeft(data.data);
      } else {
        setError(error);
      }
    })();
    setLoadingTransfer(false);
  }, []);

  useEffect(() => {
    left.forEach(function(value: UserBasic) {
      if (inputValueUsers === value.contactName) {
        setInputValueUsers("");
      }
    });
  }, [left]);

  if (inputValueUsers === "" && openFieldUsers === false) setInputValueUsers(contactName);
  if (inputValueUsers !== "" && openFieldUsers === true) setInputValueUsers("");

  //----------------------------------------------------------

  const classes = makeStyles();

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const handleChange = (field: keyof Assignment) => (event: ChangeEvent<any>) => {
    setState({ ...state, [field]: event.target.value });
  };

  function handleDateChange(date: Date, field: "startDate" | "endDate") {
    setState((s: Partial<Assignment>) => {
      if (field === "startDate" && date.getTime() > state.endDate!.getTime()) {
        s.endDate = date;
      }
      if (field === "endDate" && date.getTime() < state.startDate!.getTime()) {
        s.startDate = date;
      }

      if (state.allDay) date.setHours(0, 0, 0, 0);

      return { ...s, [field]: date };
    });
  }

  const submitForm = async (e: SyntheticEvent<EventTarget>) => {
    e.preventDefault();
    setLoading(true);
    const stateAssignment: any = {
      ...state
    };

    let dateFormated;
    let date;

    stateAssignment.startTime = moment(stateAssignment.startDate).format("HH:mm");
    date = new Date(stateAssignment.startDate);
    date.setHours(0, 0, 0, 0);
    dateFormated = moment(date.toDateString()).format("YYYY-MM-DD");
    stateAssignment.startDate = dateFormated;

    stateAssignment.endTime = moment(stateAssignment.endDate).format("HH:mm");
    date = new Date(stateAssignment.endDate);
    date.setHours(0, 0, 0, 0);
    dateFormated = moment(date.toDateString()).format("YYYY-MM-DD");
    stateAssignment.endDate = dateFormated;

    let hexColor = state.color!.slice(1);
    hexColor = hexColor.slice(4, 6) + hexColor.slice(2, 4) + hexColor.slice(0, 2);
    let colorDecimal = parseInt(hexColor, 16);
    stateAssignment.color = colorDecimal;

    const { data, error } = await apiCaller.post<any>(`/assignments`, stateAssignment);

    if (error) {
      setError(error);
    } else {
      history.push("/prazos/" + data.insertedId);
      setTimeout(() => setOpenAdd(false), 300);
    }
    setLoading(false);
    setOpenAdd(false);
  };

  let dialogContent = loading ? (
    <LinearProgress variant="query" className={classes.loading} />
  ) : (
    <div className={css.loading} />
  );

  if (!loading) {
    let dateTimePickerFormat = "EEE', 'd 'de' MMMM 'de' yyyy'";
    if (!state.allDay) dateTimePickerFormat += " às 'HH:mm";

    dialogContent = (
      <Fragment>
        <DialogContent className={classes.dialogContent}>
          <div className={css.main}>
            <div title="Período" className={css.itemDate}>
              <EventOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Período</div>
                <div className={css.allDay}>
                  <span>Dia inteiro</span>
                  <Switch
                    checked={!!state.allDay}
                    onClick={() => {
                      setState((s: Partial<Assignment>) => ({ ...s, allDay: !s.allDay }));
                    }}
                    value={state.allDay}
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                    className={classes.switchBase}
                  />
                </div>
                <div className={css.date}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                    <DateTimePicker
                      ampm={false}
                      DialogProps={{ className: classes.datePicker }}
                      showTodayButton={true}
                      todayLabel="Hoje"
                      cancelLabel="Cancelar"
                      invalidLabel="Data inválida"
                      invalidDateMessage="Data inválida"
                      className={`${classes.pickerStart} ${css.startDate}}`}
                      format={dateTimePickerFormat}
                      margin="normal"
                      value={state.startDate}
                      onChange={date => handleDateChange(date as Date, "startDate")}
                    />
                    <DateTimePicker
                      ampm={false}
                      DialogProps={{ className: classes.datePicker }}
                      showTodayButton={true}
                      todayLabel="Hoje"
                      cancelLabel="Cancelar"
                      invalidLabel="Data inválida"
                      invalidDateMessage="Data inválida"
                      className={`${classes.pickerEnd} ${css.startDate}}`}
                      format={dateTimePickerFormat}
                      margin="normal"
                      value={state.endDate}
                      onChange={date => handleDateChange(date as Date, "endDate")}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
            <Divider />
            <div title="Local" className={css.item}>
              <LocationOnOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Local</div>
                <div className={css.location}>
                  <TextField
                    inputProps={{ maxLength: 100 }}
                    placeholder="Digite um local"
                    className={`${classes.textField} ${classes.location}`}
                    value={state.location}
                    onChange={handleChange("location")}
                  />
                </div>
              </div>
            </div>
            <Divider />
            <div title="Notas" className={css.item}>
              <NotesOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Notas</div>
                <div className={css.notes}>
                  <TextField
                    autoFocus={props.focusNotes}
                    fullWidth
                    placeholder="Digite uma nota"
                    multiline
                    className={classes.textFieldMultiLine}
                    value={state.notes}
                    onChange={handleChange("notes")}
                  />
                </div>
              </div>
            </div>
            <Divider />
            <div title="Cliente" className={css.item}>
              <ContactsOutlined className={classes.icon} />
              <div className={css.content}>
                <AutocompleteAsync
                  requiredField={true}
                  setValueSelected={setValueSelectedClient}
                  setOpenField={setOpenFieldClient}
                  openField={openFieldClient}
                  optionLabel={"contactName"}
                  options={optionsClient}
                  labelField={labelField[0]}
                  setInputValueOne={setInputValueClient}
                  setInputValueSecond={setInputValueCase}
                  loading={loadingFieldClient}
                />
              </div>
            </div>
            <div title="Pasta | Caso" className={css.item}>
              <FolderOutlined className={classes.icon} />
              <div className={css.content}>
                <AutocompleteAsync
                  requiredField={false}
                  setValueSelected={setValueSelectedCase}
                  setOpenField={setOpenFieldCase}
                  openField={openFieldCase}
                  optionLabel={"title"}
                  options={optionsCase}
                  labelField={labelField[1]}
                  inputValue={inputValueCase}
                  setInputValueOne={setInputValueCase}
                  setInputValueSecond={setInputValueProcess}
                  loading={loadingFieldCase}
                />
              </div>
            </div>
            <div title="Processo" className={css.item}>
              <GavelOutlined className={classes.icon} />
              <div className={css.content}>
                <AutocompleteAsync
                  requiredField={false}
                  setValueSelected={setValueSelectedProcess}
                  setOpenField={setOpenFieldProcess}
                  openField={openFieldProcess}
                  optionLabel={"processNumber"}
                  options={optionsProcess}
                  labelField={labelField[2]}
                  inputValue={inputValueProcess}
                  setInputValueOne={setInputValueProcess}
                  loading={loadingFieldProcess}
                />
              </div>
            </div>
            <Divider />
            <div title="Vincular Usuários" className={css.item}>
              <PeopleOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Vincular usuários</div>
                <div className={css.linkedUsers}>
                  <TransferListAsync
                    left={left}
                    setLeft={setLeft}
                    right={right}
                    setRight={setRight}
                    setValueRight={setOptionsUsers}
                    loadingTransfer={loadingTransfer}
                    valueDefault={user}
                  />
                </div>
              </div>
            </div>
            <Divider />
            <div title="Atribuído à" className={css.item}>
              <HowToRegOutlined className={classes.icon} />
              <div className={css.content}>
                <AutocompleteAsync
                  requiredField={false}
                  setValueSelected={setValueSelectedUsers}
                  setOpenField={setOpenFieldUsers}
                  openField={openFieldUsers}
                  optionLabel={"contactName"}
                  options={optionsUsers}
                  labelField={labelField[3]}
                  inputValue={inputValueUsers}
                  setInputValueOne={setInputValueUsers}
                />
              </div>
            </div>
            <Divider />
            <div className={css.item}>
              <LabelOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Etiqueta</div>
                <div className={css.colorLabel}>
                  <ColorPicker setState={setState} state={state}/>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Fragment>
    );
  }

  return (
    <Dialog
      className={classes.dialog}
      open={openAdd}
      onClose={() => setOpenAdd(false)}
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
    >
      <form onSubmit={submitForm}>
        <DialogTitle className={classes.dialogTitle} id="responsive-dialog-title">
          <div className={css.header}>
            <IconButton
              className={classes.closeIcon}
              title="Fechar janela"
              onClick={() => setOpenAdd(false)}
            >
              <CloseOutlined />
            </IconButton>
            <Button
              className={classes.saveButton}
              variant="contained"
              color="primary"
              autoFocus
              size="small"
              type="submit"
            >
              Salvar
            </Button>
          </div>
          <div className={css.contentSubject}>
            <div className={css.label}>Assunto</div>
            <div title="Assunto" className={css.subject}>
              <TextField
                inputProps={{ maxLength: 255 }}
                fullWidth
                placeholder="Digite um assunto"
                required={true}
                multiline
                onChange={handleChange("subject")}
                className={classes.textFieldMultiLine}
                value={state.subject}
              />
            </div>
          </div>
        </DialogTitle>
        {dialogContent}
      </form>
    </Dialog>
  );
}

interface Props {
  openAdd: boolean;
  setOpenAdd: (s: boolean) => void;
  updateAssignment: (id: number, updateFunction: (a: AssignmentType) => AssignmentType) => void;
  linkedUsers: UserBasic[];
  focusNotes: boolean;
  assignment?: AssignmentType;
}
