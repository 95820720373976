import React from "react";
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import css from "./ChartBox.module.css";
import { Charts as StoreChart } from "../../Root/Dashboard/DashboardStore";
import { RefreshOutlined } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import makeStyles from "./Styles";
import Skeleton from "@material-ui/lab/Skeleton";

export default ChartBox;

function ChartBox({ chart, reloadChart }: Props) {
  const classes = makeStyles();

  if (chart.loading && chart.data === undefined) {
    return (
      <div className={css.boxSkeleton}>
        <Skeleton className={`${css.sklChartHeader}`} variant="text" />
        <Skeleton className={`${css.sklChart}`} height={260} variant="rect" />
      </div>
    );
  }
  if (chart.error) {
    return <div></div>;
  }

  let options: ApexCharts.ApexOptions = {
    chart: { id: chart.name, zoom: {}, width: 0 },
    plotOptions: {},
    dataLabels: {},
    stroke: {},
    markers: {},
    legend: { position: "right", show: false },
    responsive: [
      {
        breakpoint: 0,
        options: { chart: { width: 0 } }
      }
    ]
  };
  let series: ApexCharts.ApexOptions["series"] = chart.data!.series;

  switch (chart.type) {
    case "bar":
      options.plotOptions!.bar = {};
      if (!chart.dataLabels) options.dataLabels!.enabled = false;
      if (chart.stacked) options.chart!.stacked = true;
      if (chart.horizontal) options.plotOptions!.bar.horizontal = true;
      options = {
        ...options,
        xaxis: { categories: chart.data!.xAxisLabels },
        chart: {
          ...options.chart,
          zoom: {
            ...options.chart!.zoom,
            enabled: true,
            type: "x",
            autoScaleYaxis: true
          }
        },
        responsive: [
          {
            breakpoint: 200,
            options: { chart: { width: 200 } }
          }
        ]
      };
      // Foi substituido pelo código de cima
      // options.xaxis = { categories: chart.data!.xAxisLabels };
      // options.chart!.zoom!.enabled = true;
      // options.chart!.zoom!.type = "x";
      // options.chart!.zoom!.autoScaleYaxis = true;
      // options!.responsive![0].breakpoint = 200;
      // options!.responsive![0].options.chart.width = 200;
      // options!.chart!.width = 200;
      break;
    case "pie":
      options = {
        ...options,
        labels: chart.data!.labels,
        legend: {
          ...options.legend,
          position: "bottom",
          show: true,
          floating: true,
          horizontalAlign: "left"
        },
        responsive: [
          {
            options: {
              chart: { widht: 300 }
            }
          }
        ],
        chart: { width: 200 }
      };
      // Foi substituido pelo código de cima
      // options.labels = chart.data!.labels;
      // options!.responsive![0].breakpoint = 200;
      // options!.legend!.position = "bottom";
      // options!.responsive![0].options.chart.width = 200;
      // options!.legend!.show = true;
      // options!.chart!.width = 200;
      break;
  }

  return (
    <>
      <div className={css.chartContent}>
        <div className={css.chartHeader}>
          <div className={css.chartTitle}>{chart.title}</div>
          <div className={css.chartButtons}>
            <div title="Atualizar" className={css.refresh}>
              <IconButton className={classes.iconButton} onClick={() => reloadChart(chart)}>
                <RefreshOutlined />
              </IconButton>
            </div>
          </div>
        </div>
        <div className={css.chartBody}>
          <Chart key={chart.name} options={options} series={series} type={chart.type} />
        </div>
      </div>
    </>
  );
}

interface Props {
  chart: StoreChart;
  reloadChart: (chart: StoreChart) => Promise<void>;
}
