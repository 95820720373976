import React, { ReactElement, useEffect, useState, useCallback } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Auth from "./Auth";
import NotFound from "./NotFound";
import apiCaller from "./apiCaller";
import { CircularProgress } from "@material-ui/core";
import { User, UserContext } from "../declarations";
import NoticeSnackbar from "../Components/NoticeSnackbar/NoticeSnackbar";
import { makeStyles, createStyles } from "@material-ui/styles";
import Layout from "./Layout";

export default Root;

export const userContext = React.createContext<UserContext>({
  user: null,
  fetchUser: async () => {},
  setUser: () => {},
  logout: () => {}
});

function Root(): ReactElement {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);

  const fetchUser = useCallback(async () => {
    const { error, data } = await apiCaller.get<any>("/users/me");
    if (error) {
      apiCaller.setAuthToken("");
    } else {
      setUser(data);
    }
    setLoading(false);
  }, []);

  const logout = async () => {
    await apiCaller.post("/auth/logout",  user );
    await apiCaller.setAuthToken("");
    setUser(null);
    window.location.reload();
  };
  useEffect(() => {
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
    apiCaller.axiosInstance.interceptors.response.use(
      r => r,
      error => {
        if (error.message === "Network Error" || error.response.status > 499) {
          if (error.message === "Network Error") setError(error.message);
          else {
            setError(error.response.data.message);
          }
          return Promise.reject(1);
        } else if (error.response) {
          return Promise.reject(error.response.data.message);
        }
      }
    );
    fetchUser();
  }, [fetchUser, setError]);

  const classes = useStyles();

  if (loading) return <CircularProgress className={classes.loading} />;
  return (
    <BrowserRouter>
      <userContext.Provider value={{ user, fetchUser, setUser, logout }}>
        <Switch>
          <Redirect path="/" exact to="/auth/login" />
          <Route component={NotFound} path="/not-found" exact />
          <Route
            path="/auth"
            render={props => (user ? <Redirect to="/dashboard" /> : <Auth {...props} />)}
          />
          <Route
            path="/(dashboard|prazos|contatos|processos|andamentos|honorarios|despesas)"
            render={props => (user ? <Layout {...props} /> : <Redirect to="/auth/login" />)}
          />
          <Redirect to="/not-found" />
        </Switch>
        <NoticeSnackbar error={error} setError={setError} />
      </userContext.Provider>
    </BrowserRouter>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    loading: {
      alignSelf: "center",
      justifySelf: "center",
      color: "#125ad3"
    }
  })
);
