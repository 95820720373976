import React, { useContext } from "react";
import css from "./Footer.module.css";
import { Icon, Tabs, Tab } from "@material-ui/core";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import { Link } from "react-router-dom";
import { ContactsOutlined, AccessAlarmOutlined, DashboardOutlined } from "@material-ui/icons";
import makeStyles from "./Styles";
import { userContext } from "../../Root";

export default Footer;

function Footer({ path }: { path: string }) {
  const classes = makeStyles();

  const { user } = useContext(userContext);

  let menuIndex;


  const pathList = [
    "/dashboard",
    "/prazos",
    "/contatos",
    //"/processos",
    "/andamentos",
    "/honorarios",
    "/despesas"
  ]

  const pList = pathList.filter((p) => {

    if(p === "/dashboard") {
      return p
    }

    if(user!.permissions[1][0] >= "1" && p === "/prazos") {
      return p
    }
    if(user!.permissions[5][0] >= "1" && p === "/contatos"){
      return p
    }
    /*if(user!.permissions[1][3] >= "1" && p === "/processos") {
      return p
    }*/
    if(user!.permissions[2][13] >= "1" && p === "/andamentos"){
      return p
    }
    if(user!.permissions[1][6] >= "1" && p === "/honorarios") {
      return p
    }
    if(user!.permissions[2][17] >= "1" && p === "/despesas"){
      return p
    }


  })

  for(const p of pList){

    if (path.startsWith(p)) menuIndex = pList.indexOf(p);
  }

  return (
    <div className={css.footer}>
      <Tabs
        variant="scrollable"
        scrollButtons="on"
        value={menuIndex}
        textColor="primary"
        className={classes.tabs}
      >
        <Tab
        component={Link}
        to="/dashboard"
        label="DashBoard"
        icon={<DashboardOutlined />}
        />

        {user!.permissions[1][0] >= "1" ?
        <Tab
        component={Link}
        to="/prazos"
        label="Prazos"
        icon={<CheckBoxOutlinedIcon />}
        /> : null}

        {user!.permissions[5][0] >= "1" ?
        <Tab
        component={Link}
        to="/contatos"
        label="Contatos"
        icon={<ContactsOutlined />}
        /> : null}

       {/*  {user!.permissions[1][3] >= "1" ?
        <Tab
          component={Link}
          to="/processos"
          label="processos"
          icon={<Icon className={`fas fa-gavel`} />}
          disabled={false}
        /> : null} */}

        {user!.permissions[2][13] >= "1" ?
        <Tab
          component={Link}
          to="/andamentos"
          label="Andamentos"
          icon={<Icon className={`far fa-newspaper `} />}
        /> : null}

        {user!.permissions[1][6] >= "1" ?
        <Tab
        component={Link}
        to="/honorarios"
        label="Honorários"
        icon={<AccessAlarmOutlined /> }
        /> : null}

        {user!.permissions[2][17] >= "1" ?
        <Tab
          component={Link}
          to="/despesas"
          label="Despesas"
          icon={<Icon className={`fas fa-money-check-alt`} />}
        />: null}
      </Tabs>
    </div>
  );
}
