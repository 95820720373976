import {
  IDictionary,
  QueryPagination,
  DisplayMode,
  Period,
  ExpenseType,
  Account,
  PersonBasic,
  Order,
  OrderField
} from "../../declarations";
import { endOfToday } from "date-fns";
import { subDays, startOfToday } from "date-fns/esm";

export const initialState: State = {
  defaultSelectedOwner: null,
  selectedOwner: null,
  selectedCostCenter: null,
  expenseTypes: [],
  costCenters: [],
  selectedExpenseType: null,
  chargeable: null,
  charged: null,
  error: null,
  displayMode: 0,
  refreshing: 0,
  order: { mode: "DESC", field: { name: "id", label: "Data de inclusão" } },
  orderFields: [
    { name: "date", label: "Data de lançamento" },
    { name: "id", label: "Data de inclusão" }
  ],
  selectedPeriod: { fromDate: subDays(startOfToday(), 7), toDate: endOfToday() },
  query: "/expenses?",
  fetched: {},
  modes: [
    {
      label: "Todos",
      filterFunction: () => true,
      query: "",
      search: ""
    }
  ],
  queryPaginations: {}
};

export function reducer(state: State, action: Action): State {
  const currentMode = { ...state.modes[state.displayMode] };
  let newState = { ...state };
  if (action.type === "updateQuery") {
    let query = "/expenses";
    if (currentMode.search) {
      query += "/search/" + currentMode.search;
    }
    query += "?" + currentMode.query;
    query += `&orderMode=${state.order.mode}`;
    query += `&orderField=${state.order.field.name}`;

    if (state.selectedPeriod.fromDate !== null && state.selectedPeriod.toDate !== null)
      query += `&fromDate=${state.selectedPeriod.fromDate.toString()}&toDate=${state.selectedPeriod.toDate.toString()}`;

    if (state.chargeable !== null) query += `&chargeable=` + state.chargeable;
    if (state.charged !== null) query += `&charged=` + state.charged;
    if (state.selectedExpenseType !== null)
      query += `&expenseAccountId=` + state.selectedExpenseType.id;
    if (state.selectedCostCenter !== null) query += `&costCenterId=` + state.selectedCostCenter.id;
    if (state.selectedOwner !== null) query += `&ownerPersonId=` + state.selectedOwner.personId;
    else if (state.defaultSelectedOwner !== null)
      query += `&ownerPersonId=` + state.defaultSelectedOwner.personId;

    newState.query = query;
    if (state.queryPaginations[query] === undefined) {
      newState.queryPaginations[query] = {
        owns: new Set(),
        loading: true,
        lastPage: 1,
        endReached: false
      };
    }
  }
  if (action.type === "fetchNextPage") {
    const { data, query } = action.payload;
    const dataObj: IDictionary<ExpenseType> = {};
    const fetchedExpenses = data;
    const fetchedQuery = query;
    const fetchQueryPagination = { ...state.queryPaginations[fetchedQuery] };

    fetchedExpenses.forEach((c: ExpenseType) => {
      c.date = new Date(c.date);
      dataObj[c.id] = c;
      fetchQueryPagination.owns.add(c.id);
    });

    fetchQueryPagination.lastPage++;
    if (fetchedExpenses.length === 0) fetchQueryPagination.endReached = true;

    newState.fetched = { ...state.fetched, ...dataObj };
    newState.queryPaginations = {
      ...state.queryPaginations,
      [fetchedQuery]: fetchQueryPagination
    };
  }
  if (action.type === "loading") {
    const { query, loading } = action.payload;
    newState.queryPaginations[query].loading = loading;
  }
  if (action.type === "search") {
    newState.modes[state.displayMode].search = action.payload;
  }
  if (action.type === "clearFilters") {
    newState.displayMode = 0;
    newState.modes.forEach(mode => (mode.search = ""));
    newState.selectedPeriod = { fromDate: null, toDate: null };
    newState.chargeable = null;
    newState.charged = null;
    newState.selectedOwner = state.defaultSelectedOwner;
    newState.selectedCostCenter = null;
    newState.selectedExpenseType = null;
  }
  if (action.type === "displayMode") {
    newState.displayMode = action.payload;
  }
  if (action.type === "refresh") {
    newState.fetched = {};
    newState.queryPaginations = {};
    newState.refreshing++;
  }
  if (action.type === "order") {
    newState.order = action.payload;
  }
  if (action.type === "chargeable") {
    newState.chargeable = action.payload;
  }
  if (action.type === "charged") {
    newState.charged = action.payload;
  }
  if (action.type === "selectedPeriod") {
    const newPeriod = action.payload;
    if (newPeriod.fromDate !== null && newPeriod.toDate === null) {
      newPeriod.toDate = newPeriod.fromDate;
    }
    newState.selectedPeriod = action.payload;
  }
  if (action.type === "updateExpense") {
    newState.fetched[action.payload.id] = action.payload;
  }
  if (action.type === "expenseTypes") {
    newState.expenseTypes = action.payload;
  }
  if (action.type === "expenseType") {
    newState.selectedExpenseType = action.payload;
  }
  if (action.type === "costCenters") {
    newState.costCenters = action.payload;
  }
  if (action.type === "costCenter") {
    newState.selectedCostCenter = action.payload;
  }
  if (action.type === "owner") {
    newState.selectedOwner = action.payload;
  }
  if (action.type === "deleteExpense") {
    const newFetched: IDictionary<ExpenseType> = {};
    Object.entries(state.fetched).forEach(([key, value]) => {
      if (key !== "" + action.payload) newFetched[key] = value;
    });
    newState.fetched = newFetched;
  }

  return newState;
}
export type Action =
  | SimpleAction
  | FetchNextPageAction
  | LoadingAction
  | OrderAction
  | SelectedPeriodAction
  | SearchAction
  | ErrorAction
  | DisplayModeAction
  | UpdateExpenseAction
  | ChargableFilterAction
  | ExpenseTypesAction
  | ExpenseTypeFilterAction
  | CostCentersAction
  | CostCenterFilterAction
  | OwnerFilterAction
  | DeleteAction
  | ChargedFilterAction;

type SimpleAction = {
  type: "updateQuery" | "clearFilters" | "refresh";
};

interface FetchNextPageAction {
  type: "fetchNextPage";
  payload: { data: ExpenseType[]; query: string };
}
interface LoadingAction {
  type: "loading";
  payload: { query: string; loading: boolean };
}
interface SearchAction {
  type: "search";
  payload: string;
}
interface OrderAction {
  type: "order";
  payload: State["order"];
}
interface SelectedPeriodAction {
  type: "selectedPeriod";
  payload: Period;
}
interface ErrorAction {
  type: "error";
  payload: Error;
}
interface DisplayModeAction {
  type: "displayMode";
  payload: number;
}
interface UpdateExpenseAction {
  type: "updateExpense";
  payload: ExpenseType;
}
interface ChargableFilterAction {
  type: "chargeable";
  payload: boolean | null;
}
interface ChargedFilterAction {
  type: "charged";
  payload: boolean | null;
}
interface ExpenseTypesAction {
  type: "expenseTypes";
  payload: Account[];
}
interface ExpenseTypeFilterAction {
  type: "expenseType";
  payload: Account | null;
}
interface CostCentersAction {
  type: "costCenters";
  payload: { title: string; id: number }[];
}
interface CostCenterFilterAction {
  type: "costCenter";
  payload: { title: string; id: number } | null;
}
interface OwnerFilterAction {
  type: "owner";
  payload: PersonBasic | null;
}
interface DeleteAction {
  type: "deleteExpense";
  payload: number;
}

export interface State {
  defaultSelectedOwner: PersonBasic | null;
  selectedOwner: PersonBasic | null;

  selectedCostCenter: { title: string; id: number } | null;
  costCenters: { title: string; id: number }[];

  selectedExpenseType: Account | null;
  expenseTypes: Account[];
  chargeable: boolean | null;
  charged: boolean | null;
  error: Error | null;
  displayMode: number;
  refreshing: number;
  order: Order<OrderFieldNames>;
  orderFields: OrderField<OrderFieldNames>[];
  selectedPeriod: Period;
  query: string;
  fetched: IDictionary<ExpenseType>;
  modes: DisplayMode<ExpenseType>[];
  queryPaginations: IDictionary<QueryPagination>;
}

export type OrderFieldNames = "id" | "date";
