import React, { useEffect, useState, ChangeEvent } from "react";
import { TextField, CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import makeStyles from "./Styles";
import { CaseType, UserBasic, AssignmentType } from "../../declarations";

export default AutocompleteAsync;

function AutocompleteAsync(props: Props) {
  const classes = makeStyles();
  const [label, setLabel] = useState("");
  const [valueOld, setValueOld] = useState("");

  function handleChange(e: ChangeEvent<{}>, value: string, reason: string) {
    setLabel(props.labelField);

    if (label && valueOld !== value) {
      setValueOld(value);
      props.setInputValueOne(value);

      if (props.setInputValueSecond !== undefined) {
        props.setInputValueSecond("");
      }
    } else {
      props.setInputValueOne(value);
    }
  }

  return (
    <>
      <Autocomplete
        open={props.openField}
        className={classes.autoComplete}
        onOpen={() => props.setOpenField(true)}
        onClose={() => props.setOpenField(false)}
        getOptionSelected={(option, value) => {
          return option[props.optionLabel] === value[props.optionLabel];
        }}
        getOptionLabel={option => option[props.optionLabel]}
        onChange={(e, value) => props.setValueSelected(value)}
        onInputChange={handleChange}
        clearOnEscape
        inputValue={props.inputValue}
        loading={props.loading}
        loadingText={props.loading === true ? "..." : "Sem resultados"}
        clearText={"Limpar"}
        options={props.options}
        renderInput={params => {
          return (
            <TextField
              {...params}
              label={props.labelField}
              className={`${classes.textField} ${classes.client}`}
              fullWidth
              required={props.requiredField}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {props.loading ? (
                      <CircularProgress
                        style={{ width: "15px", height: "15px" }}
                        className={classes.loading}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          );
        }}
      />
    </>
  );
}

interface Props {
  setOpenField: (state: boolean) => void;
  setValueSelected: (state: any) => void;
  openField: boolean;
  optionLabel: string;
  options: Array<Object>;
  labelField: string;
  inputValue?: any;
  setInputValueOne: (state: string) => void;
  setInputValueSecond?: (state: string) => void;
  loading?: boolean;
  requiredField: boolean;
}
