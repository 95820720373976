import React, { useState, Fragment, ChangeEvent, useEffect, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Divider,
  LinearProgress,
  TextField,
  Switch,
  Select,
  Input,
  MenuItem,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  LocationOnOutlined,
  NotesOutlined,
  CloseOutlined,
  EventOutlined,
  HowToRegOutlined,
  DateRangeOutlined,
  LabelOutlined,
  PeopleOutlined,
} from "@material-ui/icons/";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import css from "./AssignmentEdit.module.css";
import apiCaller from "../../apiCaller";
import { UserBasic, AssignmentType, User, Assignment, CaseType } from "../../../declarations";
import makeStyles from "./Styles";
import moment from "moment";
import ColorPicker from "../../../Components/ColorPicker/ColorPicker";
import TransferListAsync from "../../../Components/TransferListAsync/TransferListAsync";
import { userContext } from "../../Root";

export default AssignmentEdit;

function AssignmentEdit(props: Props) {
  const { openEdit, setOpenEdit, assignment, updateAssignment, linkedUsers } = props;
  const [, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(userContext);
  const { id, contactName } = user as User;

  let colorHex = parseInt(assignment.color).toString(16);
  for (let i = colorHex.length; i < 6; i++) {
    colorHex = "0" + colorHex;
  }
  colorHex = colorHex.slice(4, 6) + colorHex.slice(2, 4) + colorHex.slice(0, 2);

  const [state, setState] = useState<State>({
    subject: assignment.subject,
    location: assignment.location,
    notes: assignment.notes,
    startDate: assignment.startDate,
    endDate: assignment.endDate,
    allDay: assignment.allDay,
    assignedUserId: assignment.assignedUserId,
    assignedUserName: assignment.assignedUserName,
    color: `#${colorHex}`,
    linkedUsersId: [],
  });

  const [optionsUsers, setOptionsUsers] = useState<UserBasic[]>(linkedUsers);
  const [inputValueUsers, setInputValueUsers] = useState(contactName);

  //TransferListAsync----------------------------------------

  const [left, setLeft] = useState<UserBasic[]>([]);
  const [right, setRight] = useState<Array<Object>>([]);
  const [loadingTransfer, setLoadingTransfer] = useState<boolean>(true);

  useEffect(() => {
    setLoadingTransfer(true);
    (async () => {
      const { error, data } = await apiCaller.get<{ data: UserBasic[] }>("/Users");

      if (data) {
        //remove usuário logado da lista
        const userIdIndex = data.data.map((user) => user.id).indexOf(id);
        data.data.splice(userIdIndex, 1);

        const results = data.data.filter(
          ({ id: id1 }) => !linkedUsers.some(({ id: id2 }) => id2 === id1)
        );
        setRight(linkedUsers);
        setLeft(results);
      } else {
        setError(error);
      }
    })();
    setLoadingTransfer(false);
  }, []);

  useEffect(() => {
    left.forEach(function (value: UserBasic) {
      if (inputValueUsers === value.contactName) {
        setInputValueUsers("");
      }
    });
  }, [left]);

  useEffect(() => {
    const findUser = optionsUsers.find((u) => u.id === user!.id!);
    if (findUser === undefined) optionsUsers.unshift(user as UserBasic);

    if (optionsUsers !== undefined) {
      const usersId = optionsUsers.map((a) => a.id);
      setState({ ...state, linkedUsersId: usersId });
    }
  }, [optionsUsers]);

  //----------------------------------------------------------

  useEffect(() => {
    if (state.color) addModified("color");
    if (state.linkedUsersId) addModified("linkedUsersId");
  }, [state.color, state.linkedUsersId]);

  const classes = makeStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  type FieldName = keyof State;
  const [modified, setModified] = useState<Set<FieldName>>(new Set());

  function addModified(field: keyof State) {
    setModified((s) => new Set([...Array.from(s.values()), field]));
  }

  const handleChange = (field: keyof State) => (event: ChangeEvent<any>) => {
    setState({ ...state, [field]: event.target.value });
    addModified(field);

    if (field === "assignedUserId") {
      addModified("assignedUserName");
      setState((s) => ({
        ...s,
        assignedUserName: (optionsUsers.find((lu) => lu.id === event.target.value) as UserBasic)
          .contactName,
      }));
    }
  };

  function handleDateChange(date: Date, field: "startDate" | "endDate") {
    addModified(field);
    setState((s) => {
      if (field === "startDate" && date.getTime() > state.endDate.getTime()) {
        s.endDate = date;
        addModified("endDate");
      }
      if (field === "endDate" && date.getTime() < state.startDate.getTime()) {
        s.startDate = date;
        addModified("startDate");
      }

      if (state.allDay) date.setHours(0, 0, 0, 0);

      return { ...s, [field]: date };
    });
  }

  async function saveUpdate() {
    setLoading(true);
    const stateAssignment = {
      ...state,
    };

    const patchObj: any = {};

    for (let field of Array.from(modified.values())) {
      let date;
      switch (field) {
        case "startDate":
          date = new Date(stateAssignment[field]);
          date.setHours(0, 0, 0, 0);
          patchObj[field] = date.toDateString();
          patchObj["startTime"] = moment(stateAssignment.startDate).format("HH:mm");
          break;
        case "endDate":
          date = new Date(stateAssignment[field]);
          date.setHours(0, 0, 0, 0);
          patchObj[field] = date.toDateString();
          patchObj["endTime"] = moment(stateAssignment.startDate).format("HH:mm");
          break;
        case "color":
          let hexColor = stateAssignment[field].slice(1);
          hexColor = hexColor.slice(4, 6) + hexColor.slice(2, 4) + hexColor.slice(0, 2);
          let colorDecimal = parseInt(hexColor, 16);
          stateAssignment.color = colorDecimal;
          patchObj[field] = colorDecimal;
          break;
        default:
          patchObj[field] = stateAssignment[field];
      }
    }

    const { error } = await apiCaller.patch(`/assignments/${assignment.id}`, patchObj);
    if (error) {
      setError(error);
    } else {
      updateAssignment(assignment.id, () => ({ ...assignment, ...stateAssignment }));
    }
    setLoading(false);
    setOpenEdit(false);
  }

  let dialogContent = loading ? (
    <LinearProgress variant="query" className={classes.loading} />
  ) : (
    <div className={css.loading} />
  );

  if (!loading) {
    let dateTimePickerFormat = "EEE', 'd 'de' MMMM 'de' yyyy'";
    if (!state.allDay) dateTimePickerFormat += " às 'HH:mm";

    dialogContent = (
      <Fragment>
        <DialogContent className={classes.dialogContent}>
          <div className={css.main}>
            <div title="Período" className={css.itemDate}>
              <EventOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Período</div>
                <div className={css.allDay}>
                  <span>Dia inteiro</span>
                  <Switch
                    checked={!!state.allDay}
                    onClick={() => {
                      setState((s: State) => ({ ...s, allDay: !s.allDay }));
                      addModified("allDay");
                    }}
                    value={state.allDay}
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                    className={classes.switchBase}
                  />
                </div>
                <div className={css.date}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                    <DateTimePicker
                      ampm={false}
                      DialogProps={{ className: classes.datePicker }}
                      disabled={assignment.recorrency}
                      showTodayButton={true}
                      todayLabel="Hoje"
                      cancelLabel="Cancelar"
                      invalidLabel="Data inválida"
                      invalidDateMessage="Data inválida"
                      className={`${classes.pickerStart} ${css.startDate}}`}
                      format={dateTimePickerFormat}
                      margin="normal"
                      value={state.startDate}
                      onChange={(date) => handleDateChange(date as Date, "startDate")}
                    />
                    <DateTimePicker
                      ampm={false}
                      DialogProps={{ className: classes.datePicker }}
                      disabled={assignment.recorrency}
                      showTodayButton={true}
                      todayLabel="Hoje"
                      cancelLabel="Cancelar"
                      invalidLabel="Data inválida"
                      invalidDateMessage="Data inválida"
                      className={`${classes.pickerEnd} ${css.startDate}}`}
                      format={dateTimePickerFormat}
                      margin="normal"
                      value={state.endDate}
                      onChange={(date) => handleDateChange(date as Date, "endDate")}
                    />
                  </MuiPickersUtilsProvider>
                  <div className={css.recorrency}>
                    {assignment.recorrency ? (
                      <Fragment>
                        <DateRangeOutlined className={classes.icon} />
                        <span title="Recorrência" className={css.recorrencyTitle}>
                          Recorrência
                        </span>
                      </Fragment>
                    ) : undefined}
                  </div>
                </div>
              </div>
            </div>
            <Divider />
            <div title="Local" className={css.item}>
              <LocationOnOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Local</div>
                <div className={css.location}>
                  <TextField
                    inputProps={{ maxLength: 100 }}
                    placeholder="Digite um local"
                    onChange={handleChange("location")}
                    className={`${classes.textField} ${classes.location}`}
                    value={state.location}
                  />
                </div>
              </div>
            </div>
            <Divider />
            <div title="Notas" className={css.item}>
              <NotesOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Notas</div>
                <div className={css.notes}>
                  <TextField
                    autoFocus={props.focusNotes}
                    fullWidth
                    placeholder="Digite uma nota"
                    required
                    multiline
                    onChange={handleChange("notes")}
                    className={classes.textFieldMultiLine}
                    value={state.notes}
                  />
                </div>
              </div>
            </div>
            <Divider />
            <div title="Vincular Usuários" className={css.item}>
              <PeopleOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Vincular usuários</div>
                <div className={css.linkedUsers}>
                  <TransferListAsync
                    left={left}
                    setLeft={setLeft}
                    right={right}
                    setRight={setRight}
                    setValueRight={setOptionsUsers}
                    loadingTransfer={loadingTransfer}
                    valueDefault={user}
                  />
                </div>
              </div>
            </div>
            <Divider />
            <div title="Atribuído" className={css.item}>
              <HowToRegOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Atribuido à</div>
                <div className={css.assigned}>
                  <Select
                    value={state.assignedUserId}
                    input={<Input name="atribuido" id="atribuido" />}
                    className={classes.select}
                    onChange={handleChange("assignedUserId")}
                  >
                    {optionsUsers.map((lu, i) => (
                      <MenuItem key={i} value={lu.id}>
                        {lu.contactName}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className={css.item}>
              <LabelOutlined className={classes.icon} />
              <div className={css.content}>
                <div className={css.label}>Etiqueta</div>
                <div className={css.colorLabel}>
                  <ColorPicker setState={setState} state={state} />
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Fragment>
    );
  }

  return (
    <Dialog
      className={classes.dialog}
      open={openEdit}
      onClose={() => setOpenEdit(false)}
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle className={classes.dialogTitle} id="responsive-dialog-title">
        <div className={css.header}>
          <IconButton
            className={classes.closeIcon}
            title="Fechar janela"
            onClick={() => setOpenEdit(false)}
          >
            <CloseOutlined />
          </IconButton>
          <Button
            className={classes.saveButton}
            onClick={() => saveUpdate()}
            variant="contained"
            color="primary"
            autoFocus
            size="small"
            disabled={modified.size === 0}
          >
            Salvar
          </Button>
        </div>
        <div className={css.contentSubject}>
          <div className={css.label}>Assunto</div>
          <div title="Assunto" className={css.subject}>
            <TextField
              inputProps={{ maxLength: 255 }}
              fullWidth
              placeholder="Digite um assunto"
              required
              multiline
              onChange={handleChange("subject")}
              className={classes.textFieldMultiLine}
              value={state.subject}
            />
          </div>
        </div>
      </DialogTitle>
      {dialogContent}
    </Dialog>
  );
}

interface Props {
  openEdit: boolean;
  setOpenEdit: (s: boolean) => void;
  assignment: AssignmentType;
  updateAssignment: (id: number, updateFunction: (a: AssignmentType) => AssignmentType) => void;
  linkedUsers: UserBasic[];
  focusNotes: boolean;
}

interface State {
  startDate: Date;
  endDate: Date;
  subject: string;
  location: string;
  notes: string;
  allDay: boolean;
  assignedUserId: number;
  assignedUserName: string;
  color: any;
  linkedUsersId: Array<any>;
}
