import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    radioLabel: {
      marginLeft: "5px",
      marginRight: "5px",
      "& .MuiTypography-body1": {
        fontSize: "0.725rem",
        color: "#565656"
      }
    },

    radio: {
      padding: "4px",
      "& .MuiSvgIcon-root": {
        width: "0.9em",
        height: "0.9em"
      },
      "&.Mui-checked": {
        color: "#125ad3"
      }
    },
    iconTitle: {
      gridRow: "1",
      gridColumn: "1",
      alignSelf: "center",
      justifySelf: "center",
      marginRight: "10px",
      fontSize: "1.8rem",
      color: "#1a5dce",
      width: "1.3em",
      height: "1em"
    },

    sidebarInfList: {
      height: "calc(var(--vh, 1vh) * 100 - 636px)",
      padding: "0px"
    },

    datePicker: {
      "& .MuiPickersToolbar-toolbar": {
        backgroundColor: "#125ad3"
      },
      "& .MuiPickersDay-daySelected:hover ": {
        backgroundColor: "#125ad3"
      },
      "& .MuiPickersDay-daySelected": {
        backgroundColor: "#125ad3",
        color: "#fff"
      },
      "& .MuiButton-textPrimary": {
        color: "#125ad3"
      },
      "& .MuiTypography-colorPrimary": {
        color: "#125ad3"
      }
    },

    button: {
      backgroundColor: "#125ad3",
      fontSize: "0.8rem",
      borderRadius: "0px",
      "&:hover": {
        backgroundColor: "#0039c4"
      }
    },
    picker: {
      width: "180px",
      margin: "0px 16px 16px",
      "& .MuiInput-underline:after": {
        borderBottom: "2px solid #125ad3"
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "2px solid #125ad3"
      }
    },
    iconFilter: {
      color: "#fff",
      fontSize: "1.2rem",
      padding: "0 5px"
    }
  })
);
