import React, { ReactElement } from "react";
import { SwipeableDrawer } from "@material-ui/core";
import makeStyles from "./Styles";

export default BackDrawer;

function BackDrawer({ drawerContent, setDrawer, showDrawer }: Props): ReactElement {
  const classes = makeStyles();

  return (
    <div>
      <SwipeableDrawer
        style={{ zIndex: 2 }}
        className={classes.drawer}
        transitionDuration={226}
        open={showDrawer}
        SwipeAreaProps={{ className: classes.swipe }}
        onOpen={() => setDrawer(true)}
        onClose={() => setDrawer(false)}
      >
        {drawerContent}
      </SwipeableDrawer>
    </div>
  );
}

interface Props {
  drawerContent: ReactElement | null;
  setDrawer: (state: boolean) => void;
  showDrawer: boolean;
}
