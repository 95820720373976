import React, { useState, useEffect, useCallback } from "react";
import css from "./Assignments.module.css";
import apiCaller from "../apiCaller";
import AssignmentsSidebar from "./Sidebar/AssignmentsSidebar";
import Assignment from "./Assignment/Assignment";
import AssignmentsListItem from "./ListItem/AssignmentsListItem";
import SearchList from "../../Components/SearchList/SearchList";
import {
  AssignmentType,
  AssignmentFromRequest,
  Result,
  Period,
  SetOrderFunc,
  UserBasic
} from "../../declarations";
import AssignmentToolbar from "./Toolbar/AssignmentsToolbar";
import MainList from "../../Components/MainList/MainList";
import BackDrawer from "../../Components/BackDrawer/BackDrawer";
import { State, Action, OrderFieldNames } from "./AssignmentsStore";
import { compareWithoutDiacritics } from "../../Utils/compareWithoutDiacritics";
import SpeedDialsAdd from "../../Components/SpeedDialsAdd/SpeedDialsAdd";
import AssignmentAdd from "./Add/AssignmentAdd";
import { RouteComponentProps } from "react-router-dom";

export default Assignments;

function Assignments({
  dispatch,
  fetched,
  query,
  displayMode,
  order,
  orderFields,
  refreshing,
  selectedPeriod,
  selectedAssigned,
  selectedSet,
  history,
  ...props
}: {
  dispatch: (a: Action) => void;
  userGroup: { contactName: string; userId: number }[];
} & State &
  RouteComponentProps) {
  const currentMode = props.modes[displayMode];
  const currentQuery = props.queryPaginations[query];
  const idParam = (props.match.params as { id?: string }).id;
  const openAssignmentId = idParam !== undefined && !isNaN(+idParam) ? +idParam : undefined;

  const clearSelected = () => dispatch({ type: "clearSelected" });
  useEffect(clearSelected, [currentQuery, dispatch]);

  const toggleSelected = function(id: number) {
    dispatch({ type: "toggleSelected", payload: id });
  };

  const setSelectedAssigned = (person: { contactName: string; userId: number } | null) => {
    dispatch({ type: "selectedAssigned", payload: person });
  };

  const fetchNextPage = useCallback(
    function() {
      dispatch({ type: "loading", payload: { query, loading: true } });

      const { lastPage } = currentQuery;
      let path = query + `&pageNumber=${lastPage}&pageLength=20`;

      (async () => {
        let { data, error } = (await apiCaller.get(path)) as Result<{
          data: AssignmentFromRequest[];
        }>;
        if (error) {
          dispatch({ type: "error", payload: error });
        } else if (data) {
          dispatch({ type: "fetchNextPage", payload: { query, data: data.data } });
        }
        dispatch({ type: "loading", payload: { query, loading: false } });
      })();
    },
    [currentQuery, dispatch, query]
  );

  useEffect(
    function () {
      (async function () {
        const { data } = await apiCaller.get<any>(`/assignments`);
        setCanAdd(data.canAdd);


      })();
    },
    []
  );

  const [canAdd, setCanAdd] = useState(false)

  const search = async (searchString: string) => {
    dispatch({ type: "search", payload: searchString });
  };

  const refresh = () => {
    dispatch({ type: "refresh" });
  };

  const [showDrawer, setDrawer] = useState<boolean>(false);
  const toggleDrawer = function() {
    setDrawer(s => !s);
  };

  const isFilterActive =
    selectedPeriod.fromDate !== null ||
    displayMode !== 0 ||
    currentMode.search !== "" ||
    selectedAssigned !== null;

  const clearFilters = () => {
    dispatch({ type: "clearFilters" });
  };

  const setSelectedPeriod = (func: (period: Period) => Period) => {
    dispatch({ type: "selectedPeriod", payload: func(selectedPeriod) });
  };

  const sidebar = (
    <AssignmentsSidebar
      clearFilters={clearFilters}
      isFilterActive={isFilterActive}
      userGroup={props.userGroup}
      selectedAssigned={selectedAssigned}
      setSelectedAssigned={setSelectedAssigned}
      selectedPeriod={selectedPeriod}
      setSelectedPeriod={setSelectedPeriod}
      displayMode={displayMode}
      setDisplayMode={(i: number) => dispatch({ type: "displayMode", payload: i })}
      setDrawer={setDrawer}
    />
  );

  const drawer = { showDrawer, setDrawer, toggleDrawer, drawerContent: sidebar };

  const assignmentSearchFields = [
    "author",
    "processNumber",
    "counterPart",
    "subject",
    "notes",
    "location",
    "processNumberUnif"
  ];

  const loading = !currentQuery || currentQuery.loading;

  const displayingAssignments = Object.values(fetched)
    .filter(a => {
      let show: boolean =
        currentMode.filterFunction(a) &&
        currentQuery &&
        currentQuery.owns &&
        currentQuery.owns.has(a.id);
      if (selectedAssigned !== null) show = show && a.assignedUserId === selectedAssigned.userId;
      if (
        selectedPeriod.fromDate !== null &&
        selectedPeriod.toDate !== null &&
        ![1, 5, 6].includes(displayMode)
      ) {
        show =
          show &&
          a.startDate.getTime() <= selectedPeriod.toDate.getTime() &&
          a.startDate.getTime() >= selectedPeriod.fromDate.getTime();
      }

      if (show && currentMode.search.length > 0) {
        show = false;
        for (let searchField of assignmentSearchFields) {
          let value = a[searchField as keyof AssignmentType] as null | string | number;
          show = show || compareWithoutDiacritics(currentMode.search, value);
        }
      }
      return show;
    })
    .sort(({ id: aId, startDate: aDate }, { id: bId, startDate: bDate }) => {
      if (order.field.name === "startDate") {
        const aMs = aDate.getTime();
        const bMs = bDate.getTime();

        if (order.mode === "ASC") {
          if (aMs === bMs) return aId - bId;
          return aMs - bMs;
        } else {
          if (aMs === bMs) return bId - aId;
          return bMs - aMs;
        }
      }
      return 0;
    });

  useEffect(() => dispatch({ type: "updateQuery" }), [
    selectedAssigned,
    currentMode.query,
    currentMode.search,
    order,
    dispatch,
    refreshing,
    selectedPeriod
  ]);

  useEffect(() => {
    if (currentQuery && !currentQuery.endReached && currentQuery.owns.size === 0) {
      fetchNextPage();
    }
  }, [currentQuery, fetchNextPage]);

  const updateAssignment = useCallback(
    (id: number, updateFunction: (a: AssignmentType) => AssignmentType) => {
      const assignment = updateFunction(fetched[id]);
      dispatch({ type: "updateAssignment", payload: assignment });
    },
    [dispatch, fetched]
  );

  const [openAdd, setOpenAdd] = useState(false);
  const [linkedUsers, setLinkedUsers] = useState<(UserBasic & { contactId: number })[]>([]);
  const [questionDialog, setQuestionDialog] = useState({ open: false, confirmed: false });

  const openAssignment = (id: number) => history.push("/prazos/" + (id ? id : ""));

  const setOrder = (func: SetOrderFunc<OrderFieldNames>) =>
    dispatch({ type: "order", payload: func(order) });
  return (
    <>
      <BackDrawer {...drawer} />
      {openAssignmentId ? (
        <Assignment
          openAssignmentId={openAssignmentId}
          updateAssignment={updateAssignment}
          assignment={fetched[openAssignmentId]}
          openAssignment={openAssignment}
          history={history}
        />
      ) : null}
      {openAdd ? (
        <AssignmentAdd
          history={history}
          openAdd={openAdd}
          setOpenAdd={setOpenAdd}
          linkedUsers={linkedUsers}
          updateAssignment={updateAssignment}
          assignment={fetched[openAssignmentId as number]}
          focusNotes={questionDialog.confirmed}
        />
      ) : null}
      <div className={css.main}>
        <div className={css.assignmentToolbar} />
        <div className={css.assignmentMain}>
          <div className={css.assignmentSidebar}>{sidebar}</div>
          <div className={css.assignmentList}>
            <>
              <AssignmentToolbar
                className={selectedSet.size > 0 ? css.visible : css.hidden}
                clearSelected={clearSelected}
                updateAssignment={updateAssignment}
                selectAll={() => {}}
                selectedSet={selectedSet}
              />
              <SearchList
                className={selectedSet.size > 0 ? css.hidden : css.visible}
                search={search}
                {...drawer}
              />
            </>
            <MainList<
              AssignmentType,
              {
                updateAssignment: any;
                openAssignment: (id: number) => void;
                toggleSelected: (id: number) => void;
                selectedSet: Set<number>;
              },
              OrderFieldNames
            >
              {...{
                endReached: currentQuery && currentQuery.endReached,
                loading,
                refresh,
                currentSearch: currentMode.search,
                setOrder,
                order,
                orderFields,
                isFilterActive,
                clearFilters,
                contentArray: displayingAssignments,
                ListComponent: AssignmentsListItem,
                listComponentProps: {
                  updateAssignment,
                  openAssignment,
                  toggleSelected,
                  selectedSet
                },
                fetchNextPage: () => {
                  if (!currentQuery.loading && !currentQuery.endReached) fetchNextPage();
                }
              }}
            />
            <SpeedDialsAdd canAdd={canAdd} openAdd={openAdd} setOpenAdd={setOpenAdd} />
          </div>
        </div>
      </div>
    </>
  );
}

function extractDate(stringDate: string, stringTime: string): Date {
  const [day, mounth, year] = stringDate
    .trim()
    .split("-")
    .map((date) => parseInt(date));

  const [hour, minute] = stringTime
    .trim()
    .split(":")
    .map((time) => parseInt(time));

  const data = new Date(day, mounth - 1, year, hour, minute);

  return data.toString() == "Invalid Date" ? new Date(0) : data;
}

export function normalizeAssignment(
  fetchedAssignment: AssignmentFromRequest
): AssignmentType {
  const {
    startDate,
    endDate,
    startTime,
    endTime,
    notes,
    subject,
    location,
    recorrency,
  } = fetchedAssignment;

  const sDate = extractDate(startDate, startTime);
  const eDate = recorrency ? sDate : extractDate(endDate, endTime);

  return {
    ...fetchedAssignment,
    startDate: sDate,
    endDate: eDate,
    notes: notes === null ? "" : notes,
    subject: subject === null ? "" : subject,
    location: location === null ? "" : location,
  };
}
