import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    icon: {
      color: "#737373",
      fontSize: "1.2rem",
      gridRow: "1",
      gridColumn: "1",
      width: "100%"
    }
  })
);
