import { makeStyles, createStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    dialog: {
      "& .MuiDialog-paper": {
        margin: "10px",
        maxWidth: "400px"
      }
    },
    dialogButton: {
      gridColumn: "2",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "end",
      color: "#125ad3"
    },
    textFieldMultiLine: {
      "& label.Mui-focused": {
        color: "#125ad3"
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#125ad3"
      },
      "& .MuiInput-underline": {
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: "2px solid #125ad3"
        },
        "&:after": {
          borderBottom: "2px solid #125ad3"
        }
      }
    },
    loading: {
      height: "5px",
      position: "relative",
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#125ad3"
      }
    },
    button: {
      height: "30px",
      display: "grid",
      padding: "0px 8px",
      color: "rgba(0, 0, 0, 0.7)",
      alignContent: "center",
      justifyContent: "center"
    },
    statusIcon: {
      alignSelf: "center",
      justifySelf: "center"
    },
    backIcon: {
      padding: "4px"
    }
  })
);
