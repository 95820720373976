import { createStyles, makeStyles } from "@material-ui/styles";

export default makeStyles(() =>
  createStyles({
    loading: {
      gridRow: "2",
      alignSelf: "center",
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#125ad3"
      }
    },
    button: {
      margin: "0px 8px",
      padding: "2px 4px",
      backgroundColor: "#125ad3",
      fontSize: "0.7rem",
      "&:hover": {
        backgroundColor: "#1153c3"
      }
    },
    input: {
      fontSize: "0.9rem",
      "&.Mui-focused": {
        color: "#125ad3"
      }
    },
    select: {
      minWidth: "120px",
      "&:hover:not(.Mui-disabled):before": {
        borderBottom: "2px solid #125ad3"
      },
      "&:after": {
        borderBottom: "2px solid #125ad3"
      }
    },
    iconFilter: {
      color: "#125ad3"
    },
    iconButton: {
      padding: "4px"
    },
    list: {
      paddingTop: "0",
      paddingBottom: "0",
      display: "grid",
      gridAutoRows: "max-content",
      height: "calc(var(--vh, 1vh) * 100 - 255px)",
      overflow: "auto",
      "@media only screen and (max-width: 600px)": {
        height: "calc(var(--vh, 1vh) * 100 - 230px)"
      },
      "@media only screen and (max-height: 480px)": {
        height: "calc(var(--vh, 1vh) * 100 - 230px)"
      }
    }
  })
);
