import React, { ReactElement } from "react";

export default Title;

function Title(props: Props): ReactElement {
  const { children, className } = props;
  return <div className={className}>{children}</div>;
}

interface Props {
  children: string;
  className: string;
}
