import React, { Fragment } from "react";
import { ListItem, Checkbox } from "@material-ui/core";
import css from "./AssignmentsListItem.module.css";
import { DateRangeOutlined, HowToRegOutlined } from "@material-ui/icons";
import { AssignmentType } from "../../../declarations";
import makeStyles from "./Styles";
import moment from "moment";
import "moment/locale/pt-br";

export default AssignmentsListItem;

moment.locale("pt-br");

function AssignmentsListItem(props: Props) {
  const { content: assignment } = props;

  const newDate = assignment.startDate

  const currentYear = moment().format("YYYY")
  const otherYears = moment(newDate).format("YYYY")

  const startDate =
    currentYear === otherYears
      ? moment(newDate).format("ddd[,] D [de] MMMM")
      :  moment(newDate).format("DD/MM/YYYY");

  const dataAssignment = new Date() === new Date(newDate) ? "Hoje" : startDate;

  const startTime = moment(newDate).format("HH:mm")

  const classes = makeStyles();

  let colorHex = parseInt(assignment.color).toString(16);
  for (let i = colorHex.length; i < 6; i++) {
    colorHex = "0" + colorHex;
  }
  colorHex = colorHex.slice(4, 6) + colorHex.slice(2, 4) + colorHex.slice(0, 2);

  const statusStyled = () => {
    switch (assignment.status) {
      case 0:
        return undefined;
      case 1:
        return { color: "rgba(0, 0, 0, 0.5)", textDecoration: "line-through" };
      case 2:
        return { color: "rgba(255, 0, 0, 0.5)", textDecoration: "line-through" };
    }
  };

  const statusStyledDate = () => {
    switch (assignment.status) {
      case 0:
        return undefined;
      case 1:
        return { color: "rgba(0, 0, 0, 0.5)" };
      case 2:
        return { color: "rgba(255, 0, 0, 0.5)" };
    }
  };

  return (
    <Fragment>
      <ListItem
        onClick={() => props.openAssignment(assignment.id)}
        className={`${classes.item} ${css.listItem}`}
        dense
        button
      >
        <div className={css.listItemColor} style={{ backgroundColor: `#${colorHex}` }} />
        <div className={css.selectItem}>
          {assignment.permissionLevel >= 1 ? (
            <Checkbox
              checked={props.selectedSet.has(assignment.id)}
              color="primary"
              className={classes.checkBox}
              onClick={e => {
                e.stopPropagation();
                props.toggleSelected(assignment.id);
              }}
            />
          ) : null}
        </div>
        <div className={css.listItemBody}>
          <div className={css.itemSubject} style={statusStyled()}>
            {assignment.subject}
          </div>
          <div className={css.itemDesc} style={statusStyled()}>
            {assignment.notes}
          </div>
          <div className={css.listItemDate} style={statusStyledDate()}>
            <div className={css.itemDate}>{dataAssignment}</div>
            {!assignment.allDay ? <div className={css.itemHour}>{startTime}</div> : null}
            <div title="Recorrência" className={css.itemRecorrency}>
              {assignment.recorrency ? (
                <DateRangeOutlined className={classes.iconDate} />
              ) : (
                undefined
              )}
            </div>
          </div>
        </div>
        <div title="Atribuído à você" className={css.listItemTools}>
          {assignment.linkedUserId === assignment.assignedUserId ? (
            <HowToRegOutlined className={classes.icon} />
          ) : (
            undefined
          )}
        </div>
      </ListItem>
    </Fragment>
  );
}

interface Props {
  content: AssignmentType;
  openAssignment: (id: number) => void;
  toggleSelected: (id: number) => void;
  selectedSet: Set<number>;
}
