import React, { useState, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Divider,
  LinearProgress,
  TextField,
  Switch,
  useMediaQuery,
  useTheme,
  IconButton,
  Icon,
  DialogActions,
  Select,
  Input,
  MenuItem,
} from "@material-ui/core";
import {
  CloseOutlined,
  EventOutlined,
  FolderOutlined,
  ContactsOutlined,
  NotesOutlined,
  AttachMoneyOutlined,
  SearchOutlined,
  ErrorOutlineOutlined,
} from "@material-ui/icons/";
import css from "./ExpenseAdd.module.css";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import apiCaller from "../../apiCaller";
import { RouteComponentProps } from "react-router";
import ClientSearch from "../../../Components/ClientSearch/ClientSearch";
import { CaseType, Account } from "../../../declarations";
import makeStyles from "./Styles";
import { useDropzone } from "react-dropzone";
import { formatCurrency } from "../../../Utils/fieldFormat";
import { RecentCases } from "../../../Components/RecentCases/RecentCases";

export default ExpenseAdd;

function ExpenseAdd(props: Props & Pick<RouteComponentProps, "history">) {
  const { openAdd, setOpenAdd, expenseTypes, costCenters, history } = props;
  const [error, setError] = useState<{ open: boolean; message: string }>({
    open: false,
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [caseNumber, setCaseNumber] = useState<null | string>(null);
  const [selectedCase, setSelectedCase] = useState<null | CaseType>(null);

  const [
    { date, chargeable, value, discrimination, operatorAccount, costCenter },
    setState,
  ] = useState<State>({
    date: new Date(),
    upload: "",
    chargeable: true,
    discrimination: "",
    value: "",
    operatorAccount: expenseTypes[0],
    costCenter: costCenters[0],
  });

  const getCase = useCallback(async function (n: string) {
    setSelectedCase(null);
    setLoading(true);
    const { data, error } = await apiCaller.get<{ case: CaseType }>(
      "/cases/caseByNumber?number=" + n
    );
    if (data) {
      setSelectedCase(data.case);
    } else {
      if (error === "Not Found") setError({ message: "Caso não encontrado", open: true });
      if (error === "Unauthorized")
        setError({ message: "Você não tem permissão neste caso.", open: true });
    }
    setLoading(false);
  }, []);

  const isValueValid = !Number.isNaN(+value);
  const canSubmit = isValueValid && selectedCase;

  const [attachment, setAttachment] = useState<{ file: File; url: string } | null>(null);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setAttachment(() => ({
      url: URL.createObjectURL(acceptedFiles[0]),
      file: acceptedFiles[0],
    }));
  }, []);

  const maxSize = 2 ** 30;

  const { isDragActive, getRootProps, getInputProps, isDragReject, rejectedFiles } = useDropzone({
    onDrop,
    minSize: 1,
    maxSize,
    accept: "image/*, application/*, text/*, video/*",
  });

  const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

  async function submitForm() {
    setLoading(true);

    const options: {
      caseId: number;
      date: string;
      chargeable: boolean;
      value: number;
      history: string;
      operatorAccountId: number;
      costCenterId: number;
    } = {
      caseId: selectedCase!.id,
      date: date.toDateString(),
      chargeable,
      value: +value,
      history: discrimination,
      operatorAccountId: operatorAccount.id,
      costCenterId: costCenter.id,
    };

    const blob = new Blob([JSON.stringify(options)], {
      type: "application/json",
    });

    const formData = new FormData();
    if (attachment) formData.append("attachment", attachment.file);
    formData.append("data", blob);
    const { data, error } = await apiCaller.post<any>("/expenses", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (error) {
      setError({ message: "Erro ao criar a despesa", open: true });
    } else {
      history.push("/despesas/" + data.insertedId);
      setTimeout(() => setOpenAdd(false), 300);
    }
    setLoading(false);
  }

  const classes = makeStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  function handleDateChange(date: Date, field: "date" | "startTime" | "endTime") {
    setState((s) => {
      s = { ...s, [field]: date };

      return s;
    });
  }

  let dialogContent = loading ? (
    <LinearProgress variant="query" className={classes.loading} />
  ) : (
    <div className={css.loading} />
  );

  if (selectedCase === null) {
    dialogContent = <RecentCases getCase={getCase} />;
  } else {
    dialogContent = (
      <DialogContent className={classes.dialogContent}>
        <div className={css.panelSup}>
          <div title="Cliente" className={css.client}>
            <ContactsOutlined className={classes.icon} />
            <div className={css.clientName}>{selectedCase.client.contactName}</div>
          </div>
          <div title="Pasta/Caso" className={css.folder}>
            <FolderOutlined className={classes.icon} />
            <div className={css.folderNumber}>{selectedCase.title}</div>
          </div>
        </div>
        <div className={css.panelInf}>
          <div title="Data da despesa" className={css.item}>
            <EventOutlined className={classes.icon} />
            <div className={css.content}>
              <div className={css.label}>Data da despesa</div>
              <div className={css.date}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                  <DatePicker
                    DialogProps={{ className: classes.datePicker }}
                    showTodayButton={true}
                    todayLabel="Hoje"
                    cancelLabel="Cancelar"
                    invalidLabel="Data inválida"
                    invalidDateMessage="Data inválida"
                    className={`${classes.pickerStart} ${css.startDate}}`}
                    format="EEE', 'd 'de' MMMM 'de' yyyy"
                    margin="normal"
                    value={date}
                    onChange={(date) => handleDateChange(date as Date, "date")}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
          <Divider />
          <div title="Tipo de despesa" className={css.item}>
            <Icon className={`fas fas fa-receipt ${classes.icon}`} />
            <div className={css.content}>
              <div className={css.label}>Tipo de despesa</div>
              <div className={css.value}>
                <Select
                  value={operatorAccount.id}
                  input={<Input name="atribuido" id="atribuido" />}
                  className={classes.select}
                  onChange={(e) => {
                    const selected = expenseTypes.find((expType) => expType.id === e.target.value);
                    if (selected)
                      setState((s) => ({
                        ...s,
                        operatorAccount: selected,
                      }));
                  }}
                >
                  {expenseTypes.map((expType) => (
                    <MenuItem value={expType.id}>{expType.code + " - " + expType.title}</MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <Divider />
          <div title="Centro de custo" className={css.item}>
            <Icon className={`fas fa-map-marker ${classes.icon}`} />
            <div className={css.content}>
              <div className={css.label}>Centro de custo</div>
              <div className={css.value}>
                <Select
                  value={costCenter.id}
                  input={<Input />}
                  className={classes.select}
                  onChange={(e) => {
                    const selected = costCenters.find((cc) => cc.id === e.target.value);
                    if (selected)
                      setState((s) => ({
                        ...s,
                        costCenter: selected,
                      }));
                  }}
                >
                  {costCenters.map((cc) => (
                    <MenuItem value={cc.id}>{cc.title}</MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <Divider />
          <div title="Discriminação" className={css.item}>
            <NotesOutlined className={classes.icon} />
            <div className={css.content}>
              <div className={css.label}>Discriminação</div>
              <div className={css.discrimination}>
                <TextField
                  fullWidth
                  placeholder="Detalhe sobre esta despesa"
                  required
                  multiline
                  className={classes.textFieldMultiLine}
                  onChange={(e) => {
                    e.persist();
                    setState((s) => ({ ...s, discrimination: e.target.value }));
                  }}
                  value={discrimination}
                />
              </div>
            </div>
          </div>
          <Divider />
          <div title="Documento anexo" className={css.item}>
            <Icon className={`far fa-file-alt ${classes.icon}`} />
            <div className={css.content}>
              <div className={css.label}>Documento anexo</div>
              <div className={css.atachDoc}>
                <div className={css.dragDrop} {...getRootProps()}>
                  <input {...{ ...getInputProps(), multiple: false }} />
                  {(function () {
                    if (attachment !== null) {
                      return <div className={css.fileName}>{attachment.file.name}</div>;
                    } else if (!isDragActive) return "Clique ou solte os arquivos aqui";
                    else if (isDragActive && !isDragReject) return "Solte o arquivo.";
                    else if (isFileTooLarge && !isDragActive)
                      return (
                        <div className={css.fileLarge}>
                          O arquivo é muito grande.
                          <br />
                          (max. 10mb)
                        </div>
                      );
                  })()}
                </div>
              </div>
              <div
                title="Excluir anexo"
                onClick={() => setAttachment(null)}
                className={css.atachDelete}
              >
                <Icon className={`fas fa-minus ${classes.iconFont}`} />
                <div className={css.titleIconDelete}>Excluir anexo</div>
              </div>
            </div>
          </div>
          <Divider />
          <div title="Valor total da despesa" className={css.itemValue}>
            <AttachMoneyOutlined className={classes.icon} />
            <div className={css.content}>
              <div className={css.label}>Valor Total</div>
              <div className={css.value}>
                <TextField
                  inputProps={{ maxLength: 10 }}
                  placeholder="Digite o valor"
                  className={`${classes.textField} ${classes.totalValue}`}
                  onChange={(e) => {
                    e.persist();
                    setState((s) => ({
                      ...s,
                      value: formatCurrency(e.target.value)!.toFixed(2),
                    }));
                  }}
                  value={value}
                />
              </div>
            </div>
            <div className={css.itemChargeable}>
              <div className={css.labelChargeable}>Faturável</div>
              <div className={css.chargeable}>
                <Switch
                  checked={chargeable}
                  onClick={() => setState((s) => ({ ...s, chargeable: !chargeable }))}
                  value={chargeable}
                  color="primary"
                  className={classes.switchBase}
                />
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    );
  }

  return (
    <Dialog
      className={classes.dialog}
      open={openAdd}
      onClose={() => setOpenAdd(false)}
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle className={classes.dialogTitle} id="responsive-dialog-title">
        <div className={css.header}>
          <IconButton
            className={classes.closeIcon}
            title="Fechar janela"
            onClick={() => setOpenAdd(false)}
          >
            <CloseOutlined />
          </IconButton>
          <Button
            disabled={!canSubmit}
            className={classes.saveButton}
            onClick={submitForm}
            variant="contained"
            color="primary"
            autoFocus
            size="small"
          >
            Salvar
          </Button>
        </div>
        <div className={css.searchHeader}>
          <div title="Caso" className={css.folderNumberSearch}>
            <FolderOutlined className={classes.iconFolderSearch} />
            <div className={css.content}>
              <div className={css.label}>Busca por nº do caso</div>
              <div className={css.folderSearch}>
                <TextField
                  onKeyDown={(e) => (e.keyCode === 13 ? getCase(caseNumber as string) : null)}
                  inputProps={{ maxLength: 100 }}
                  placeholder="Nº do caso"
                  className={`${classes.textField} ${classes.folderSearch}`}
                  value={caseNumber}
                  onChange={(e) => setCaseNumber(e.target.value)}
                />

                <IconButton
                  className={classes.searchIcon}
                  title="Pesquisar"
                  onClick={() => getCase(caseNumber as string)}
                >
                  <SearchOutlined />
                </IconButton>
              </div>
            </div>
          </div>
          <div title="Cliente" className={css.clientSearch}>
            <div className={css.content}>
              <Button
                className={classes.searchButton}
                onClick={() => setOpenSearch(true)}
                variant="contained"
                color="primary"
                autoFocus
                size="small"
              >
                Busca por cliente
              </Button>
            </div>
          </div>
        </div>
      </DialogTitle>
      {dialogContent}
      <Dialog open={error.open} onClose={() => setError((e) => ({ ...e, open: false }))}>
        <ErrorOutlineOutlined className={classes.iconAlert} />
        <DialogContent className={classes.dialogContentAlert}>
          <div className={css.dialogTitle}>{error.message}</div>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.dialogButton}
            onClick={() => setError((e) => ({ ...e, open: false }))}
            color="primary"
            autoFocus
            size="small"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <ClientSearch
        submitSearch={(n: string) => {
          setCaseNumber(n);
          getCase(n);
          setOpenSearch(false);
        }}
        openSearch={openSearch}
        setOpenSearch={setOpenSearch}
      ></ClientSearch>
    </Dialog>
  );
}

interface Props {
  costCenters: { title: string; id: number }[];
  expenseTypes: Account[];
  openAdd: boolean;
  setOpenAdd: (s: boolean) => void;
}

interface State {
  costCenter: { id: number; title: string };
  operatorAccount: Account;
  date: Date;
  chargeable: boolean;
  discrimination: string;
  value: string;
  upload: any;
}
