import React from "react";
import { ListItem, Icon } from "@material-ui/core";
import css from "./TimesheetsListItem.module.css";
import { TimesheetType } from "../../../declarations";
import { ContactsOutlined, FolderOutlined, TimerOutlined } from "@material-ui/icons";
import makeStyles from "./Styles";
import moment from "moment";
import "moment/locale/pt-br";

export default TimesheetsListItem;

moment.locale("pt-br");

function TimesheetsListItem(props: Props) {
  const timesheet = props.content as TimesheetType;

  const newDate = timesheet.date;

  const currentYear = moment().format("YYYY")

  const movementDayMonth = moment(newDate).format("D MMM")
  const year = moment(newDate).format("YYYY")

  const movementYear =
    currentYear !== year ? (
      <div className={css.itemDateYear}>
        <div className={css.itemYear}>{year}</div>
      </div>
    ) : null;

  const classes = makeStyles();

  return (
    <div>
      <ListItem
        onClick={() => props.openTimesheet(timesheet.id)}
        className={`${classes.Item} ${css.listItem}`}
        button
      >
        <div className={css.ItemDate}>
          <div className={css.itemDayMonth}>{movementDayMonth}</div>
          {movementYear}
        </div>
        <div className={css.ItemTime}>
          <div className={css.itemTimeSpent}>
            <TimerOutlined className={classes.iconTime} />
          </div>
          <div className={css.itemTimeTitle}>
            <div className={css.timeTitle}>{timesheet.timeSpent}</div>
          </div>
        </div>

        <div className={css.listItemInfo}>
          <div className={css.itemDesc}>{timesheet.discrimination}</div>
          <div title="Cliente" className={css.itemClientIcon}>
            <ContactsOutlined className={classes.iconInfo} />
          </div>
          <div className={css.itemClientName}>{timesheet.client.contactName}</div>
          <div title="Pasta/Caso" className={css.itemFolderIcon}>
            <FolderOutlined className={classes.iconInfo} />
          </div>
          <div className={css.itemFolderNumber}>
            {timesheet.process.folderNumber}{" "}
            {timesheet.process.title ? `- ${timesheet.process.title}` : null}
          </div>
          <div title="Advogado executor" className={css.itemLawyerIcon}>
            <Icon className={`fas fa-user-tie ${classes.iconInfo}`} />
          </div>
          <div className={css.itemLawyerName}>{timesheet.executionLawyer.contactName}</div>
        </div>
        {timesheet.status === "F" ? (
          <div title="Faturado" className={css.ItemStatus}>
            <div className={css.itemIconStatus}>
              <Icon className={`fas fa-file-invoice-dollar ${classes.iconStatus}`} />
            </div>
          </div>
        ) : null}
      </ListItem>
    </div>
  );
}

interface Props {
  content: TimesheetType;
  openTimesheet: (id: number) => void;
}
