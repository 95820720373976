import {
  IDictionary,
  QueryPagination,
  DisplayMode,
  Period,
  AssignmentType,
  AssignmentFromRequest,
  Order,
  OrderField
} from "../../declarations";
import { normalizeAssignment } from "./Assignments";
import moment from "moment";

export const initialState: Omit<State, "defaultSelectedAssigned"> = {
  selectedSet: new Set(),
  selectedAssigned: null,
  error: null,
  displayMode: 1,
  refreshing: 0,
  order: { mode: "DESC", field: { name: "startDate", label: "Data inicial" } },
  orderFields: [{ name: "startDate", label: "Data inicial" }],
  selectedPeriod: { fromDate: null, toDate: null },
  query: "/assignments?",
  fetched: {},
  modes: [
    {
      label: "Todos",
      query: "",
      filterFunction: () => true,
      search: ""
    },
    {
      label: "Hoje",
      query: `fromDate=${moment(new Date()).format("YYYY-MM-DD")}&toDate=${moment(
        new Date()
      ).format("YYYY-MM-DD")}`,
      filterFunction: (a: AssignmentType) =>
        new Date().toDateString() === a.startDate.toDateString(),
      search: ""
    },
    {
      label: "Pendentes",
      query: "status=0",
      filterFunction: (a: AssignmentType) => +a.status === 0,
      search: ""
    },
    {
      label: "Cancelados",
      query: "status=2",
      filterFunction: (a: AssignmentType) => +a.status === 2,
      search: ""
    },
    {
      label: "Concluidos",
      query: "status=1",
      filterFunction: (a: AssignmentType) => +a.status === 1,
      search: ""
    },
    {
      label: "Proximos 3 dias",
      query: `fromDate=${new Date(new Date().setHours(24)).toDateString()}&toDate=${new Date(
        new Date().setHours(24 * 3)
      ).toDateString()}`,
      filterFunction: (a: AssignmentType) => {
        const date = a.startDate;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const dateDiff = date.getTime() - today.getTime();
        return dateDiff > 0 && dateDiff < 3 * 24 * 60 * 60 * 1000;
      },
      search: ""
    },
    {
      label: "Proximos 7 dias",
      query: `fromDate=${new Date(new Date().setHours(24)).toDateString()}&toDate=${new Date(
        new Date().setHours(24 * 7)
      ).toDateString()}`,
      filterFunction: (a: AssignmentType) => {
        const date = a.startDate;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const dateDiff = date.getTime() - today.getTime();
        return dateDiff > 0 && dateDiff < 7 * 24 * 60 * 60 * 1000;
      },
      search: ""
    }
  ],
  queryPaginations: {}
};

export function reducer(state: State, action: Action): State {
  const currentMode = { ...state.modes[state.displayMode] };
  let newState = { ...state };
  if (action.type === "updateQuery") {
    let query = "/assignments";
    if (currentMode.search) {
      query += "/search/" + currentMode.search;
    }
    query += "?" + currentMode.query;
    query += `&orderMode=${state.order.mode}`;
    query += `&orderField=${state.order.field.name}`;

    if (
      state.selectedPeriod.fromDate !== null &&
      state.selectedPeriod.toDate !== null &&
      state.displayMode < 5 &&
      state.displayMode !== 1
    )
      query += `&fromDate=${state.selectedPeriod.fromDate.toDateString()}&toDate=${state.selectedPeriod.toDate.toDateString()}`;
    if (state.selectedAssigned !== null)
      query += `&assignedUserId=${state.selectedAssigned.userId}`;
    else if (state.defaultSelectedAssigned !== null)
      query += `&assignedUserId=${state.defaultSelectedAssigned.userId}`;

    newState.query = query;

    if (state.queryPaginations[query] === undefined) {
      newState.queryPaginations[query] = {
        owns: new Set(),
        loading: true,
        lastPage: 1,
        endReached: false
      };
    }
  }
  if (action.type === "fetchNextPage") {
    const { data, query } = action.payload;
    const fetchedData = data;
    const fetchedQuery = query;
    const fetchedQueryPagination = { ...state.queryPaginations[fetchedQuery] };

    const fetchedAssignments: IDictionary<AssignmentType> = {};
    fetchedData.forEach(assignment => {
      fetchedAssignments[assignment.id] = normalizeAssignment(assignment);
      fetchedQueryPagination.owns.add(assignment.id);
    });

    if (fetchedData.length === 0) fetchedQueryPagination.endReached = true;
    fetchedQueryPagination.lastPage++;

    newState.fetched = { ...state.fetched, ...fetchedAssignments };
    newState.queryPaginations = {
      ...state.queryPaginations,
      [fetchedQuery]: fetchedQueryPagination
    };
  }
  if (action.type === "loading") {
    const { query, loading } = action.payload;
    newState.queryPaginations[query].loading = loading;
  }
  if (action.type === "search") {
    newState.modes[state.displayMode].search = action.payload;
  }
  if (action.type === "clearFilters") {
    newState.displayMode = 0;
    newState.modes.forEach(mode => (mode.search = ""));
    newState.selectedPeriod = { fromDate: null, toDate: null };
    newState.selectedAssigned = null;
  }
  if (action.type === "displayMode") {
    newState.displayMode = action.payload;
  }
  if (action.type === "refresh") {
    newState.fetched = {};
    newState.queryPaginations = {};
    newState.refreshing++;
  }
  if (action.type === "order") {
    newState.order = action.payload;
  }
  if (action.type === "selectedPeriod") {
    const newPeriod = action.payload;

    if (newPeriod.fromDate !== null && newPeriod.toDate === null) {
      newPeriod.toDate = newPeriod.fromDate;
    }
    if (
      newPeriod.fromDate === null ||
      Number.isNaN(newPeriod.fromDate.getTime()) ||
      newPeriod.toDate === null ||
      Number.isNaN(newPeriod.toDate.getTime())
    ) {
      return newState;
    }
    newState.selectedPeriod = newPeriod;
  }
  if (action.type === "selectedAssigned") {
    newState.selectedAssigned = action.payload;
  }
  if (action.type === "toggleSelected") {
    const ns = new Set([...Array.from(state.selectedSet.values())]);
    const id = action.payload;
    if (ns.has(id)) {
      ns.delete(id);
    } else {
      ns.add(id);
    }
    newState.selectedSet = ns;
  }
  if (action.type === "clearSelected") {
    newState.selectedSet = new Set();
  }
  if (action.type === "updateAssignment") {
    const assignment = action.payload;
    newState.fetched[assignment.id] = assignment;
  }
  return newState;
}
export type Action =
  | SimpleAction
  | FetchNextPageAction
  | LoadingAction
  | OrderAction
  | SelectedPeriodAction
  | SearchAction
  | OpenAction
  | ErrorAction
  | DisplayModeAction
  | SelectedAssignedAction
  | ToggleSelectedAction
  | UpdateAssignmentAction;

type SimpleAction = {
  type: "updateQuery" | "clearFilters" | "refresh" | "clearSelected";
};

interface FetchNextPageAction {
  type: "fetchNextPage";
  payload: { data: AssignmentFromRequest[]; query: string };
}

interface LoadingAction {
  type: "loading";
  payload: { query: string; loading: boolean };
}

interface OpenAction {
  type: "open";
  payload: number;
}

interface SearchAction {
  type: "search";
  payload: string;
}

interface OrderAction {
  type: "order";
  payload: State["order"];
}
interface SelectedPeriodAction {
  type: "selectedPeriod";
  payload: Period;
}
interface ErrorAction {
  type: "error";
  payload: Error;
}
interface DisplayModeAction {
  type: "displayMode";
  payload: number;
}
interface SelectedAssignedAction {
  type: "selectedAssigned";
  payload: { contactName: string; userId: number } | null;
}
interface ToggleSelectedAction {
  type: "toggleSelected";
  payload: number;
}
interface UpdateAssignmentAction {
  type: "updateAssignment";
  payload: AssignmentType;
}

export interface State {
  selectedSet: Set<number>;
  defaultSelectedAssigned: { contactName: string; userId: number };
  selectedAssigned: { contactName: string; userId: number } | null;
  error: Error | null;
  displayMode: number;
  refreshing: number;
  order: Order<OrderFieldNames>;
  orderFields: OrderField<OrderFieldNames>[];
  selectedPeriod: Period;
  query: string;
  fetched: IDictionary<AssignmentType>;
  modes: DisplayMode<AssignmentType>[];
  queryPaginations: IDictionary<QueryPagination>;
}

export type OrderFieldNames = "startDate";
